import { Outlet } from "react-router-dom";

export { default as Create } from "./Create";
export { default as ApprovalRequests } from "./ApprovalRequests";

const Index = () => (
  <div className="card">
    <div className="card-body pt-1">
      <Outlet />
    </div>
  </div>
);

export default Index;
