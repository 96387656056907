import { ReactNode } from "react";

const ExpandableSection = ({
  title,
  sectionId,
  children,
  expandedByDefault,
  className,
}: {
  title: string;
  sectionId: string;
  children: ReactNode;
  expandedByDefault?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={`accordion shadow ${className ?? ""}`}
      id={`expandableSection-${sectionId}`}
    >
      <div className="accordion-item">
        <h2
          className="accordion-header"
          id={`expandableSectionHeader-${sectionId}`}
        >
          <button
            className="accordion-button collapsed fw-bold"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#expandableSectionBody-${sectionId}`}
            aria-expanded={`${expandedByDefault ? "true" : "false"}`}
            aria-controls={`expandableSectionBody-${sectionId}`}
          >
            {title}
          </button>
        </h2>
        <div
          id={`expandableSectionBody-${sectionId}`}
          className={`accordion-collapse collapse ${
            expandedByDefault ? "show" : ""
          }`}
          aria-labelledby={`#expandableSectionHeader-${sectionId}`}
          data-bs-parent={`#expandableSection-${sectionId}`}
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ExpandableSection;
