import { useEffect, useState } from "react";
import { ISupplierCountry } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  search?: string;
};

type SupplierCountryResponse =
  | PageableDTO<ISupplierCountry[]>
  | ISupplierCountry[];

export const fetchSupplierCountry = async ({
  search,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      search,
      limit: 700,
      ordering: "name",
    });

    const response = await axios.get<SupplierCountryResponse>(
      `/product-master/supplier-country/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useSupplierCountry({ search }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ISupplierCountry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchSupplierCountry({
        search,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [search]);

  return { data, isLoading };
}
