import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import { toast } from "react-toastify";
import Spinner from "../Spinner";

export const TopNavProductSearch = () => {
  const navigate = useNavigate();
  const axios = useAxios();
  const [productNumber, setProductNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleProductNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cleanedNum = e.target.value.replace(/[^0-9]/gi, "");
    setProductNumber(cleanedNum);
  };

  const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
    if (productNumber.length == 0 || productNumber.length >= 3) {
      e.preventDefault();
      setIsLoading(true);
      const endpoint = `/product-exists?id=${productNumber.replace(/^0+/, "")}`;
      const productExists = (await axios.get(endpoint)).data.exists;
      setIsLoading(false);

      if (productExists) {
        navigate(`/product-information/?productnumber=${productNumber}`);
        setProductNumber("");
      } else {
        toast.error("An SKU with that number does not exist");
      }
    }
  };

  return (
    <form
      className="top-nav-product-search nav-link d-flex align-items-center gap-2 mx-2"
      onSubmit={(e) => handleSearch(e)}
    >
      <label className="mb-0">Search SKU:</label>
      <div
        className={`d-flex align-items-center ${
          isLoading ? "small-spinner-spacing" : ""
        }`}
      >
        <span className="mr-2">11 </span>
        <input
          onChange={(e) => handleProductNumberChange(e)}
          value={productNumber}
          minLength={3}
          placeholder=""
          maxLength={6}
          type="text"
          className="form-control d-inline top-nav-product-search-field"
        />
      </div>
      {isLoading ? (
        <div className="small-spinner-container">
          <Spinner hideLabel={true} smallSpinner={true} />
        </div>
      ) : (
        <button
          type="submit"
          className="icon-button d-flex align-items-center mx-auto"
          disabled={productNumber.length == 0}
        >
          <i className="fas fa-fw fa-search"></i>
        </button>
      )}
    </form>
  );
};
