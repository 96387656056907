import { IRequestorMarket } from "../types/data.interface";

export const formatRequestorMarketIntoOption = (item: IRequestorMarket) => ({
    value: item,
    label: `${item.short_name}, ${item.full_name}`,
});

export const getRequestorMarkets = (
    requestorMarkets: IRequestorMarket[],
) =>
    requestorMarkets.map(formatRequestorMarketIntoOption);