import { Tooltip } from "react-tooltip";
import { ITcc } from "../../../../types/data.interface";

const TCC = ({ model }: { model: ITcc }) => {
  return (
    <>
      <div className="row ml-2 mr-2 mb-3">
        <div className="col-3">
          <label className="form-label">Product Color</label>
          <textarea
            value={model?.product_color_id}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Material (Marketing)</label>
          <textarea
            value={model?.marketing_material?.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Artwork</label>
          <textarea
            value={model?.artwork_id}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Channel</label>
          <textarea
            value={model?.channel?.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="row ml-2 mr-2 mb-2">
        <div className="col-3">
          <label className="form-label">Nº of pcs (Sets)</label>
          <div className="d-flex align-items-center">
            <input
              value={model?.number_of_skus}
              type="text"
              className="form-control d-inline"
              disabled
            />
          </div>
        </div>

        <div className="col-3">
          <label className="form-label">Consumer Facing Name</label>
          <textarea
            value={model?.consumer_facing_name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Licensor</label>
          <textarea
            value={model?.licensed_property?.licensor?.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-3">
          <label className="form-label">Licensed Property</label>
          <textarea
            value={model?.licensed_property?.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="row ml-2 mr-2 mb-2">
        <div className="col-3">
          <label className="form-label">Royalty Item</label>
          <div className="d-flex align-items-center">
            <input
              value={
                model?.licensed_property?.licensor?.is_royalty ? "Yes" : "No"
              }
              type="text"
              className="form-control d-inline"
              disabled
            />
          </div>
        </div>

        <div className="col-3">
          <label className="form-label">Flavor/Scent</label>
          <textarea
            value={model?.flavor_scent?.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Global Std Cost</label>
          <textarea
            value={model?.global_standard_cost}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-3">
          <Tooltip
            id="global-msrp"
            className="tooltip-text-box"
            place="top-start"
            noArrow={true}
          />
          <label
            className="form-label"
            data-tooltip-id="global-msrp"
            data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
          >
            Global MSRP
          </label>
          <textarea
            value={model?.global_manufacturer_suggested_retail_price}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="row ml-2 mr-2 mb-2">
        <div className="col-3">
          <label className="form-label">UPC12</label>
          <textarea
            value={model?.upc_12_digits_us}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">GTIN14</label>
          <textarea
            value={model?.gtin_14_digits_row}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Production Lifecycle Status</label>
          <textarea
            value={model?.production_lifecycle_status}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-3">
          <label className="form-label">Sales Lifecycle Status</label>
          <textarea
            value={model?.sales_lifecycle_status}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
    </>
  );
};

export default TCC;
