import { memo } from "react";
import ButtonModal from "../../routes/skus/components/modals/ButtonModal";
import PictureModal from "../modals/PictureModal";

const PictureCellRenderer = memo(
  (prop: { id: number; url: string; sourceType: string }) => (
    <ButtonModal
      buttonIconClass="d-flex justify-content-between"
      description="PICTURE"
      title={`SKU Picture 11 ${prop.id}`}
    >
      <PictureModal url={prop.url} sourceType={prop.sourceType} />
    </ButtonModal>
  ),
);

export default PictureCellRenderer;
