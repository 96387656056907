import { useEffect, useState } from "react";
import { IResPisTpsType } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  search?: string;
};

type TpsTypeResponse = PageableDTO<IResPisTpsType[]> | IResPisTpsType[];

export const fetchTpsType = async ({
  search,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      search,
    });

    const response = await axios.get<TpsTypeResponse>(
      `/tps-types/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useTpsType({ search }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IResPisTpsType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchTpsType({
        search,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [search]);

  return { data, isLoading };
}
