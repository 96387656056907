import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner";
import AuthContext from "../../../context/AuthContext";
import ProductContext, {
  ProductContextInterface,
} from "../../../context/ProductContext";
import SkuCreationApprovalContext, {
  SkuCreationApprovalContextInterface,
} from "../../../context/SkuCreationApprovalContext";
import {
  buildPisRequest,
  mapPisRequestToSkuDataObject,
} from "../../../helpers/pis-request.helper";
import { getMessageForSimilarSkus } from "../../../helpers/sku.helper";
import {
  createPisRequest,
  fetchPisHistory,
  fetchPisRequest,
  updatePisRequest,
} from "../../../hooks/usePisRequest";
import {
  ProductMaster,
  fetchProductMasterById,
} from "../../../hooks/useProductMaster";
import { fetchSkuComposition } from "../../../hooks/useSkuComposition";
import { Predicates } from "../../../libraries/predicates/predicates";
import {
  IGlobalProduct,
  IPisRequest,
  IRequestorMarket,
  ISkuComposition,
  ISkuInformation,
  ITcc,
  Sku,
} from "../../../types/data.interface";
import useAxios from "../../../utils/useAxios";
import { useBeforeUnload } from "../../../utils/useBeforeUnload";
import { Breadcrumb, BreadcrumbItem } from "../components/breadcrumb";
import {
  Composition,
  Hierarchy,
  Information,
  ProductPackaging,
  RequestorInfo,
  Summary,
} from "../features/sku-create";
import { CompositionProps } from "../features/sku-create/Composition";
import { HierarchyProps } from "../features/sku-create/Hierarchy";
import { InformationProps } from "../features/sku-create/Information";
import { RequestorProps } from "../features/sku-create/RequestorInfo";
import { SummaryProps } from "../features/sku-create/Summary";
import {
  fillCompositionNodeIds,
  getEmptySkuDataObject,
  getTodayDate,
} from "../features/sku-create/helpers";
import { PageableTableProvider } from "../../../context/PageableTableContext";
import {
  NEW_PIS_HIERARCHY_PREFIX,
  NewPISHierarchyPersistentData,
} from "../../../types/persistent-page-filters";
import { saveObjectsToLocalStorage } from "../../../helpers/local-storage.helper";

type CreateProps = {
  step: number;
  hierarchyProps: HierarchyProps;
  compositionProps: CompositionProps;
  informationProps: InformationProps;
  requestorProps: RequestorProps;
  summaryProps: SummaryProps;
};

const Steps = ({
  step,
  hierarchyProps,
  compositionProps,
  informationProps,
  requestorProps,
  summaryProps,
}: CreateProps) => {
  switch (step) {
    case 1:
      return <Composition {...compositionProps} />;
    case 2:
      return <Information {...informationProps} />;
    case 3:
      return <ProductPackaging {...informationProps} />;
    case 4:
      return <RequestorInfo {...requestorProps} />;
    case 5:
      return <Summary {...summaryProps} />;
    default:
      return (
        <PageableTableProvider>
          <Hierarchy
            requestStatus={hierarchyProps.requestStatus}
            selectedGP={hierarchyProps.selectedGP}
            selectedPM={hierarchyProps.selectedPM}
            selectedSku={hierarchyProps.selectedSku}
            selectedTcc={hierarchyProps.selectedTcc}
            setSelectedGP={hierarchyProps.setSelectedGP}
            setSelectedPM={hierarchyProps.setSelectedPM}
            setSelectedSku={hierarchyProps.setSelectedSku}
            setSelectedTcc={hierarchyProps.setSelectedTcc}
            setUnsavedChangesToTrue={hierarchyProps.setUnsavedChangesToTrue}
          />
        </PageableTableProvider>
      );
  }
};

const Create = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const username = authCtx?.username;
  const hasPermission = authCtx?.hasPermission;
  const { unsavedChanges, setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);
  const { finalizeSkuCreationProcess } =
    useContext<SkuCreationApprovalContextInterface>(SkuCreationApprovalContext);
  const hasApproverPermission: boolean = hasPermission(
    "product_master.approver_pis_request",
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const id: number | undefined = useMemo(() => {
    const paramId = searchParams.get("id");
    return Predicates.isNotNullAndNotUndefined(paramId)
      ? parseInt(paramId)
      : undefined;
  }, [searchParams]);
  const pisRequestId: string | null = useMemo(
    () => searchParams.get("pis_request_id"),
    [searchParams],
  );
  const requestRevisionNumber: string | null = useMemo(
    () => searchParams.get("revision"),
    [searchParams],
  );
  const shouldCreate: string | null = useMemo(
    () => searchParams.get("should_create"),
    [searchParams],
  );
  const isApproved: string | null = useMemo(
    () => searchParams.get("is_approved"),
    [searchParams],
  );
  const lastApprovedRevisionNumber: string | null = useMemo(
    () => searchParams.get("last_approved_revision"),
    [searchParams],
  );

  const [pisRequest, setPisRequest] = useState<IPisRequest>({
    pis_request_id: "",
    revision: "0",
    request_date: getTodayDate(),
    status: "CREATED",
    requested_by: username ?? "-",
  });

  const isFinished: boolean = useMemo(
    () => pisRequest.status === "APPROVED" || pisRequest.status === "REJECTED",
    [pisRequest],
  );

  const [step, setStep] = useState(0);
  const [selectedSku, setSelectedSku] = useState<Sku | null>(null);
  const [selectedPM, setSelectedPM] = useState<ProductMaster | null>(null);
  const [previousSelectedPM, setPreviousSelectedPM] =
    useState<ProductMaster | null>(null);
  const [selectedGP, setSelectedGP] = useState<IGlobalProduct | null>(null);
  const [selectedTcc, setSelectedTcc] = useState<ITcc | null>(null);

  const [isPisRequestInformationLoading, setIsPisRequestInformationLoading] =
    useState<boolean>(false);
  const [isClonedInformationLoading, setIsClonedInformationLoading] =
    useState<boolean>(false);
  const [isSaveOrSubmitInProgress, setIsSaveOrSubmitInProgress] =
    useState<boolean>(false);

  const [shouldSaveSelectedSkuToClone, setShouldSaveSelectedSkuToClone] =
    useState<boolean>(false);

  //Composition
  const [skuComposition, setSkuComposition] = useState<ISkuComposition>({
    id: "",
    name: "",
    skus: [],
    molded_components: [],
    non_molded_components: [],
  });

  //Other Info
  const [skuData, setSkuData] = useState<ISkuInformation>(
    getEmptySkuDataObject(),
  );

  //Requestor Info
  const [requestorMarket, setRequestorMarket] = useState<IRequestorMarket>({
    id: "",
    short_name: "",
    full_name: "",
  });

  const isStepDisabled = useCallback(
    (step: number): boolean => {
      let shouldBeDisabled: boolean = true;
      switch (step) {
        case 0:
          shouldBeDisabled =
            isFinished ||
            !checkIfSkuHierarchyIsValid() ||
            isClonedInformationLoading;
          break;
        case 1:
          shouldBeDisabled = isFinished;
          break;
        case 2:
          shouldBeDisabled = isFinished || !checkIfSkuInformationIsValid();
          break;
        case 3:
          shouldBeDisabled = isFinished || !checkIfProductPackagingIsValid();
          break;
        case 4:
          shouldBeDisabled = isFinished || !checkIfRequestorInfoIsValid();
          break;
        default:
          shouldBeDisabled = false;
      }
      return step === 0
        ? shouldBeDisabled
        : shouldBeDisabled || isStepDisabled(step - 1);
    },
    [
      isFinished,
      step,
      pisRequest,
      selectedGP,
      selectedTcc,
      isClonedInformationLoading,
      skuData,
      requestorMarket,
    ],
  );

  const checkIfSkuHierarchyIsValid = (): boolean => {
    const hierarchyFieldToCheck: ITcc | IGlobalProduct | null =
      pisRequest.status === "CREATED"
        ? selectedGP
        : hasApproverPermission
          ? selectedGP // Changed from 'selectedTCC' to 'selectedGP' so that the approver can check the other info to decide which TCC needs to be chosen
          : selectedGP;
    return Predicates.isNotNullAndNotUndefined(hierarchyFieldToCheck);
  };
  const checkIfSkuInformationIsValid = (): boolean => {
    return (
      skuData.name.trim().length > 0 &&
      skuData.product_use.trim().length > 0 &&
      skuData.giftbox.trim().length > 0 &&
      skuData.reldt.trim().length > 0 &&
      skuData.tps_product.trim().length > 0 &&
      ((skuData.tps_product === "Y" &&
        Predicates.isNotNullAndNotUndefined(skuData.tps_loc_id) &&
        skuData.tps_loc_id.id !== "0" &&
        Predicates.isNotNullAndNotUndefined(skuData.tps_type_id) &&
        skuData.tps_type_id.res_pis_type_id !== "0") ||
        skuData.tps_product === "N")
    );
  };
  const checkIfProductPackagingIsValid = (): boolean => {
    return (
      skuData.stdpk.trim().length > 0 &&
      skuData.stdpk_weight.trim().length > 0 &&
      skuData.stdpk_length.trim().length > 0 &&
      skuData.stdpk_width.trim().length > 0 &&
      skuData.stdpk_height.trim().length > 0
    );
  };
  const checkIfRequestorInfoIsValid = (): boolean => {
    return requestorMarket.id.length > 0;
  };

  const manageSelectedSkuToClone = (sku: ProductMaster | null) => {
    setPreviousSelectedPM(selectedPM);
    setSelectedPM(sku);
  };

  const clearAllState = () => {
    setSelectedGP(null);
    setSelectedTcc(null);
    setSelectedPM(null);
    setPreviousSelectedPM(null);
    setSelectedSku(null);
    setStep(0);
    setSkuComposition({
      id: "",
      name: "",
      skus: [],
      molded_components: [],
      non_molded_components: [],
    });
    setSkuData(getEmptySkuDataObject());
    setRequestorMarket({
      id: "",
      short_name: "",
      full_name: "",
    });
  };

  const setUnsavedChangesToTrue = () => {
    setUnsavedChanges({
      existsUnsavedChanges: true,
      message: "You may have unsaved changes. Are you sure you want to leave?",
    });
  };

  useEffect(() => {
    const retrievePisRequest = async () => {
      if (Predicates.isNotNullAndNotUndefined(id)) {
        const currentRevisionNumber: string = requestRevisionNumber
          ? requestRevisionNumber
          : "0";
        const pisRevisionNumberToRetrieve: string =
          shouldCreate &&
          Number(currentRevisionNumber) > 0 &&
          lastApprovedRevisionNumber
            ? lastApprovedRevisionNumber
            : currentRevisionNumber;

        if (currentRevisionNumber === "0" && shouldCreate) {
          clearAllState();
          return;
        }

        setIsPisRequestInformationLoading(true);
        const retrievedPisRequest: IPisRequest | null =
          shouldCreate || isApproved
            ? await fetchPisHistory({
                id: id,
                pis_request_id: pisRequestId,
                revision: pisRevisionNumberToRetrieve,
                axios,
              })
            : await fetchPisRequest({
                id: id,
                pis_request_id: pisRequestId,
                revision: pisRevisionNumberToRetrieve,
                axios,
              });

        if (retrievedPisRequest) {
          setPisRequest({
            ...retrievedPisRequest,
            revision: currentRevisionNumber,
            status: shouldCreate ? "CREATED" : retrievedPisRequest.status,
            request_date: shouldCreate
              ? getTodayDate()
              : retrievedPisRequest.request_date,
            requested_by: shouldCreate
              ? username
                ? username
                : "-"
              : retrievedPisRequest.requested_by,
          });

          if (retrievedPisRequest.cloned_sku_id) {
            const clonedSku: ProductMaster | undefined =
              await fetchProductMasterById({
                id: retrievedPisRequest.cloned_sku_id,
                axios,
              });
            manageSelectedSkuToClone(
              Predicates.isNullOrUndefined(clonedSku) ? null : clonedSku,
            );
          } else {
            manageSelectedSkuToClone(null);
          }

          setSelectedGP(retrievedPisRequest.global_product ?? null);
          setSelectedTcc(retrievedPisRequest.tcc ?? null);

          if (retrievedPisRequest.sku_composition) {
            setSkuComposition(
              fillCompositionNodeIds(
                JSON.parse(retrievedPisRequest.sku_composition),
              ),
            );
          }

          setSkuData({
            ...skuData,
            ...mapPisRequestToSkuDataObject(retrievedPisRequest),
          });
          setRequestorMarket(
            retrievedPisRequest.res_pm_market ?? {
              id: "",
              short_name: "",
              full_name: "",
            },
          );

          if (
            !shouldCreate &&
            (retrievedPisRequest.status === "APPROVED" ||
              retrievedPisRequest.status === "REJECTED")
          ) {
            setStep(5);
          }
        }

        setIsPisRequestInformationLoading(false);
      } else {
        clearAllState();
      }
    };

    retrievePisRequest();
  }, []);

  useEffect(() => {
    setUnsavedChanges({
      existsUnsavedChanges: !isFinished,
      message: "You may have unsaved changes. Are you sure you want to leave?",
    });
  }, [
    isFinished,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  useBeforeUnload({
    condition: unsavedChanges ? unsavedChanges.existsUnsavedChanges : false,
    message: "You may have unsaved changes. Are you sure you want to leave?",
  });

  const onNext = useCallback(
    (specificStep?: number) => {
      const getClonedSkuInformation = async () => {
        if (selectedPM) {
          const cleanSkuId: string = selectedPM.id.replace(/^0+/, "");
          // SKU Composition Info
          setIsClonedInformationLoading(true);
          const retrievedComposition = await fetchSkuComposition({
            id: cleanSkuId,
            axios,
          });
          setSkuComposition(fillCompositionNodeIds(retrievedComposition));

          const retrievedInfo: any = (
            await axios.get(`/product-information?id=${cleanSkuId}`)
          ).data[0];
          setSkuData({
            ...retrievedInfo,
            name: retrievedInfo.name.trim(),
            reldt: "",
            giftbox: retrievedInfo.giftbox ? retrievedInfo.giftbox : "N",
            tps_product: retrievedInfo.tps_product
              ? retrievedInfo.tps_product
              : "N",
            tps_loc_id: retrievedInfo.vendor,
            tps_type_id: retrievedInfo.tps_type,
          });

          if (
            !selectedTcc &&
            retrievedInfo.tcc &&
            retrievedInfo.tcc.suffix !== "8888"
          ) {
            setSelectedTcc(retrievedInfo.tcc);
          }
          setShouldSaveSelectedSkuToClone(true);
          setIsClonedInformationLoading(false);
        }

        setStep(
          Predicates.isNotNullAndNotUndefined(specificStep)
            ? specificStep
            : step + 1,
        );
      };

      if (step === 0) {
        saveObjectsToLocalStorage<NewPISHierarchyPersistentData>({
          prefix: NEW_PIS_HIERARCHY_PREFIX,
          lastTableFilters: [],
        });
      }

      if (
        step === 0 &&
        (!pisRequest.cloned_sku_id ||
          (pisRequest.cloned_sku_id &&
            pisRequest.cloned_sku_id !== selectedPM?.id)) &&
        previousSelectedPM?.id !== selectedPM?.id
      ) {
        getClonedSkuInformation();
      } else {
        setStep(
          Predicates.isNotNullAndNotUndefined(specificStep)
            ? specificStep
            : step + 1,
        );
      }
    },
    [step, selectedPM, previousSelectedPM, pisRequest],
  );

  const onPrevious = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onSubmit = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      { ...pisRequest, status: "SUBMITTED" },
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      username ?? null,
    );
    let response: IPisRequest | null;

    setIsSaveOrSubmitInProgress(true);
    if (shouldCreate === "true") {
      response = await createPisRequest({
        requestData: formattedPisRequest,
        axios,
      });
      const newPisRequestId: string = response?.pis_request_id ?? "";
      const newId = response?.id;
      setPisRequest({
        ...pisRequest,
        pis_request_id: newPisRequestId,
        id: newId,
      });
    } else {
      response = await updatePisRequest({
        id: formattedPisRequest.id,
        requestData: formattedPisRequest,
        axios,
      });
    }
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${response.pis_request_id ?? ""} submitted successfully!`,
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to submit the PIS Request.",
      );
    }

    if (response?.similar_skus && response?.similar_skus.length)
      showToastMessageForSimiliarSkus(response?.similar_skus);
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  const onSave = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      username ?? null,
    );
    let response: IPisRequest | null;

    setIsSaveOrSubmitInProgress(true);
    if (shouldCreate === "true") {
      response = await createPisRequest({
        requestData: formattedPisRequest,
        axios,
      });
      const newPisRequestId: string = response?.pis_request_id ?? "";
      const newId = response?.id;
      setPisRequest({
        ...pisRequest,
        pis_request_id: newPisRequestId,
        id: newId,
      });

      if (response) {
        const newUrlParams = new URLSearchParams({
          id: String(newId) ?? "",
          pis_request_id: newPisRequestId,
          revision: formattedPisRequest.revision,
        });
        setSearchParams(newUrlParams, { replace: true });
      }
    } else {
      response = await updatePisRequest({
        id: formattedPisRequest.id,
        requestData: formattedPisRequest,
        axios,
      });
    }
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${response.pis_request_id ?? ""} saved successfully!`,
      );
    } else {
      toast.error(
        "An error occurred. It was not possible to save the PIS Request.",
      );
    }

    if (response?.similar_skus)
      showToastMessageForSimiliarSkus(response?.similar_skus);
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  const onApprove = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      username ?? null,
    );

    if (Predicates.isNullOrUndefined(selectedTcc)) {
      toast.error(
        "You need to select a TCC in order to Approve the request. Please fill that information in the 'SKU Hierarchy' step.",
      );
      return;
    }

    setIsSaveOrSubmitInProgress(true);
    const response = await updatePisRequest({
      id: formattedPisRequest.id,
      requestData: { ...formattedPisRequest, status: "APPROVED" },
      axios,
    });
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${formattedPisRequest.pis_request_id} approved!`,
      );
      finalizeSkuCreationProcess(
        response.pis_request_id,
        response.revision,
        response.finish_goods_no_assigned,
        username ? username : "",
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to approve the PIS Request.",
      );
    }
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  const onReject = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      username ?? null,
    );
    setIsSaveOrSubmitInProgress(true);
    const response = await updatePisRequest({
      id: formattedPisRequest.id,
      requestData: { ...formattedPisRequest, status: "REJECTED" },
      axios,
    });
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${formattedPisRequest.pis_request_id} rejected!`,
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to reject the PIS Request.",
      );
    }
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  const showToastMessageForSimiliarSkus = (skus: Array<number>) => {
    if (skus && skus.length) toast.warning(getMessageForSimilarSkus(skus));
  };

  const hierarchyProps = useMemo(
    () => ({
      selectedSku,
      setSelectedSku,
      selectedGP,
      setSelectedGP,
      selectedTcc,
      setSelectedTcc,
      selectedPM,
      setSelectedPM: manageSelectedSkuToClone,
      requestStatus: pisRequest.status,
      setUnsavedChangesToTrue,
    }),
    [
      selectedSku,
      setSelectedSku,
      selectedGP,
      setSelectedGP,
      selectedTcc,
      setSelectedTcc,
      selectedPM,
      manageSelectedSkuToClone,
      pisRequest.status,
      setUnsavedChangesToTrue,
    ],
  );

  return (
    <>
      {isPisRequestInformationLoading ? (
        <Spinner />
      ) : (
        <div>
          <Breadcrumb>
            <BreadcrumbItem
              text="1 - SKU Hierarchy"
              isDisabled={isFinished}
              onClick={() => {
                onNext(0);
              }}
              showActive={true}
              isCurrent={step === 0}
            />
            <BreadcrumbItem
              text="2 - SKU Composition"
              isDisabled={isStepDisabled(0)}
              onClick={() => {
                onNext(1);
              }}
              showActive={step >= 1}
              isCurrent={step === 1}
            />
            <BreadcrumbItem
              text="3 - SKU Information"
              isDisabled={isStepDisabled(1)}
              onClick={() => {
                onNext(2);
              }}
              showActive={step >= 2}
              isCurrent={step === 2}
            />
            <BreadcrumbItem
              text="4 - Product Packaging"
              isDisabled={isStepDisabled(2)}
              onClick={() => {
                onNext(3);
              }}
              showActive={step >= 3}
              isCurrent={step === 3}
            />
            <BreadcrumbItem
              text="5 - Requestor Info"
              isDisabled={isStepDisabled(3)}
              onClick={() => {
                onNext(4);
              }}
              showActive={step >= 4}
              isCurrent={step === 4}
            />
            <BreadcrumbItem
              text="6 - Summary"
              isDisabled={isFinished ? false : isStepDisabled(4)}
              onClick={() => {
                onNext(5);
              }}
              showActive={step >= 5}
              isCurrent={step === 5}
            />
          </Breadcrumb>
          {isClonedInformationLoading ? (
            <Spinner />
          ) : (
            <Steps
              step={step}
              hierarchyProps={hierarchyProps}
              {...{
                compositionProps: {
                  selectedGP,
                  selectedTcc,
                  saveSkuComposition: setSkuComposition,
                  savedSkuComposition: skuComposition,
                  skuIdInRevision: pisRequest.finish_goods_no_assigned ?? "",
                  setUnsavedChangesToTrue,
                },
                informationProps: {
                  selectedGP,
                  selectedTcc,
                  skuData,
                  setSkuData,
                  setUnsavedChangesToTrue,
                },
                requestorProps: {
                  selectedGP,
                  selectedTcc,
                  requestorMarket,
                  setRequestorMarket,
                  setUnsavedChangesToTrue,
                },
                summaryProps: {
                  selectedPM,
                  selectedGP,
                  selectedTcc,
                  savedSkuComposition: skuComposition,
                  skuData,
                  requestorMarket,
                  pisRequest,
                },
              }}
            />
          )}

          {step == 5 &&
          pisRequest.status === "SUBMITTED" &&
          Predicates.isNotNullAndNotUndefined(pisRequest.similar_skus) &&
          pisRequest.similar_skus.length ? (
            <div className="alert alert-warning mt-3" role="alert">
              {getMessageForSimilarSkus(pisRequest.similar_skus)}
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex justify-content-end mt-3">
            {!isFinished &&
              (pisRequest.status === "CREATED" || hasApproverPermission) && (
                <button
                  className="btn btn-primary me-auto"
                  disabled={isSaveOrSubmitInProgress}
                  onClick={onSave}
                >
                  Save
                </button>
              )}

            <div className="d-flex gap-3" style={{ height: 38 }}>
              {isSaveOrSubmitInProgress && (
                <Spinner hideLabel={true} mediumSpinner={true} />
              )}

              {step > 0 && !isFinished && (
                <button
                  className="btn btn-outline-primary ms-auto"
                  disabled={step === 0 || isSaveOrSubmitInProgress}
                  onClick={onPrevious}
                >
                  Previous
                </button>
              )}

              {step < 5 && (
                <button
                  className="btn btn-primary ms-auto"
                  disabled={isStepDisabled(step) || isSaveOrSubmitInProgress}
                  onClick={() => onNext()}
                >
                  {"Next"}
                </button>
              )}

              {step === 5 && pisRequest.status === "CREATED" && (
                <button
                  className="btn btn-primary ms-auto"
                  disabled={isStepDisabled(step) || isSaveOrSubmitInProgress}
                  onClick={onSubmit}
                >
                  {"Submit for Approval"}
                </button>
              )}

              {step === 5 &&
                pisRequest.status === "SUBMITTED" &&
                hasApproverPermission && (
                  <>
                    <button
                      className="btn btn-danger ms-auto"
                      disabled={
                        isStepDisabled(step) || isSaveOrSubmitInProgress
                      }
                      onClick={onReject}
                    >
                      {"Reject"}
                    </button>

                    <button
                      className="btn btn-primary ms-auto"
                      disabled={
                        isStepDisabled(step) || isSaveOrSubmitInProgress
                      }
                      onClick={onApprove}
                    >
                      {"Approve"}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Create;
