import { ProductMaster } from "../hooks/useProductMaster";
import { getTodayDate } from "../routes/skus/features/sku-create/helpers";
import {
  IGlobalProduct,
  IPisRequest,
  IRequestorMarket,
  ISkuComposition,
  ISkuInformation,
  ITcc,
} from "../types/data.interface";

export const mapPisRequestToSkuDataObject = (
  pisRequest: IPisRequest
): ISkuInformation => {
  return {
    id: pisRequest.finish_goods_no_assigned ?? "",
    name: pisRequest.item_description ?? "",
    stdpk: pisRequest.standard_pack ?? "",
    stdpk_weight: pisRequest.std_pk_wgt ?? "",
    stdpk_length: pisRequest.std_pk_size_l ?? "",
    stdpk_width: pisRequest.std_pk_size_w ?? "",
    stdpk_height: pisRequest.std_pk_size_h ?? "",
    reldt: pisRequest.release_date ?? "",
    giftbox: pisRequest.gift_box ? pisRequest.gift_box : "N",
    width: pisRequest.prod_dim_w ?? "",
    length: pisRequest.prod_dim_l ?? "",
    height: pisRequest.prod_dim_h ?? "",
    comments: pisRequest.comments ?? "",
    product_use: pisRequest.product_use ?? "",
    tps_product: pisRequest.tps_product ? pisRequest.tps_product : "N",
    tps_loc_id: pisRequest.tps_loc_id ?? null,
    tps_type_id: pisRequest.tps_type_id ?? null,
  };
};

export const buildPisRequest = (
  pisRequest: IPisRequest,
  selectedGP: IGlobalProduct | null,
  selectedTcc: ITcc | null,
  selectedPM: ProductMaster | null,
  shouldSaveSelectedPM: boolean,
  skuComposition: ISkuComposition,
  skuData: ISkuInformation,
  requestorMarket: IRequestorMarket,
  username: String | null
): any => {
  const test = {
    id: pisRequest.id ?? undefined,
    pis_request_id: pisRequest.pis_request_id
      ? Number(pisRequest.pis_request_id)
      : null,
    revision: Number(pisRequest.revision),
    request_date: pisRequest.request_date ? pisRequest.request_date : null,
    status: pisRequest.status,
    requested_by: username ? username : pisRequest.requested_by,
    dtchng: getTodayDate(),
    cloned_sku_id: shouldSaveSelectedPM
      ? selectedPM?.id
      : pisRequest.cloned_sku_id
      ? pisRequest.cloned_sku_id
      : null,
    finish_goods_no_assigned: pisRequest.finish_goods_no_assigned,

    global_product: selectedGP?.id,
    tcc: selectedTcc?.id,

    sku_composition: JSON.stringify(skuComposition),

    item_description: skuData.name,
    comments: skuData.comments,
    //assoc_pp_id: skuData.,
    gift_box: skuData.giftbox,
    prod_dim_h: skuData.height,
    prod_dim_l: skuData.length,
    prod_dim_w: skuData.width,
    product_use: skuData.product_use,
    release_date: skuData.reldt ? skuData.reldt : null,
    tps_product: skuData.tps_product,
    tps_loc_id: skuData.tps_loc_id?.id ?? "",
    tps_type_id: skuData.tps_type_id?.res_pis_type_id ?? "",

    standard_pack: skuData.stdpk,
    std_pk_size_h: skuData.stdpk_height,
    std_pk_size_l: skuData.stdpk_length,
    std_pk_size_w: skuData.stdpk_width,
    std_pk_wgt: skuData.stdpk_weight,

    res_pm_market: requestorMarket.id ? requestorMarket.id : null,
  };
  return test;
};
