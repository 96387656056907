import { Predicates } from "../libraries/predicates/predicates";
import { PagePersistentTypes } from "../types/persistent-page-filters";

export function saveObjectsToLocalStorage<T extends PagePersistentTypes>(
  objectsToSave: T
): void {
  const keys = Object.keys(objectsToSave) as (keyof T)[];

  keys.forEach((key) => {
    if (key.toString() !== "prefix") {
      const value = objectsToSave[key];
      if (Predicates.isNotNullAndNotUndefined(value)) {
        const label: string = `${objectsToSave.prefix}-${key.toString()}`;
        localStorage.setItem(label, JSON.stringify(value));
      }
    }
  });
}

export function retrieveObjectFromLocalStorage<T extends PagePersistentTypes>(
  prefix: T["prefix"],
  objectNameToRetrieve: keyof T
): any {
  const completeKey = `${prefix}-${objectNameToRetrieve.toString()}`;
  const storedValue = localStorage.getItem(completeKey);
  if (Predicates.isNotNullAndNotUndefined(storedValue)) {
    return JSON.parse(storedValue);
  }
  return null;
}

export function retrieveValueFromLocalStorageObject<
  T extends PagePersistentTypes
>(
  prefix: T["prefix"],
  objectNameToRetrieve: keyof T,
  key: string,
  fallbackValue?: any
) {
  const localStorageObject: any = retrieveObjectFromLocalStorage<T>(
    prefix,
    objectNameToRetrieve
  );
  const defaultValue: any = Predicates.isNotNullAndNotUndefined(fallbackValue)
    ? fallbackValue
    : null;
  if (Predicates.isNotNullAndNotUndefined(localStorageObject)) {
    return key in localStorageObject ? localStorageObject[key] : defaultValue;
  }
  return defaultValue;
}
