import {
  formatGlobalProductIntoOption,
  getGlobalProductCode,
} from "../../../../helpers/global-product.helper";
import {
  formatTccIntoOption,
  getTccCode,
} from "../../../../helpers/tcc.helper";
import { ProductMaster } from "../../../../hooks/useProductMaster";
import {
  IGlobalProduct,
  IPisRequest,
  IRequestorMarket,
  ISkuComposition,
  ISkuInformation,
  ITcc,
} from "../../../../types/data.interface";
import { SkuCompositionTree } from "../../components/sku-composition-tree";
import ButtonModal from "../../components/modals/ButtonModal";
import {
  GlobalProductTemplate,
  TCCTemplate,
} from "../../components/attributes-modal";
import { faCirclePlus, faImage } from "@fortawesome/free-solid-svg-icons";
import { PictureModal } from "../../components/modals";
import { numberOfTextAreaRows } from "../../../../helpers";
import { formatSupplierCountryIntoOption } from "../../../../helpers/supplier-country.helper";
import { formatTpsTypeIntoOption } from "../../../../helpers/tps-type.helper";

export type SummaryProps = {
  selectedPM: ProductMaster | null;
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  savedSkuComposition: ISkuComposition;
  skuData: ISkuInformation;
  requestorMarket: IRequestorMarket;
  pisRequest: IPisRequest;
};

const Summary = ({
  selectedPM,
  selectedGP,
  selectedTcc,
  savedSkuComposition,
  skuData,
  requestorMarket,
  pisRequest,
}: SummaryProps) => {
  return (
    <>
      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">Summary</h6>
      </div>

      <div className="row">
        <div className="col-4">
          <label className="form-label">Request Status</label>
          <input
            value={pisRequest.status}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-4">
          <label className="form-label">Request nº</label>
          <input
            value={pisRequest.pis_request_id ? pisRequest.pis_request_id : "-"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-4">
          <label className="form-label">Revision nº</label>
          <input
            value={pisRequest.revision}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-4">
          <label className="form-label">Request Location</label>
          <input
            value={`${requestorMarket.short_name}, ${requestorMarket.full_name}`}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Request Date</label>
          <input
            value={pisRequest.request_date}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">Requester</label>
          <input
            value={pisRequest.requested_by ?? "-"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">{`${
            pisRequest.status === "REJECTED" ? "Rejected" : "Approved"
          } Date`}</label>
          <input
            value={
              pisRequest.status === "APPROVED" ||
              pisRequest.status === "REJECTED"
                ? pisRequest.dtchng ?? "-"
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">Approver</label>
          <input
            value={
              pisRequest.status === "APPROVED" ||
              pisRequest.status === "REJECTED"
                ? pisRequest.uidchng ?? "-"
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-2">
          <label className="form-label">SKU nº</label>
          <input
            value={
              pisRequest.finish_goods_no_assigned
                ? `11 ${pisRequest.finish_goods_no_assigned}`
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-6">
          <label className="form-label">
            SKU Description <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={skuData.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">Cloned from SKU</label>
          <textarea
            value={
              selectedPM && selectedPM?.id && selectedPM?.description
                ? `11 ${selectedPM?.id} - ${selectedPM?.description?.trim()}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Hierarchy</h6>
      </div>

      <div className="row">
        <div className="col-4">
          <label className="form-label">
            Category <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.sub_category.parent?.code} - ${selectedGP.sub_category.parent?.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">
            Sub-Category <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.sub_category.code} - ${selectedGP.sub_category.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">
            Product Line <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.product_line.code} - ${selectedGP.product_line.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-6">
          <label className="form-label">
            Global Product <span className="red-text fw-bold">*</span>
          </label>
          {selectedGP ? (
            <ButtonModal
              buttonIconClass="mx-3 d-inline-block"
              description="show attributes"
              title={`Global Product ${
                formatGlobalProductIntoOption(selectedGP).label
              }`}
              icon={faCirclePlus}
            >
              <GlobalProductTemplate model={selectedGP} />
            </ButtonModal>
          ) : (
            <></>
          )}
          <textarea
            value={
              selectedGP
                ? `${getGlobalProductCode(selectedGP)} - ${selectedGP.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-6">
          <label className="form-label">
            TCC{" "}
            {pisRequest.status !== "CREATED" && (
              <span className="red-text fw-bold">*</span>
            )}
          </label>
          {selectedTcc ? (
            <>
              <ButtonModal
                buttonIconClass="mx-3 d-inline-block"
                description="show attributes"
                title={`TCC ${formatTccIntoOption(selectedTcc).label}`}
                icon={faCirclePlus}
              >
                <TCCTemplate model={selectedTcc} />
              </ButtonModal>

              <ButtonModal
                buttonIconClass="mx-3 d-inline-block"
                description="show picture"
                title={`TCC ${formatTccIntoOption(selectedTcc).label}`}
                icon={faImage}
              >
                <PictureModal src={selectedTcc.product_photo} />
              </ButtonModal>
            </>
          ) : (
            <></>
          )}
          <textarea
            value={
              selectedTcc
                ? `${getTccCode(selectedTcc)} - ${selectedTcc.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Composition</h6>
      </div>

      <SkuCompositionTree
        skuComposition={savedSkuComposition}
        isSummaryPage={true}
        isMainPage={false}
        shouldIncludeSku={false}
        manageSkuInclusion={() => {}}
        manageComponentInclusion={() => {}}
        assertComponentIsIncluded={() => {}}
      />

      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Information</h6>
      </div>

      <div className="mt-1">
        <div className="row align-items-end">
          <div className="col-2">
            <label className="form-label">
              Release Date <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.reldt ? skuData.reldt : "-"}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-2 ">
            <label className="form-label ">
              Product Use <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.product_use}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-2">
            <label className="form-label">
              Gift Box <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.giftbox === "N" ? "No" : "Yes"}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-6">
            <div className="row pb-1">
              <div className="col label-3 bottom-border ">
                <label className="form-label ">
                  <strong> Product Dimension</strong>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-4 label-3">
                <label className="form-label">L (mm)</label>
                <input
                  value={skuData.length}
                  type="text"
                  className="form-control d-inline"
                  disabled={true}
                />
              </div>
              <div className="col-4 label-3">
                <label className="form-label">W (mm)</label>
                <input
                  value={skuData.width}
                  type="text"
                  className="form-control d-inline"
                  disabled={true}
                />
              </div>
              <div className="col-4 label-3">
                <label className="form-label">H (mm)</label>
                <input
                  value={skuData.height}
                  type="text"
                  className="form-control d-inline"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-4 ">
          <label className="form-label">
            TPS Product <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.tps_product === "N" ? "No" : "Yes"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            Vendor
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <input
            value={
              skuData.tps_loc_id && skuData.tps_loc_id.id !== "0"
                ? formatSupplierCountryIntoOption(skuData.tps_loc_id).label
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            TPS Type
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <input
            value={
              skuData.tps_type_id && skuData.tps_type_id.res_pis_type_id !== "0"
                ? formatTpsTypeIntoOption(skuData.tps_type_id).label
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col">
          <label className="form-label">Comment</label>
          <textarea
            value={skuData.comments}
            rows={numberOfTextAreaRows(skuData.comments.split("\n"))}
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">Product Packaging</h6>
      </div>

      <div className="row align-items-end mt-1 justify-content-between mb-2">
        <div className="col-3 ">
          <label className="form-label ">
            Standard Pack <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.stdpk}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-3">
          <label className="form-label">
            Standard Pack Gross Weight (kg){" "}
            <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.stdpk_weight}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-6">
          <div className="row pb-1 ">
            <div className="col label-3 bottom-border">
              <label className="form-label ">
                <strong> Standard Pack Size </strong>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-4 label-3">
              <label className="form-label">
                L (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_length}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3">
              <label className="form-label">
                W (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_width}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3">
              <label className="form-label ">
                H (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_height}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;

// WILL BE USED IN LATER ITERATIONS
/*
<label className="form-label mt-1">Associated Active Packaging Procedure</label>
      <div className="d-flex align-items-center mb-3">
        <input
          // value={ }
          type="text"
          className="form-control d-inline"
          disabled={true}
        />
      </div>
*/
