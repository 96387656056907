const TablePagination = ({
  gridRef,
  totalEntries,
  pageSize,
  currentPage,
  totalPages,
}: {
  gridRef: any;
  totalEntries: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
}) => {
  const onGoToPage = (pageNum: number) => {
    // first page is zero
    gridRef.current?.api.paginationGoToPage(pageNum - 1);
  };

  const onGoToFirst = () => {
    gridRef.current?.api.paginationGoToFirstPage();
  };

  const onGoToLast = () => {
    gridRef.current?.api.paginationGoToLastPage();
  };

  const onNext = () => {
    gridRef.current?.api.paginationGoToNextPage();
  };

  const onPrevious = () => {
    gridRef.current?.api.paginationGoToPreviousPage();
  };

  const pagesArray = Array.from(Array(totalPages).keys());
  const lastFivePages = pagesArray.slice(-5);
  const firstFivePages = pagesArray.slice(0, 5);

  return (
    <div className="d-flex justify-content-between pagination mt-2 mb-0">
      <div className="page-size-info">
        <span>Showing </span>
        {pageSize > totalEntries ? (
          <span>{totalEntries}</span>
        ) : currentPage === totalPages && totalEntries % pageSize !== 0 ? (
          <span>{totalEntries % pageSize}</span>
        ) : (
          <span>{pageSize}</span>
        )}
        <span> entries out of </span>
        <span>{totalEntries}</span>
      </div>
      <div className="page-navigation">
        <button onClick={onPrevious} className="page-button previous">
          Previous
        </button>

        {totalPages <= 6 ? (
          pagesArray.map((page) => {
            return (
              <button
                key={page}
                onClick={() => onGoToPage(page + 1)}
                className={`page-button page-num ${
                  currentPage === page + 1 ? "selected" : ""
                }`}
              >
                {page + 1}
              </button>
            );
          })
        ) : currentPage < 5 ? (
          <>
            {firstFivePages.map((page) => {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page + 1)}
                  className={`page-button page-num ${
                    currentPage === page + 1 ? "selected" : ""
                  }`}
                >
                  {page + 1}
                </button>
              );
            })}
            <span className="page-button page-num">...</span>
            <button
              onClick={onGoToLast}
              className={`page-button page-num ${
                currentPage === totalPages ? "selected" : ""
              }`}
            >
              {totalPages}
            </button>
          </>
        ) : currentPage > totalPages - 4 ? (
          <>
            <button
              onClick={onGoToFirst}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              1
            </button>
            <span className="page-button page-num">...</span>
            {lastFivePages.map((page) => {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page + 1)}
                  className={`page-button page-num ${
                    currentPage === page + 1 ? "selected" : ""
                  }`}
                >
                  {page + 1}
                </button>
              );
            })}
          </>
        ) : (
          <>
            <button
              onClick={onGoToFirst}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              1
            </button>
            <span className="page-button page-num">...</span>
            <button
              onClick={onPrevious}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              {currentPage - 1}
            </button>
            <button className="page-button page-num selected">
              {currentPage}
            </button>
            <button
              onClick={onNext}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              {currentPage + 1}
            </button>
            <span className="page-button page-num">...</span>
            <button
              onClick={onGoToLast}
              className={`page-button page-num ${
                currentPage === totalPages ? "selected" : ""
              }`}
            >
              {totalPages}
            </button>
          </>
        )}

        <button onClick={onNext} className="page-button next">
          Next
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
