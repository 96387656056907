import { IMaterial } from "../types/data.interface";

export const formatMaterialIntoOption = (item: IMaterial) => ({
    value: item,
    label: `${item.four_digit_code} - ${item.name}`,
});

export const getMaterials = (
    materials: IMaterial[],
) =>
    materials.map(formatMaterialIntoOption);