import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../utils/useAxios";

function BarcodeImageModal({
  handleCloseModal,
  fileName,
}: {
  handleCloseModal: any;
  fileName: any;
}) {
  const axios = useAxios();
  const [image, setImage] = useState(null);

  const getImage = async () => {
    try {
      const response = await axios.get(
        "/barcode-image/?image_file_name=" + fileName,
      );
      if (response.status === 200) {
        setImage(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [fileName]);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end mb-1">
        <button onClick={handleCloseModal} className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      <div className="d-flex flex-column align-items-center px-5 mx-5 ">
        <img
          src={`data:image/jpeg;base64,${image}`}
          className="mb-3 d-block"
          height={200}
          alt="barcode preview"
        />
      </div>
    </div>
  );
}

export default BarcodeImageModal;
