import { useContext, useEffect, useState } from "react";
import PMSFilters from "../components/PMSFilters";
import ProductMasterTable from "../components/ProductMasterTable";
import AuthContext from "../context/AuthContext";
import { usePageableTable } from "../context/PageableTableContext";
import {
  mapFiltersToQueryString,
  storeLastFiltersAndResults,
} from "../helpers";
import { Predicates } from "../libraries/predicates/predicates";
import { retrieveObjectFromLocalStorage } from "../helpers/local-storage.helper";
import {
  PMSPersistentData,
  PMS_PREFIX,
} from "../types/persistent-page-filters";
import { PageableTableActions } from "../states/pageable-table";

const ProductMasterPage = () => {
  const lastFilter = retrieveObjectFromLocalStorage<PMSPersistentData>(
    PMS_PREFIX,
    "lastFilters",
  );
  const lastQueryString = Predicates.isNotNullAndNotUndefined(lastFilter)
    ? mapFiltersToQueryString(lastFilter)
    : "";

  const [queryString, setQueryString] = useState(lastQueryString);
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;

  const {
    state: { total, isFirstRender },
    dispatch,
  } = usePageableTable();

  const filterProducts = (filters: any, dropdownFiltersToStore: any) => {
    storeLastFiltersAndResults(filters, dropdownFiltersToStore);
    setQueryString(mapFiltersToQueryString(filters));
    if (isFirstRender) dispatch(PageableTableActions.setFirstRender(false));
  };

  useEffect(() => {
    // dynamic favicon
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.href = process.env.PUBLIC_URL + "/search-favicon.ico";
  }, [user]);

  return (
    <>
      <div className="card shadow-sm mb-2 mt-1">
        <div className="card-body p-1">
          <PMSFilters filterProducts={filterProducts} />
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 text-primary">
            Search Results
            <span className="text-primary fw-bold">
              {` (${total} Result${total != 1 ? "s" : ""} Found)`}
            </span>
          </h6>
        </div>
        <div className="card-body">
          <ProductMasterTable
            localStoragePrefix={PMS_PREFIX}
            filters={{ queryString }}
            showCustomFields={["gp_name", "tcc_name"]}
          />
        </div>
      </div>
    </>
  );
};

export default ProductMasterPage;
