import { useEffect, useState } from "react";
import { IDesign } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  decorationTechniqueId: string;
  search?: string;
};

type DesignResponse = PageableDTO<IDesign[]> | IDesign[];

export const fetchDesign = async ({
  decorationTechniqueId,
  search,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      deco_tech_id: decorationTechniqueId,
      search,
      limit: 100,
    });

    const response = await axios.get<DesignResponse>(
      `/product-master/designs/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useDesign({
  decorationTechniqueId,
  search,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IDesign[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchDesign({
        decorationTechniqueId,
        search,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [decorationTechniqueId, search]);

  return { data, isLoading };
}
