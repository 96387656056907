import { IDesign } from "../types/data.interface";

export const formatDesignIntoOption = (item: IDesign) => ({
    value: item,
    label: `${item.id} - ${item.name}`,
});

export const getDesigns = (
    designs: IDesign[],
) =>
    designs.map(formatDesignIntoOption);