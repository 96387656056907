import { PropsWithChildren, useCallback, useState } from "react";
import ButtonIcon, { ButtonIconType } from "../../../../components/ButtonIcon";
import Modal from "../../../../components/Modal";

type ButtonModalType = Omit<ButtonIconType, "onClick"> & {
  title?: string;
  customTitle?: any;
};

export default function ButtonModal({
  description,
  buttonIconClass,
  icon,
  title,
  children,
  descriptionClass,
  customTitle,
}: PropsWithChildren<ButtonModalType>) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => setShowModal(true), []);
  const handleCloseModal = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Modal
        showModal={showModal}
        onClose={handleCloseModal}
        title={title}
        customTitle={customTitle}
      >
        {children}
      </Modal>
      <ButtonIcon
        description={description}
        buttonIconClass={buttonIconClass}
        onClick={handleOpenModal}
        icon={icon}
        descriptionClass={descriptionClass}
      />
    </>
  );
}
