import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import OptionFormatter from "./OptionFormatter";
import useAxios from "../utils/useAxios";

import {
  mapSelectOptions,
  mapSupplierOptions,
  mapMoldColorOptions,
  mapValues,
  mapSingleValue,
  currentYear,
  storeLastFiltersAndResults,
  mapMaterialOptions,
} from "../helpers";
import { ExpandableSection } from "../routes/skus/components/expandable-section";
import { useDatacatalogContext } from "../context/DatacatalogContext";
import {
  formatDatacalogIntoOption,
  getDatacatalog,
} from "../helpers/datacatalog.helper";
import { Predicates } from "../libraries/predicates/predicates";
import {
  formatGlobalProductIntoOption,
  getGlobalProduct,
} from "../helpers/global-product.helper";
import useGlobalProduct, {
  fetchGlobalProduct,
} from "../hooks/useGlobalProduct";
import { formatTccIntoOption, getTcc } from "../helpers/tcc.helper";
import useTcc, { fetchTcc } from "../hooks/useTcc";
import {
  formatDecorTechIntoOption,
  getDecorTechs,
} from "../helpers/decor-tech.helper";
import { fetchDecorTech } from "../hooks/useDecorTech";
import { useMoldContext } from "../context/MoldContext";
import { useNonMoldContext } from "../context/NonMoldContext";
import { useMaterialContext } from "../context/MaterialContext";
import { useColorContext } from "../context/ColorContext";
import { useDecorationTechContext } from "../context/DecorationTechContext";
import { fetchMold } from "../hooks/useMold";
import { fetchNonMold } from "../hooks/useNonMold";
import { fetchMaterial } from "../hooks/useMaterial";
import { fetchColor } from "../hooks/useColor";
import { useBuyerContext } from "../context/BuyerContext";
import { useSupplierCountryContext } from "../context/SupplierCountryContext";
import useDesign, { fetchDesign } from "../hooks/useDesign";
import { getDesigns } from "../helpers/design.helper";
import { getBrands } from "../helpers/brand.helper";
import useBrand from "../hooks/useBrand";
import { getTpsTypes } from "../helpers/tps-type.helper";
import useTpsType from "../hooks/useTpsType";
import useMarketingMaterial from "../hooks/useMarketingMaterial";
import useFlavorScent from "../hooks/useFlavorScent";
import useChannel from "../hooks/useChannel";
import useLicensor from "../hooks/useLicensor";
import useLicensedProperty, {
  fetchLicensedProperty,
} from "../hooks/useLicensedProperty";
import { getFlavorScents } from "../helpers/flavor-scent.helper";
import { getLicensors } from "../helpers/licensor.helper";
import { getLicensedPropertys } from "../helpers/licensed-property.helper";
import { getChannels } from "../helpers/channel.helper";
import { getMarketingMaterials } from "../helpers/marketing-material.helper";
import {
  DebounceContext,
  DebounceContextType,
} from "../context/DebounceContext";
import { Tooltip } from "react-tooltip";
import { retrieveObjectFromLocalStorage } from "../helpers/local-storage.helper";
import {
  PMSPersistentData,
  PMS_PREFIX,
} from "../types/persistent-page-filters";

const PMSFilters = ({ filterProducts }: { filterProducts: any }) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);

  // Global Product Search Fields
  const [globalProduct, setGlobalProduct] = useState<any | null>("");
  const [categorySelected, setCategorySelected] = useState<any | null>(null);
  const [subCategorySelected, setSubCategorySelected] = useState<any | null>(
    null,
  );
  const [productLineSelected, setProductLineSelected] = useState<any | null>(
    null,
  );
  const [alwaysOnSelected, setAlwaysOnSelected] = useState<any | null>(null);
  const [brandSelected, setBrandSelected] = useState<any | null>(null);
  const [sourcingTypeSelected, setSourcingTypeSelected] = useState<any | null>(
    null,
  );
  const [tpsTypeSelected, setTpsTypeSelected] = useState<any | null>(null);
  const [vendorSelected, setVendorSelected] = useState<any | null>(null);
  const [capacityValue, setCapacityValue] = useState<any | null>("");
  const [capacityUnitsSelected, setCapacityUnitsSelected] = useState<
    any | null
  >(null);

  // TCC search fields
  const [tcc, setTcc] = useState<any | null>("");
  const [productColorSelected, setProductColorSelected] = useState<any | null>(
    null,
  );
  const [royaltyItemSelected, setRoyaltyItemSelected] = useState<any | null>(
    null,
  );
  const [piecesSellingUnitsOperator, setPiecesSellingUnitsOperator] = useState<
    any | null
  >({
    label: "=",
    value: "pieces_selling_units",
  });
  const [piecesSellingUnits, setPiecesSellingUnits] = useState<any | null>("");
  const [tccArtworkSelected, setTccArtworkSelected] = useState<any | null>(
    null,
  );
  const [consumerFacingNameValue, setConsumerFacingNameValue] = useState<
    any | null
  >("");
  const [productionStatusSelected, setProductionStatusSelected] = useState<
    any | null
  >(null);
  const [salesStatusSelected, setSalesStatusSelected] = useState<any | null>(
    null,
  );
  const [globalStandardCostValue, setGlobalStandardCostValue] = useState<
    any | null
  >(null);
  const [globalManufacturerPriceValue, setGlobalManufacturerPriceValue] =
    useState<any | null>(null);
  const [upcValue, setUpcValue] = useState<any | null>(null);
  const [gtinValue, setGtinValue] = useState<any | null>(null);
  const [marketingMaterialSelected, setMarketingMaterialSelected] = useState<
    any | null
  >(null);
  const [channelSelected, setChannelSelected] = useState<any | null>(null);
  const [licensorSelected, setLicensorSelected] = useState<any | null>(null);
  const [licensedPropertySelected, setLicensedPropertySelected] = useState<
    any | null
  >(null);
  const [flavorScentSelected, setFlavorScentSelected] = useState<any | null>(
    null,
  );

  // SKU and Components Search Fields
  const [productNumber, setProductNumber] = useState<any | null>("");
  const [productDescription, setProductDescription] = useState<any | null>("");
  const [moldsSelected, setMoldsSelected] = useState<any | null>(null);
  const [nonMoldedComponentsSelected, setNonMoldedComponentsSelected] =
    useState<any | null>(null);
  const [materialsSelected, setMaterialsSelected] = useState<any | null>(null);
  const [colorsSelected, setColorsSelected] = useState<any | null>(null);
  const [decoTechSelected, setDecoTechSelected] = useState<any | null>(null);
  const [designSelected, setDesignSelected] = useState<any | null>(null);

  // More search fields
  const [buyersSelected, setBuyersSelected] = useState<any | null>(null);
  const [releaseDateOperator, setReleaseDateOperator] = useState({
    label: "=",
    value: "release_date",
  });
  const [releaseDate, setReleaseDate] = useState<any | null>("");
  const [supplierCountrySelected, setSupplierCountrySelected] = useState<
    any | null
  >(null);
  const [discontinuedSelected, setDiscontinuedSelected] = useState<any | null>(
    null,
  );
  const [stockReportedSelected, setStockReportedSelected] = useState<
    any | null
  >(null);

  // Options lists
  const yesOrNoOptions = [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ];

  const booleanOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];

  const sourcingTypeOptions = [
    { label: "TPS", value: "TPS" },
    { label: "In-House", value: "In-House" },
    { label: "Hybrid", value: "Hybrid" },
  ];

  const capacityUnitsOfMeasureOptions = [
    { label: "ML", value: "ML" },
    { label: "L", value: "L" },
    { label: "CUP", value: "CUP" },
    { label: "OZ", value: "OZ" },
    { label: "QT", value: "QT" },
  ];

  const lifecycleStatusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const bundlingOptions = [
    { label: "Individual", value: "I" },
    { label: "Kit", value: "K" },
    { label: "Bundle", value: "B" },
    { label: "Set", value: "S" },
  ];

  const buttonDisabled =
    (!productNumber || !productNumber.trim()) &&
    (!productDescription || !productDescription.trim()) &&
    !designSelected &&
    !releaseDate &&
    !globalProduct &&
    !brandSelected &&
    !tcc &&
    !productColorSelected &&
    (!moldsSelected || !moldsSelected.length) &&
    (!nonMoldedComponentsSelected || !nonMoldedComponentsSelected.length) &&
    (!colorsSelected || !colorsSelected.length) &&
    (!materialsSelected || !materialsSelected.length) &&
    !decoTechSelected &&
    !buyersSelected &&
    !supplierCountrySelected &&
    !categorySelected &&
    !subCategorySelected &&
    !productLineSelected &&
    !alwaysOnSelected &&
    !stockReportedSelected &&
    !piecesSellingUnits &&
    !royaltyItemSelected &&
    !discontinuedSelected &&
    !sourcingTypeSelected &&
    !tpsTypeSelected &&
    !vendorSelected &&
    (!capacityValue || !capacityValue.trim()) &&
    !capacityUnitsSelected &&
    !tccArtworkSelected &&
    (!consumerFacingNameValue || !consumerFacingNameValue.trim()) &&
    !productionStatusSelected &&
    !salesStatusSelected &&
    (!globalStandardCostValue || !globalStandardCostValue.trim()) &&
    (!globalManufacturerPriceValue || !globalManufacturerPriceValue.trim()) &&
    (!upcValue || !upcValue.trim()) &&
    (!gtinValue || !gtinValue.trim()) &&
    !marketingMaterialSelected &&
    !channelSelected &&
    !licensorSelected &&
    !licensedPropertySelected &&
    !flavorScentSelected
      ? true
      : false;

  const { datacatalog, isLoading: isDatacatalogLoading } =
    useDatacatalogContext();

  const { data: globalProducts, isLoading: isGlobalProductsLoading } =
    useGlobalProduct({
      category: subCategorySelected?.parent?.id ?? categorySelected?.id,
      sub_category: subCategorySelected?.id,
      product_line: productLineSelected?.id,
      id: globalProduct?.id,
    });

  const { data: tccs, isLoading: isTccsLoading } = useTcc({
    category: subCategorySelected?.parent?.id ?? categorySelected?.id,
    sub_category: subCategorySelected?.id,
    product_line: productLineSelected?.id,
    global_product: globalProduct?.value?.id,
  });

  const { data: moldsFetched, isLoading: isMoldsLoading } = useMoldContext();
  const { data: nonMoldedComponentsFetched, isLoading: isNonMoldsLoading } =
    useNonMoldContext();
  const { data: materialsFetched, isLoading: isMaterialsLoading } =
    useMaterialContext();
  const { data: colorsFetched, isLoading: isColorsLoading } = useColorContext();
  const { data: decoTechs, isLoading: isDecoTechsLoading } =
    useDecorationTechContext();
  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: String(decoTechSelected?.value?.id ?? ""),
  });
  const { data: buyers, isLoading: isBuyersLoading } = useBuyerContext();
  const { data: supplierCountries, isLoading: isSupplierCountriesLoading } =
    useSupplierCountryContext();
  const { data: brands, isLoading: isBrandsLoading } = useBrand({});
  const { data: tpsTypes, isLoading: isTpsTypesLoading } = useTpsType({});
  const { data: marketingMaterials, isLoading: isMarketingMaterialsLoading } =
    useMarketingMaterial({});
  const { data: flavorScents, isLoading: isFlavorScentsLoading } =
    useFlavorScent({});
  const { data: channels, isLoading: isChannelsLoading } = useChannel({});
  const { data: licensors, isLoading: isLicensorsLoading } = useLicensor({});
  const { data: licensedProperties, isLoading: isLicensedPropertiesLoading } =
    useLicensedProperty({
      licensorId: String(licensorSelected?.value?.id.toString() ?? ""),
    });

  const globalProductOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchGlobalProduct({
      category: subCategorySelected?.parent?.id ?? categorySelected?.id,
      sub_category: subCategorySelected?.id,
      product_line: productLineSelected?.id,
      search,
      axios,
    });

    callback(
      getGlobalProduct(response, {
        subCategory: subCategorySelected?.id,
        productLine: productLineSelected?.id,
        id: globalProduct?.value?.id,
      }),
    );
  };

  const tccOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchTcc({
      category: subCategorySelected?.parent?.id ?? categorySelected?.id,
      sub_category: subCategorySelected?.id,
      product_line: productLineSelected?.id,
      global_product: globalProduct?.value?.id,
      search,
      axios,
    });
    callback(getTcc(response));
  };

  const decoTechOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchDecorTech({
      search,
      axios,
    });
    callback(getDecorTechs(response));
  };

  const designOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchDesign({
      decorationTechniqueId: String(String(decoTechSelected?.value?.id ?? "")),
      search,
      axios,
    });
    callback(getDesigns(response));
  };

  const tccArtworkOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchDesign({
      decorationTechniqueId: String(""),
      search,
      axios,
    });
    callback(getDesigns(response));
  };

  const licensedPropertiesOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchLicensedProperty({
      licensorId: String(String(licensorSelected?.value?.id.toString() ?? "")),
      search,
      axios,
    });
    callback(getLicensedPropertys(response));
  };

  const handleGlobalProduct = (e: any) => {
    if (Predicates.isNotNullAndNotUndefined(e)) {
      setGlobalProduct(e);
      setCategorySelected(e?.value.sub_category.parent);
      setSubCategorySelected(e?.value.sub_category);
      setProductLineSelected(e?.value.product_line);
    } else {
      setGlobalProduct(null);
      setCategorySelected(null);
      setSubCategorySelected(null);
      setProductLineSelected(null);
      setTcc(null);
    }
  };

  const handleTcc = (e: any) => {
    if (Predicates.isNotNullAndNotUndefined(e)) {
      setTcc(e);
      setGlobalProduct(formatGlobalProductIntoOption(e?.value?.global_product));
      setCategorySelected(e?.value?.global_product.sub_category.parent);
      setSubCategorySelected(e?.value?.global_product.sub_category);
      setProductLineSelected(e?.value?.global_product.product_line);
    } else {
      setTcc(null);
      setGlobalProduct(null);
      setCategorySelected(null);
      setSubCategorySelected(null);
      setProductLineSelected(null);
    }
  };

  // Select input handlers
  const handleCategoryChange = (e: any) => {
    setCategorySelected(e?.value ?? e);
    setSubCategorySelected(null);
  };
  const handleSubCategoryChange = (e: any) => {
    setSubCategorySelected(e?.value ?? null);
    setCategorySelected(e?.value.parent ?? null);
  };
  const handleProductLineChange = (e: any) => {
    setProductLineSelected(e?.value ?? null);
  };

  const handleReleaseDateOperator = (e: any) => {
    if (e) {
      setReleaseDateOperator(e);
    } else {
      setReleaseDateOperator({ label: "=", value: "release_date" });
    }
  };
  const handleCapacityChange = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setCapacityValue(e.target.value);
    }
  };
  const handlePiecesSellingUnitsOperator = (e: any) => {
    setPiecesSellingUnitsOperator(
      e ? e : { label: "=", value: "pieces_selling_units" },
    );
  };
  const handleGlobalStandardCostChange = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setGlobalStandardCostValue(e.target.value);
    }
  };
  const handleGlobalManufacturerPriceChange = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setGlobalManufacturerPriceValue(e.target.value);
    }
  };

  const loadMolds = async (input: string, callback: any) => {
    let options: any = mapMoldColorOptions(moldsFetched);
    if (input.length > 2) {
      const response = await fetchMold({ search: input, axios });
      options = mapMoldColorOptions(response);
    }
    callback(options);
  };
  const loadNonMoldedComponents = async (input: string, callback: any) => {
    let options: any = mapMoldColorOptions(nonMoldedComponentsFetched);
    if (input.length > 2) {
      const response = await fetchNonMold({ search: input, axios });
      options = mapMoldColorOptions(response);
    }
    callback(options);
  };
  const loadMaterial = async (input: string, callback: any) => {
    let options: any = mapMaterialOptions(materialsFetched);
    if (input.length > 2) {
      const response = await fetchMaterial({ search: input, axios });
      options = mapMaterialOptions(response);
    }
    callback(options);
  };
  const loadColors = async (input: string, callback: any) => {
    let options: any = mapMoldColorOptions(colorsFetched);
    if (input.length > 2) {
      const response = await fetchColor({ search: input, axios });
      options = mapMoldColorOptions(response);
    }
    callback(options);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const dropdownFilters = {
      globalProduct: globalProduct,
      tcc: tcc,
      category: categorySelected,
      subCategory: subCategorySelected,
      productLine: productLineSelected,
      moldedComponents: moldsSelected,
      nonMoldedComponents: nonMoldedComponentsSelected,
      materials: materialsSelected,
      colors: colorsSelected,
      decorationTech: decoTechSelected,
      design: designSelected,
      buyer: buyersSelected,
      supplierCountry: supplierCountrySelected,
      product_color: productColorSelected,
      always_on: alwaysOnSelected,
      stock_reported: stockReportedSelected,
      royaltyitem: royaltyItemSelected,
      discontinued: discontinuedSelected,
      brand: brandSelected,
      tpsProduct: sourcingTypeSelected,
      tpsType: tpsTypeSelected,
      vendor: vendorSelected,
      capacityUnits: capacityUnitsSelected,
      tccArtwork: tccArtworkSelected,
      productionStatus: productionStatusSelected,
      salesStatus: salesStatusSelected,
      marketingMaterial: marketingMaterialSelected,
      channel: channelSelected,
      licensor: licensorSelected,
      licensedProperty: licensedPropertySelected,
      flavorScent: flavorScentSelected,
    };

    const searchParams = new URLSearchParams();
    searchParams.append("", productDescription);

    const filterObject = {
      id: productNumber ? productNumber : null,
      description: productDescription ? searchParams.toString().slice(1) : null,
      global_product_id: globalProduct
        ? globalProduct.value.id.toString()
        : null,
      tcc_id: tcc ? tcc.value.id.toString() : null,
      molds: mapValues(moldsSelected),
      non_molded_components: mapValues(nonMoldedComponentsSelected),
      colors: mapValues(colorsSelected),
      materials: mapValues(materialsSelected),
      category: categorySelected ? categorySelected.id.toString() : null,
      sub_category: subCategorySelected
        ? subCategorySelected.id.toString()
        : null,
      product_line: productLineSelected
        ? productLineSelected.id.toString()
        : null,
      decoration_technology: decoTechSelected?.value
        ? decoTechSelected.value.id.toString()
        : null,
      supplier: mapSingleValue(supplierCountrySelected),
      buyer: mapSingleValue(buyersSelected),
      design: designSelected?.value ? designSelected.value.id.toString() : null,
      product_color: mapSingleValue(productColorSelected),

      always_on: mapSingleValue(alwaysOnSelected),
      stock_reported: mapSingleValue(stockReportedSelected),
      // pieces in selling units key is based on operator
      [piecesSellingUnitsOperator.value]: piecesSellingUnits
        ? piecesSellingUnits
        : null,
      royaltyitem: mapSingleValue(royaltyItemSelected),
      discontinued: mapSingleValue(discontinuedSelected),
      // release date key is based on operator
      [releaseDateOperator.value]: releaseDate ? releaseDate : null,
      brand: brandSelected?.value ? brandSelected.value.id.toString() : null,
      tps_product: mapSingleValue(sourcingTypeSelected),
      tps_type: tpsTypeSelected?.value
        ? tpsTypeSelected.value.res_pis_type_id.toString()
        : null,
      vendor: mapSingleValue(vendorSelected),
      capacity_value: capacityValue ? capacityValue : null,
      capacity_uom: mapSingleValue(capacityUnitsSelected),
      tcc_artwork: tccArtworkSelected?.value
        ? tccArtworkSelected.value.id.toString()
        : null,
      consumer_facing_name: consumerFacingNameValue
        ? consumerFacingNameValue
        : null,
      production_status: mapSingleValue(productionStatusSelected),
      sales_status: mapSingleValue(salesStatusSelected),
      global_standard_cost: globalStandardCostValue
        ? globalStandardCostValue
        : null,
      global_manufacturer_price: globalManufacturerPriceValue
        ? globalManufacturerPriceValue
        : null,
      upc: upcValue ? upcValue : null,
      gtin: gtinValue ? gtinValue : null,
      marketing_material: marketingMaterialSelected?.value
        ? marketingMaterialSelected.value.id.toString()
        : null,
      channel: channelSelected?.value
        ? channelSelected.value.id.toString()
        : null,
      licensor: licensorSelected?.value
        ? licensorSelected.value.id.toString()
        : null,
      licensed_property: licensedPropertySelected?.value
        ? licensedPropertySelected.value.id.toString()
        : null,
      flavor_scent: flavorScentSelected?.value
        ? flavorScentSelected.value.id.toString()
        : null,
    };

    filterProducts(filterObject, dropdownFilters);
  };

  const setFiltersBasedOnLocalStorageValues = () => {
    const storedFilters = retrieveObjectFromLocalStorage<PMSPersistentData>(
      PMS_PREFIX,
      "lastFilters",
    );
    if (storedFilters) {
      setProductNumber(storedFilters.id ?? "");
      setProductDescription(storedFilters.description ?? "");

      const releaseDateValues = getOperatorAndValue(
        storedFilters,
        "release_date",
      );
      setReleaseDateOperator(
        releaseDateValues?.operator ?? { label: "=", value: "release_date" },
      );
      setReleaseDate(releaseDateValues?.value);

      const piecesSellingUnitsValues = getOperatorAndValue(
        storedFilters,
        "pieces_selling_units",
      );
      setPiecesSellingUnitsOperator(
        piecesSellingUnitsValues?.operator ?? {
          label: "=",
          value: "pieces_selling_units",
        },
      );
      setPiecesSellingUnits(piecesSellingUnitsValues?.value);

      setCapacityValue(storedFilters.capacity_value ?? "");
      setConsumerFacingNameValue(storedFilters.consumer_facing_name ?? "");
      setGlobalStandardCostValue(storedFilters.global_standard_cost ?? "");
      setGlobalManufacturerPriceValue(
        storedFilters.global_manufacturer_price ?? "",
      );
      setUpcValue(storedFilters.upc ?? "");
      setGtinValue(storedFilters.gtin ?? "");
    }
  };

  const getOperatorAndValue = (storedFilters: any, keyPrefix: string) => {
    for (let key in storedFilters) {
      if (key.includes(keyPrefix)) {
        const releaseDateKeySplit = key.split("__");
        let label = "";
        switch (releaseDateKeySplit[1]) {
          case "gt":
            label = ">";
            break;
          case "gte":
            label = ">=";
            break;
          case "lt":
            label = "<";
            break;
          case "lte":
            label = "<=";
            break;
          default:
            label = "=";
            break;
        }
        return {
          operator: { label: label, value: key },
          value: storedFilters[key] ?? "",
        };
      }
    }
  };

  const setDropdownFiltersBasedOnLocalStorageValues = () => {
    const storedDropdownFilters =
      retrieveObjectFromLocalStorage<PMSPersistentData>(
        PMS_PREFIX,
        "lastDropdownFilters",
      );
    if (storedDropdownFilters) {
      setGlobalProduct(storedDropdownFilters.globalProduct ?? "");
      setTcc(storedDropdownFilters.tcc ?? "");
      setProductColorSelected(storedDropdownFilters.product_color ?? "");
      setCategorySelected(storedDropdownFilters.category ?? "");
      setSubCategorySelected(storedDropdownFilters.subCategory ?? "");
      setProductLineSelected(storedDropdownFilters.productLine ?? "");
      setDecoTechSelected(storedDropdownFilters.decorationTech ?? "");
      setDesignSelected(storedDropdownFilters.design ?? "");
      setBuyersSelected(storedDropdownFilters.buyer ?? "");
      setSupplierCountrySelected(storedDropdownFilters.supplierCountry ?? "");
      setMoldsSelected(storedDropdownFilters.moldedComponents ?? []);
      setNonMoldedComponentsSelected(
        storedDropdownFilters.nonMoldedComponents ?? [],
      );
      setMaterialsSelected(storedDropdownFilters.materials ?? []);
      setColorsSelected(storedDropdownFilters.colors ?? []);
      setAlwaysOnSelected(storedDropdownFilters.always_on ?? "");
      setStockReportedSelected(storedDropdownFilters.stock_reported ?? "");
      setRoyaltyItemSelected(storedDropdownFilters.royaltyitem ?? "");
      setDiscontinuedSelected(storedDropdownFilters.discontinued ?? "");
      setBrandSelected(storedDropdownFilters.brand ?? "");
      setSourcingTypeSelected(storedDropdownFilters.tpsProduct ?? "");
      setTpsTypeSelected(storedDropdownFilters.tpsType ?? "");
      setVendorSelected(storedDropdownFilters.vendor ?? "");
      setCapacityUnitsSelected(storedDropdownFilters.capacityUnits ?? "");
      setTccArtworkSelected(storedDropdownFilters.tccArtwork ?? "");
      setProductionStatusSelected(storedDropdownFilters.productionStatus ?? "");
      setSalesStatusSelected(storedDropdownFilters.salesStatus ?? "");
      setMarketingMaterialSelected(
        storedDropdownFilters.marketingMaterial ?? "",
      );
      setChannelSelected(storedDropdownFilters.channel ?? "");
      setLicensorSelected(storedDropdownFilters.licensor ?? "");
      setLicensedPropertySelected(storedDropdownFilters.licensedProperty ?? "");
      setFlavorScentSelected(storedDropdownFilters.flavorScent ?? "");
    }
  };

  useEffect(() => {
    setFiltersBasedOnLocalStorageValues();
    setDropdownFiltersBasedOnLocalStorageValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAllFilters = () => {
    setGlobalProduct("");
    setTcc("");
    setProductColorSelected("");
    setCategorySelected("");
    setSubCategorySelected("");
    setProductLineSelected("");
    setProductNumber("");
    setProductDescription("");
    setMoldsSelected([]);
    setNonMoldedComponentsSelected([]);
    setMaterialsSelected([]);
    setColorsSelected([]);
    setDecoTechSelected("");
    setDesignSelected("");
    setBuyersSelected("");
    setReleaseDateOperator({ label: "=", value: "release_date" });
    setReleaseDate("");
    setSupplierCountrySelected("");
    setAlwaysOnSelected("");
    setStockReportedSelected("");
    setPiecesSellingUnitsOperator({
      label: "=",
      value: "pieces_selling_units",
    });
    setPiecesSellingUnits("");
    setRoyaltyItemSelected("");
    setDiscontinuedSelected("");
    setBrandSelected("");
    setSourcingTypeSelected("");
    setTpsTypeSelected("");
    setVendorSelected("");
    setCapacityValue("");
    setCapacityUnitsSelected("");
    setTccArtworkSelected("");
    setConsumerFacingNameValue("");
    setProductionStatusSelected("");
    setSalesStatusSelected("");
    setGlobalStandardCostValue("");
    setGlobalManufacturerPriceValue("");
    setUpcValue("");
    setGtinValue("");
    setMarketingMaterialSelected("");
    setChannelSelected("");
    setLicensorSelected("");
    setLicensedPropertySelected("");
    setFlavorScentSelected("");

    storeLastFiltersAndResults({}, null);
  };

  return (
    <>
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Global Product Code / Name</label>
            <AsyncSelect
              name="globalProduct"
              value={globalProduct}
              onChange={handleGlobalProduct}
              defaultOptions={getGlobalProduct(globalProducts, {
                subCategory: subCategorySelected?.id,
                productLine: productLineSelected?.id,
                id: globalProduct?.value?.id,
              })}
              loadOptions={(input, callback) => {
                searchDebounce(
                  globalProductOptions,
                  input,
                  getGlobalProduct(globalProducts, {
                    subCategory: subCategorySelected?.id,
                    productLine: productLineSelected?.id,
                    id: globalProduct?.value?.id,
                  }),
                  callback,
                );
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isGlobalProductsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 characters)"
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-lg-3 form-group">
            <label className="form-label">Category</label>
            <Select
              options={getDatacatalog(datacatalog, { type: "Category" })}
              onChange={handleCategoryChange}
              formatOptionLabel={OptionFormatter}
              value={
                categorySelected
                  ? formatDatacalogIntoOption(categorySelected)
                  : null
              }
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-3 form-group">
            <label className="form-label">Sub-Category</label>
            <Select
              options={getDatacatalog(datacatalog, {
                type: "Sub-Category",
                categoryId: categorySelected?.id,
              })}
              onChange={handleSubCategoryChange}
              formatOptionLabel={OptionFormatter}
              value={
                subCategorySelected
                  ? formatDatacalogIntoOption(subCategorySelected)
                  : null
              }
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-3 form-group">
            <label className="form-label">Product Line</label>
            <Select
              options={getDatacatalog(datacatalog, { type: "Product Line" })}
              onChange={handleProductLineChange}
              value={
                productLineSelected
                  ? formatDatacalogIntoOption(productLineSelected)
                  : null
              }
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group product-number-col">
            <label className="form-label">SKU nº</label>
            <div className="d-flex align-items-center">
              <span className="mr-2">11 </span>
              <input
                onChange={(e) =>
                  setProductNumber(e.target.value.replace(/[^0-9]/gi, ""))
                }
                value={productNumber}
                minLength={3}
                placeholder="(min 3 digits)"
                maxLength={6}
                type="text"
                className="form-control d-inline"
              />
            </div>
          </div>

          <div className="col-lg form-group">
            <label className="form-label">SKU Description</label>
            <input
              onChange={(e) => setProductDescription(e.target.value)}
              value={productDescription}
              placeholder="(min 3 characters)"
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
            />
          </div>

          <div className="col-lg-6 form-group">
            <label className="form-label">TCC Code / Name</label>
            <AsyncSelect
              name="tcc"
              value={tcc}
              onChange={handleTcc}
              defaultOptions={tccs.map(formatTccIntoOption)}
              loadOptions={(input, callback) => {
                searchDebounce(
                  tccOptions,
                  input,
                  tccs.map(formatTccIntoOption),
                  callback,
                );
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isTccsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 characters)"
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Molded Components </label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMolds,
                  input,
                  mapMoldColorOptions(moldsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={mapMoldColorOptions(moldsFetched)}
              onChange={setMoldsSelected}
              value={moldsSelected}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                moldsSelected && moldsSelected.length >= 5
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 molded components)"
              isClearable
              isLoading={isMoldsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Non-Molded Components</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadNonMoldedComponents,
                  input,
                  mapMoldColorOptions(nonMoldedComponentsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={mapMoldColorOptions(nonMoldedComponentsFetched)}
              onChange={setNonMoldedComponentsSelected}
              value={nonMoldedComponentsSelected}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                nonMoldedComponentsSelected &&
                nonMoldedComponentsSelected.length >= 5
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 non-molded components)"
              isClearable
              isLoading={isNonMoldsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Component Material</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMaterial,
                  input,
                  mapMaterialOptions(materialsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={mapMaterialOptions(materialsFetched)}
              onChange={setMaterialsSelected}
              value={materialsSelected}
              isOptionDisabled={() =>
                materialsSelected && materialsSelected.length >= 5
              }
              isMulti
              placeholder="(min 3 characters, up to 5 materials)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isMaterialsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Component Color</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  mapMoldColorOptions(colorsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={mapMoldColorOptions(colorsFetched)}
              onChange={setColorsSelected}
              value={colorsSelected}
              isOptionDisabled={() =>
                colorsSelected && colorsSelected.length >= 5
              }
              isMulti
              placeholder="(min 3 characters, up to 5 colors)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">
              Component Decoration Technology
            </label>
            <AsyncSelect
              loadOptions={(input, callback) => {
                searchDebounce(
                  decoTechOptions,
                  input,
                  decoTechs.map(formatDecorTechIntoOption),
                  callback,
                );
              }}
              defaultOptions={decoTechs.map(formatDecorTechIntoOption)}
              onChange={setDecoTechSelected}
              formatOptionLabel={OptionFormatter}
              value={decoTechSelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDecoTechsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Component Artwork</label>
            <AsyncSelect
              loadOptions={(input, callback) => {
                searchDebounce(
                  designOptions,
                  input,
                  getDesigns(designs),
                  callback,
                );
              }}
              defaultOptions={getDesigns(designs)}
              onChange={setDesignSelected}
              formatOptionLabel={OptionFormatter}
              value={designSelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDesignsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <ExpandableSection
          sectionId="advancedFields"
          title="Advanced Search Fields"
          className="mt-1"
        >
          <ExpandableSection
            className="mb-2"
            sectionId="GP"
            title="Global Product Attributes"
          >
            <div className="form-row form-group d-flex justify-content-start mb-0">
              <div className="col-2 form-group">
                <label className="form-label">Always On</label>
                <Select
                  options={booleanOptions}
                  onChange={setAlwaysOnSelected}
                  formatOptionLabel={OptionFormatter}
                  value={alwaysOnSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Brand</label>
                <Select
                  onChange={setBrandSelected}
                  formatOptionLabel={OptionFormatter}
                  value={brandSelected}
                  options={getBrands(brands)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isBrandsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group ">
                <label className="form-label">Product Capacity Value</label>
                <input
                  onChange={handleCapacityChange}
                  value={capacityValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-lg form-group ">
                <label className="form-label">
                  Product Capacity Unit of Measure
                </label>
                <Select
                  onChange={setCapacityUnitsSelected}
                  formatOptionLabel={OptionFormatter}
                  value={capacityUnitsSelected}
                  options={capacityUnitsOfMeasureOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>

          <ExpandableSection
            className="mb-2"
            sectionId="TCC"
            title="TCC Attributes"
          >
            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">Product Color</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadColors,
                      input,
                      mapMoldColorOptions(colorsFetched) ?? [],
                      callback,
                    );
                  }}
                  defaultOptions={mapMoldColorOptions(colorsFetched)}
                  onChange={setProductColorSelected}
                  value={productColorSelected}
                  placeholder="(min 3 characters)"
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  isClearable
                  isLoading={colorsFetched.length == 0}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Material (Marketing) </label>
                <Select
                  onChange={setMarketingMaterialSelected}
                  formatOptionLabel={OptionFormatter}
                  value={marketingMaterialSelected}
                  options={getMarketingMaterials(marketingMaterials)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isMarketingMaterialsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Artwork </label>
                <AsyncSelect
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      tccArtworkOptions,
                      input,
                      getDesigns(designs),
                      callback,
                    );
                  }}
                  defaultOptions={getDesigns(designs)}
                  onChange={setTccArtworkSelected}
                  formatOptionLabel={OptionFormatter}
                  value={tccArtworkSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isDesignsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Channel</label>
                <Select
                  onChange={setChannelSelected}
                  formatOptionLabel={OptionFormatter}
                  value={channelSelected}
                  options={getChannels(channels)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isChannelsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-lg form-group">
                <label className="form-label">
                  No of Pieces in Selling Unit (Sets)
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    options={[
                      { label: "=", value: "pieces_selling_units" },
                      { label: ">", value: "pieces_selling_units__gt" },
                      { label: ">=", value: "pieces_selling_units__gte" },
                      { label: "<", value: "pieces_selling_units__lt" },
                      { label: "<=", value: "pieces_selling_units__lte" },
                    ]}
                    onChange={handlePiecesSellingUnitsOperator}
                    value={piecesSellingUnitsOperator}
                    formatOptionLabel={OptionFormatter}
                    classNamePrefix="react-select"
                    className="mr-1 release-date-operator"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <input
                    onChange={(e) =>
                      setPiecesSellingUnits(
                        e.target.value.replace(/[^0-9]/gi, ""),
                      )
                    }
                    value={piecesSellingUnits}
                    type="text"
                    className="form-control flex-grow-1"
                  />
                </div>
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Consumer Facing Name</label>
                <input
                  onChange={(e) => setConsumerFacingNameValue(e.target.value)}
                  value={consumerFacingNameValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Licensor </label>
                <Select
                  onChange={setLicensorSelected}
                  formatOptionLabel={OptionFormatter}
                  value={licensorSelected}
                  options={getLicensors(licensors)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLicensorsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Licensed Property </label>
                <AsyncSelect
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      licensedPropertiesOptions,
                      input,
                      getLicensedPropertys(licensedProperties),
                      callback,
                    );
                  }}
                  defaultOptions={getLicensedPropertys(licensedProperties)}
                  onChange={setLicensedPropertySelected}
                  formatOptionLabel={OptionFormatter}
                  value={licensedPropertySelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLicensedPropertiesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">Royalty Item </label>
                <Select
                  onChange={setRoyaltyItemSelected}
                  formatOptionLabel={OptionFormatter}
                  value={royaltyItemSelected}
                  options={booleanOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Flavor/Scent </label>
                <Select
                  onChange={setFlavorScentSelected}
                  formatOptionLabel={OptionFormatter}
                  value={flavorScentSelected}
                  options={getFlavorScents(flavorScents)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isFlavorScentsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Global Standard Cost</label>
                <input
                  onChange={handleGlobalStandardCostChange}
                  value={globalStandardCostValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-md-3 form-group">
                <Tooltip
                  id="global-msrp"
                  className="tooltip-text-box"
                  place="top-start"
                  noArrow={true}
                />
                <label
                  className="form-label"
                  data-tooltip-id="global-msrp"
                  data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
                >
                  Global MSRP
                </label>
                <input
                  onChange={handleGlobalManufacturerPriceChange}
                  value={globalManufacturerPriceValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">UPC 12 Digits US</label>
                <input
                  onChange={(e) => setUpcValue(e.target.value)}
                  value={upcValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="form-label">GTIN 14 Digits RoW</label>
                <input
                  onChange={(e) => setGtinValue(e.target.value)}
                  value={gtinValue}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="form-label">
                  Production Lifecycle Status
                </label>
                <Select
                  onChange={setProductionStatusSelected}
                  formatOptionLabel={OptionFormatter}
                  value={productionStatusSelected}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="form-label">Sales Lifecycle Status</label>
                <Select
                  onChange={setSalesStatusSelected}
                  formatOptionLabel={OptionFormatter}
                  value={salesStatusSelected}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>

          <ExpandableSection sectionId="otherFields" title="More Search Fields">
            <div className="row form-row align-items-end mb-0">
              <div className="col-lg form-group">
                <label className="form-label">TPS Product</label>
                <Select
                  onChange={setSourcingTypeSelected}
                  formatOptionLabel={OptionFormatter}
                  value={sourcingTypeSelected}
                  options={yesOrNoOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Vendor</label>
                <Select
                  onChange={setVendorSelected}
                  formatOptionLabel={OptionFormatter}
                  value={vendorSelected}
                  options={mapSupplierOptions(supplierCountries)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isSupplierCountriesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">TPS Type</label>
                <Select
                  onChange={setTpsTypeSelected}
                  formatOptionLabel={OptionFormatter}
                  value={tpsTypeSelected}
                  options={getTpsTypes(tpsTypes)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isTpsTypesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Discontinued</label>
                <Select
                  options={yesOrNoOptions}
                  onChange={setDiscontinuedSelected}
                  formatOptionLabel={OptionFormatter}
                  value={discontinuedSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row align-items-end mb-0">
              <div className="col-lg form-group">
                <label className="form-label">Buyers</label>
                <Select
                  options={mapSelectOptions(buyers)}
                  onChange={setBuyersSelected}
                  formatOptionLabel={OptionFormatter}
                  value={buyersSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isBuyersLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Release date</label>
                <div className="form-inline">
                  <Select
                    options={[
                      { label: "=", value: "release_date" },
                      { label: ">", value: "release_date__gt" },
                      { label: ">=", value: "release_date__gte" },
                      { label: "<", value: "release_date__lt" },
                      { label: "<=", value: "release_date__lte" },
                    ]}
                    onChange={handleReleaseDateOperator}
                    value={releaseDateOperator}
                    formatOptionLabel={OptionFormatter}
                    classNamePrefix="react-select"
                    className="mr-1 release-date-operator"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <input
                    onChange={(e) => setReleaseDate(e.target.value)}
                    value={releaseDate}
                    type="date"
                    min="1993-01-01"
                    max={`${currentYear}-12-31`}
                    className="form-control flex-grow-1"
                  />
                </div>
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Release / Supplier Country</label>
                <Select
                  options={mapSupplierOptions(supplierCountries)}
                  onChange={setSupplierCountrySelected}
                  formatOptionLabel={OptionFormatter}
                  value={supplierCountrySelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isSupplierCountriesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Stock Reported</label>
                <Select
                  options={yesOrNoOptions}
                  onChange={setStockReportedSelected}
                  formatOptionLabel={OptionFormatter}
                  value={stockReportedSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>
        </ExpandableSection>

        <div className="d-flex gap-3 form-group align-items-end justify-content-end mt-2">
          <input
            disabled={buttonDisabled}
            type="button"
            onClick={clearAllFilters}
            className="btn btn-outline-primary"
            value="Clear Filters"
          />

          <input
            disabled={buttonDisabled}
            type="submit"
            className="btn btn-primary"
            value="Search"
          />
        </div>
      </form>
    </>
  );
};

export default PMSFilters;
