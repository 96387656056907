import { getGlobalProductCode } from "../../../../helpers/global-product.helper";
import { getTccCode } from "../../../../helpers/tcc.helper";
import { IGlobalProduct, ITcc } from "../../../../types/data.interface";
import { ExpandableSection } from "../expandable-section";

type HierarchyInfoSectionProps = {
  tcc: ITcc | null;
  gp: IGlobalProduct | null;
};

const HierarchyInfoSection = ({ tcc, gp }: HierarchyInfoSectionProps) => {
  return (
    <>
      {gp && (
        <ExpandableSection sectionId={"hierarchy"} title="SKU Hierarchy">
          <div className="row form-row gap-2">
            <div className="col-md form-group d-flex align-items-center">
              <label className="form-label mb-0 mr-2 text-nowrap">
                Category
              </label>
              <textarea
                value={`${gp.sub_category.parent?.code} - ${gp.sub_category.parent?.name}`}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md form-group d-flex align-items-center">
              <label className="form-label mb-0 mr-2 text-nowrap">
                Sub-Category
              </label>
              <textarea
                value={`${gp.sub_category.code} - ${gp.sub_category.name}`}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md form-group d-flex align-items-center">
              <label className="form-label mb-0 mr-2 text-nowrap">
                Product Line
              </label>
              <textarea
                value={`${gp.product_line.code} - ${gp.product_line.name}`}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="row form-row gap-2">
            <div className="col-md form-group d-flex align-items-center mb-0">
              <label className="form-label mb-0 mr-2 text-nowrap">
                Global Product
              </label>
              <textarea
                value={`${getGlobalProductCode(gp)} - ${gp.name}`}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md form-group d-flex align-items-center mb-0">
              <label className="form-label mb-0 mr-2 text-nowrap">TCC</label>
              <textarea
                value={tcc ? `${getTccCode(tcc)} - ${tcc.name}` : "-"}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>
        </ExpandableSection>
      )}
    </>
  );
};

export default HierarchyInfoSection;
