export class DateTimeFormat {
  /**
   * Formats the date in "YYYY-MM-DD" format.
   * @param {Date} date
   * @returns {string}
   */
  static formatDate(date: Date) {
    return new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  }
}
