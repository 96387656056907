import "./BreadcrumbItem.css";

type BreadcrumbItemType = {
  text: string;
  onClick: () => void;
  showActive?: boolean;
  isDisabled?: boolean;
  isCurrent?: boolean;
};

const BreadcrumbItem = ({
  text,
  onClick,
  showActive,
  isDisabled,
  isCurrent,
}: BreadcrumbItemType) => (
  <button
    type="button"
    className={`flex-fill btn btn-link border-top-0 border-start-0 border-end-0 rounded-0 fw-bold breadcrumb-link text-decoration-none ${
      showActive ? "active" : ""
    } ${isCurrent ? "fw-bolder" : ""}`}
    onClick={onClick}
    aria-disabled={isDisabled}
    disabled={isDisabled}
  >
    {text}
  </button>
);

export default BreadcrumbItem;
