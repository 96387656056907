import AsyncSelect from "react-select/async";
import OptionFormatter from "../../../../components/OptionFormatter";
import { ICompositionComponent } from "../../../../types/data.interface";
import { formatIntoOption } from "../../features/sku-create/helpers";
import { useMoldContext } from "../../../../context/MoldContext";
import { getMolds } from "../../../../helpers/mold.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { fetchMold } from "../../../../hooks/useMold";
import useAxios from "../../../../utils/useAxios";
import { useMaterialContext } from "../../../../context/MaterialContext";
import { getMaterials } from "../../../../helpers/material.helper";
import { fetchMaterial } from "../../../../hooks/useMaterial";
import { getColors } from "../../../../helpers/color.helper";
import { useColorContext } from "../../../../context/ColorContext";
import { fetchColor } from "../../../../hooks/useColor";
import Select from "react-select";
import { getDecorTechs } from "../../../../helpers/decor-tech.helper";
import { useDecorationTechContext } from "../../../../context/DecorationTechContext";
import { fetchNonMold } from "../../../../hooks/useNonMold";
import { useNonMoldContext } from "../../../../context/NonMoldContext";
import useDesign, { fetchDesign } from "../../../../hooks/useDesign";
import { getDesigns } from "../../../../helpers/design.helper";
import { useContext } from "react";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";

const ComponentAdditionForm = ({
  component,
  setComponent,
}: {
  component: ICompositionComponent | null;
  setComponent: any;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const { data: molds, isLoading: isMoldsLoading } = useMoldContext();
  const { data: nonMolds, isLoading: isNonMoldsLoading } = useNonMoldContext();
  const { data: materials, isLoading: isMaterialsLoading } =
    useMaterialContext();
  const { data: colors, isLoading: isColorsLoading } = useColorContext();
  const { data: decorationTechs, isLoading: isDecoTechsLoading } =
    useDecorationTechContext();
  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: String(component?.decoration_technique_id ?? ""),
  });

  const handleComponentTypeChange = (e: any) => {
    setComponent({
      ...component,
      type: e.target.checked ? "NonMolded" : "Molded",
      mold_id: null,
      mold_description: null,
    });
  };

  const handleMoldChange = (e: any) => {
    if (e) {
      setComponent({
        ...component,
        mold_id: e.value.id,
        mold_description: e.label,
      });
    } else {
      setComponent({ ...component, mold_id: null, mold_description: null });
    }
  };

  const loadMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchMold({
      search,
      axios,
    });

    callback(getMolds(response));
  };

  const loadNonMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchNonMold({
      search,
      axios,
    });

    callback(getMolds(response));
  };

  const handleMaterialChange = (e: any) => {
    if (e) {
      setComponent({
        ...component,
        material_id: e.value.id,
        material_description: e.label,
      });
    } else {
      setComponent({
        ...component,
        material_id: null,
        material_description: null,
      });
    }
  };

  const loadMaterialOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchMaterial({
      search,
      axios,
    });

    callback(getMaterials(response));
  };

  const handleColorChange = (e: any) => {
    if (e) {
      setComponent({
        ...component,
        color_id: e.value.id,
        color_description: e.label,
      });
    } else {
      setComponent({ ...component, color_id: null, color_description: null });
    }
  };

  const loadColorOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchColor({
      search,
      axios,
    });

    callback(getColors(response));
  };

  const handleDecorationTechniqueChange = (e: any) => {
    if (e) {
      setComponent({
        ...component,
        decoration_technique_id: e.value.id,
        decoration_technique_description: e.label,
      });
      //loadDesignOptions("");
    } else {
      setComponent({
        ...component,
        decoration_technique_id: null,
        decoration_technique_description: null,
        artwork_id: null,
        artwork_description: null,
      });
    }
  };

  const handleArtworkChange = (e: any) => {
    if (e) {
      setComponent({
        ...component,
        artwork_id: e.value.id,
        artwork_description: e.label,
      });
    } else {
      setComponent({
        ...component,
        artwork_id: null,
        artwork_description: null,
      });
    }
  };

  const loadDesignOptions = async (search: string, callback: any) => {
    if (
      !component?.decoration_technique_id &&
      (Predicates.isNullOrUndefined(search) || search.length < 3)
    )
      return [];
    const response = await fetchDesign({
      decorationTechniqueId: String(component?.decoration_technique_id),
      search,
      axios,
    });

    callback(getDesigns(response));
  };

  const handlePiecesChange = (e: any) => {
    const cleanedNum = e.target.value.replace(/[^0-9]/gi, "");
    setComponent({ ...component, nr_pieces: cleanedNum });
  };

  const handleTpsChange = (e: any) => {
    setComponent({ ...component, is_tps: e.target.checked });
  };

  return (
    <>
      <div className="row form-row align-items-end">
        <div className="col-md-4 form-group d-flex align-items-center">
          <label className="form-label mb-0">Non-Molded</label>
          <div className="fixed-checkbox-height pl-2">
            <input
              onChange={handleComponentTypeChange}
              name="non-molded"
              checked={component?.type === "NonMolded"}
              type="checkbox"
              className="checkbox"
            />
          </div>
        </div>
      </div>

      <div className="row form-row align-items-end">
        <div className="col-md-4 form-group">
          <label className="form-label">
            {`${component?.type === "NonMolded" ? "Sequence" : "Mold"}`}{" "}
            <span className="red-text fw-bold">*</span>
          </label>
          <AsyncSelect
            cacheOptions
            loadOptions={(input, callback) => {
              if (component?.type === "NonMolded") {
                searchDebounce(
                  loadNonMoldOptions,
                  input,
                  getMolds(nonMolds),
                  callback,
                );
              } else {
                searchDebounce(
                  loadMoldOptions,
                  input,
                  getMolds(molds),
                  callback,
                );
              }
            }}
            defaultOptions={getMolds(
              component?.type === "NonMolded" ? nonMolds : molds,
            )}
            onChange={handleMoldChange}
            value={formatIntoOption(
              component?.mold_id,
              component?.mold_description ?? "",
            )}
            placeholder="(min 3 characters)"
            formatOptionLabel={OptionFormatter}
            isLoading={isMoldsLoading || isNonMoldsLoading}
            classNamePrefix="react-select"
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">
            Material <span className="red-text fw-bold">*</span>
          </label>
          <AsyncSelect
            cacheOptions
            loadOptions={(input, callback) => {
              searchDebounce(
                loadMaterialOptions,
                input,
                getMaterials(materials),
                callback,
              );
            }}
            defaultOptions={getMaterials(materials)}
            onChange={handleMaterialChange}
            value={formatIntoOption(
              component?.material_id,
              component?.material_description ?? "",
            )}
            placeholder="(min 3 characters)"
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            isClearable
            isLoading={isMaterialsLoading}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">
            Color <span className="red-text fw-bold">*</span>
          </label>
          <AsyncSelect
            cacheOptions
            loadOptions={(input, callback) => {
              searchDebounce(
                loadColorOptions,
                input,
                getColors(colors),
                callback,
              );
            }}
            defaultOptions={getColors(colors)}
            onChange={handleColorChange}
            value={formatIntoOption(
              component?.color_id,
              component?.color_description ?? "",
            )}
            placeholder="(min 3 characters)"
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            isClearable
            isLoading={isColorsLoading}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>

      <div className="row form-row align-items-end">
        <div className="col-md-4 form-group">
          <label className="form-label">Decor. Tech</label>
          <Select
            options={getDecorTechs(decorationTechs)}
            onChange={handleDecorationTechniqueChange}
            value={formatIntoOption(
              component?.decoration_technique_id,
              component?.decoration_technique_description ?? "",
            )}
            placeholder=""
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            isLoading={isDecoTechsLoading}
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">Design</label>
          <AsyncSelect
            isDisabled={!component?.decoration_technique_id}
            loadOptions={(input, callback) => {
              searchDebounce(
                loadDesignOptions,
                input,
                getDesigns(designs),
                callback,
              );
            }}
            defaultOptions={getDesigns(designs)}
            onChange={handleArtworkChange}
            value={formatIntoOption(
              component?.artwork_id,
              component?.artwork_description ?? "",
            )}
            placeholder="(min 3 characters)"
            formatOptionLabel={OptionFormatter}
            isLoading={isDesignsLoading}
            classNamePrefix="react-select"
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">
            Pcs/Sets <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={component?.nr_pieces}
            onChange={handlePiecesChange}
            className="form-control"
            type="text"
          />
        </div>

        {component?.type !== "NonMolded" && (
          <div className="col-md-1 form-group d-flex flex-column align-items-center">
            <label className="form-label">TPS</label>
            <div className="fixed-checkbox-height">
              <input
                onChange={handleTpsChange}
                name="discontinued"
                checked={component?.is_tps}
                type="checkbox"
                className="checkbox"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ComponentAdditionForm;
