const spinnerWheelSrc =
  process.env.PUBLIC_URL + "/assets/img/spinner-wheel.gif";

const Spinner = ({
  hideLabel,
  smallSpinner,
  mediumSpinner,
}: {
  hideLabel?: boolean;
  smallSpinner?: boolean;
  mediumSpinner?: boolean;
}) => {
  return (
    <div
      className={`spinner-container ${
        smallSpinner ? "small-spinner" : mediumSpinner ? "medium-spinner" : ""
      }`}
    >
      <div>
        <img src={spinnerWheelSrc} alt="loading..." />
        {!hideLabel && <p className="text-center">Loading Data...</p>}
      </div>
    </div>
  );
};

export default Spinner;
