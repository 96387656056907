import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ButtonIconType = {
  description: string;
  buttonIconClass: string;
  onClick: () => void;
  icon?: IconDefinition;
  descriptionClass?: string;
};

export default function ButtonIcon({
  description,
  buttonIconClass,
  onClick,
  icon,
  descriptionClass,
}: ButtonIconType) {
  return (
    <span className={buttonIconClass} onClick={onClick}>
      <button
        type="button"
        className={`modal-start-button btn btn-link p-0 ${
          icon ? "text-decoration-none" : ""
        }`}
        style={{ color: icon ? "var(--bs-card-color)" : "", border: "unset" }}
      >
        {icon ? <FontAwesomeIcon icon={icon} className="me-2" /> : <></>}
        <span className={descriptionClass}>{description}</span>
      </button>
    </span>
  );
}
