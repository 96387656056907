import { useEffect } from "react";

export const useBeforeUnload = ({
  condition,
  message,
}: {
  condition: boolean;
  message: string;
}) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    if (condition) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [condition, message]);
};
