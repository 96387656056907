import { memo } from "react";
import ButtonModal from "../../routes/skus/components/modals/ButtonModal";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import MoldCompositionModal from "../modals/MoldCompositionModal";

const MoldCompositionCellRenderer = memo(
  (model: {
    id: string;
    description: string;
    moldQty: number;
    nonMoldQty: number;
  }) => {
    const componentsQty: string = `${model.moldQty > 0 ? model.moldQty : "0"} + ${
      model.nonMoldQty > 0 ? model.nonMoldQty : "0"
    }`;

    return model.moldQty > 0 || model.nonMoldQty > 0 ? (
      <div className="d-flex">
        <div className="pe-2 flex-fill">{componentsQty}</div>
        <div>
          <ButtonModal
            buttonIconClass="icon-button"
            description="Details"
            title={`Composition Details - SKU nº 11 ${model.id} - ${model.description}`}
            icon={faCirclePlus}
            descriptionClass="small"
          >
            <MoldCompositionModal model={model} />
          </ButtonModal>
        </div>
      </div>
    ) : (
      <></>
    );
  },
);

export default MoldCompositionCellRenderer;
