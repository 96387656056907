import { IMold } from "../types/data.interface";

export const formatMoldIntoOption = (item: IMold) => ({
    value: item,
    label: `${item.id} - ${item.name}`,
});

export const getMolds = (
    molds: IMold[],
) =>
    molds.map(formatMoldIntoOption);