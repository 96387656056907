import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import ProductContext, {
  ProductContextInterface,
} from "../../context/ProductContext";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";

function SideNav({ showMenu, toggle }: { showMenu: boolean; toggle: any }) {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const { productDetails, unsavedChanges, setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);
  const location = useLocation();

  const navigationPaths = {
    moldappLogin: `${process.env.REACT_APP_MOLD_APP_URL}/ords/moldapp/apppack.welcome`,
    moldAppMainMenu: `${
      process.env.REACT_APP_MOLD_APP_URL
    }/ords/moldapp/apppack.mainmenu?sessid=${sessionStorage.getItem("sessid")}`,
    similarItems: `product-information/similar-items?p_fg_id=${productDetails?.productId}`,
    buyersRelatedData: `product-information/buyers-data?p_prodid=${productDetails?.productId}&p_proddesc=${productDetails?.description}&p_is_iframe=Y`,
    inventory: `product-information/inventory?p_prodid=${productDetails?.productId}&p_is_iframe=Y`,
    productMasterSearch: `/product-master?sessid=${sessionStorage.getItem("sessid")}`,
    globalProductHierarchy: `${
      process.env.REACT_APP_GPH_APP_URL
    }/?sessid=${sessionStorage.getItem("sessid")}`,
    productMassUpdate: `${
      process.env.REACT_APP_PMU_APP_URL
    }/?sessid=${sessionStorage.getItem("sessid")}`,
    packagingProcedures: `${process.env.REACT_APP_PACK_PROCEDURES_APP_URL}/packaging_procedures`,
    bulksSearchTool: `${
      process.env.REACT_APP_MOLD_APP_URL
    }/ords/moldapp/product_master.ask_for_bulks?sessid=${sessionStorage.getItem(
      "sessid",
    )}`,
    createNewPis: `skus/create?should_create=true&sessid=${sessionStorage.getItem("sessid")}`,
    pisRequestsList: `skus/requests/?sessid=${sessionStorage.getItem("sessid")}`,
  };

  const navigate = useNavigate();

  const isNoAccessPage = useMatch("/no-access");

  const isProdInfoPage = useMatch("/product-information");
  const isProdInfoRelatedPages = useMatch("/product-information/*");

  const prodInfoPageActiveLink = isProdInfoRelatedPages
    ? "nav-item active"
    : "nav-item";

  const prodMasterSearchActiveLink = useMatch("/product-master")
    ? "nav-item active"
    : "nav-item";

  useEffect(() => {
    if (isProdInfoPage && !showMenu) {
      toggle();
    }
  }, [isProdInfoPage]);

  // To be used when we want to advertise the user for unsaved changes before navigating away
  const handleUnsavedChangesNavigation = (
    event: any,
    pathToNavigate: string,
    shouldCompareCurrentPath?: boolean,
  ) => {
    event.preventDefault();
    const isNavigationToSamePath: boolean =
      location.pathname === pathToNavigate.split("?")[0];

    if (unsavedChanges?.existsUnsavedChanges) {
      const confirmationToLeave = window.confirm(unsavedChanges?.message);
      if (confirmationToLeave) {
        setUnsavedChanges({ existsUnsavedChanges: false });
        navigate(pathToNavigate);
        if (shouldCompareCurrentPath && isNavigationToSamePath) navigate(0);
      }
    } else {
      navigate(pathToNavigate);
      if (shouldCompareCurrentPath && isNavigationToSamePath) navigate(0);
    }
  };

  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        showMenu ? "" : "toggled"
      }`}
      id="accordionSidebar"
    >
      {/* Home Page Mold App */}
      {isNoAccessPage ? (
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href={navigationPaths.moldappLogin}
        >
          <div className="sidebar-brand-icon">
            <i className="fas fa-home"></i>
          </div>
        </a>
      ) : (
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href={navigationPaths.moldAppMainMenu}
          target="_blank"
        >
          <div className="sidebar-brand-icon">
            <i className="fas fa-home"></i>
          </div>
        </a>
      )}

      {/* Divider */}
      <hr className="sidebar-divider" />

      {!isNoAccessPage && (
        <>
          {hasPermission("product_master.view_pisrequest") ? (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapsePIS"
                aria-expanded="false"
                aria-controls="collapsePIS"
              >
                <i className="fas fa-fw fa-sitemap"></i>
                <span>PIS</span>
              </a>
              <div
                id="collapsePIS"
                className="collapse"
                aria-labelledby="headingPIS"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Product Information Sheet</h6>
                  {hasPermission("product_master.add_pisrequest") ? (
                    <NavLink
                      className="collapse-item"
                      to={navigationPaths.createNewPis}
                      onClick={(event) =>
                        handleUnsavedChangesNavigation(
                          event,
                          navigationPaths.createNewPis,
                          true,
                        )
                      }
                      data-toggle="collapse"
                      data-target="#collapsePIS"
                      aria-expanded="true"
                      aria-controls="collapsePIS"
                    >
                      Create new SKU
                    </NavLink>
                  ) : (
                    <></>
                  )}
                  <NavLink
                    className="collapse-item"
                    to={navigationPaths.pisRequestsList}
                    onClick={(event) =>
                      handleUnsavedChangesNavigation(
                        event,
                        navigationPaths.pisRequestsList,
                      )
                    }
                    data-toggle="collapse"
                    data-target="#collapsePIS"
                    aria-expanded="true"
                    aria-controls="collapsePIS"
                  >
                    My approval requests
                  </NavLink>
                </div>
              </div>
            </li>
          ) : (
            <></>
          )}

          {/* {hasPermission("product_master.view_pisrequest") ? ( */}
          {/* <li className="nav-item">
            <a
              href="#"
              className="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTCC"
              aria-expanded="false"
              aria-controls="collapseTCC"
            >
              <i className="fas fa-fw fa-poll-h"></i>
              <span>TCC</span>
            </a>
            <div
              id="collapseTCC"
              className="collapse"
              aria-labelledby="headingTCC"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Tup. Consumer Code</h6>
                <NavLink
                  className="collapse-item"
                  to="/tccs/search"
                  data-toggle="collapse"
                  data-target="#collapseTCC"
                  aria-expanded="true"
                  aria-controls="collapseTCC"
                >
                  Search TCC
                </NavLink>
                <NavLink
                  className="collapse-item"
                  to="/tccs/requests"
                  data-toggle="collapse"
                  data-target="#collapseTCC"
                  aria-expanded="true"
                  aria-controls="collapseTCC"
                >
                  Search TCC Request
                </NavLink>
                <NavLink
                  className="collapse-item"
                  to="/tccs/create"
                  data-toggle="collapse"
                  data-target="#collapseTCC"
                  aria-expanded="true"
                  aria-controls="collapseTCC"
                >
                  Create TCC Request
                </NavLink>
                <NavLink
                  className="collapse-item"
                  to="/tccs/approvals"
                  data-toggle="collapse"
                  data-target="#collapseTCC"
                  aria-expanded="true"
                  aria-controls="collapseTCC"
                >
                  My TCC Approvals
                </NavLink>
                <NavLink
                  className="collapse-item"
                  to="/tccs/admin"
                  data-toggle="collapse"
                  data-target="#collapseTCC"
                  aria-expanded="true"
                  aria-controls="collapseTCC"
                >
                  TCC User Roles
                </NavLink>
              </div>
            </div>
          </li> */}
          {/* ) : (
            <></>
          )} */}

          {/* Page Specific Navigation */}
          {isProdInfoRelatedPages && (
            <li className={prodInfoPageActiveLink}>
              <a
                className={`nav-link ${showMenu ? "" : "collapsed"}`}
                href="#"
                data-toggle="collapse"
                data-target="#collapsePIP"
                aria-expanded="true"
                aria-controls="collapsePIP"
              >
                <i className="fas fa-fw fa-book"></i>
                <span>Product Information</span>
              </a>
              <div
                id="collapsePIP"
                className={`collapse in ${showMenu ? "show" : ""}`}
                aria-labelledby="headingPIP"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <NavLink
                    className="collapse-item"
                    to={navigationPaths.similarItems}
                    onClick={(event) =>
                      handleUnsavedChangesNavigation(
                        event,
                        navigationPaths.similarItems,
                      )
                    }
                    data-toggle="collapse"
                    data-target="#collapsePIP"
                    aria-expanded="true"
                    aria-controls="collapsePIP"
                  >
                    Similar Items
                  </NavLink>

                  <NavLink
                    className="collapse-item"
                    to={navigationPaths.buyersRelatedData}
                    onClick={(event) =>
                      handleUnsavedChangesNavigation(
                        event,
                        navigationPaths.buyersRelatedData,
                      )
                    }
                    data-toggle="collapse"
                    data-target="#collapsePIP"
                    aria-expanded="true"
                    aria-controls="collapsePIP"
                  >
                    Buyer's Related Data
                  </NavLink>

                  <NavLink
                    className="collapse-item"
                    to={navigationPaths.inventory}
                    onClick={(event) =>
                      handleUnsavedChangesNavigation(
                        event,
                        navigationPaths.inventory,
                      )
                    }
                    data-toggle="collapse"
                    data-target="#collapsePIP"
                    aria-expanded="true"
                    aria-controls="collapsePIP"
                  >
                    Inventory
                  </NavLink>
                </div>
              </div>
            </li>
          )}

          {/* Global Navigation */}
          {/* Nav Item - Product Master Search */}
          <li className={prodMasterSearchActiveLink}>
            <NavLink
              className="nav-link"
              to={navigationPaths.productMasterSearch}
              onClick={(event) =>
                handleUnsavedChangesNavigation(
                  event,
                  navigationPaths.productMasterSearch,
                )
              }
            >
              <i className="fas fa-fw fa-search"></i>
              <span>Product Master Search</span>
            </NavLink>
          </li>

          {/* Nav Item - Global Product Hierarchy */}
          {hasPermission("api.view_datacatalog") && (
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href={navigationPaths.globalProductHierarchy}
              >
                <i className="fas fa-fw fa-database"></i>
                <span>Global Product Hierarchy</span>
              </a>
            </li>
          )}

          {/* Nav Item - Product Mass Update */}
          {hasPermission("api.view_pmuqueue") && (
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href={navigationPaths.productMassUpdate}
              >
                <FontAwesomeIcon icon={faFileArrowUp} className="fa fa-fw" />
                <span>Product Mass Update</span>
              </a>
            </li>
          )}

          {/* Nav Item - Packaging Procedures */}
          <li className="nav-item">
            <a
              className="nav-link"
              target="_blank"
              href={navigationPaths.packagingProcedures}
            >
              <i className="fas fa-fw fa-boxes"></i>
              <span>Packaging Procedures</span>
            </a>
          </li>

          {/* Nav Item - Bulks Search Tool */}
          <li className="nav-item">
            <a
              className="nav-link"
              target="_blank"
              href={navigationPaths.bulksSearchTool}
            >
              <i className="fas fa-fw fa-search"></i>
              <span>Bulks Search Tool</span>
            </a>
          </li>
          {/* Divider */}
          <hr className="sidebar-divider d-none d-md-block" />

          {/* Sidebar Toggler */}
          <div className="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              onClick={toggle}
              id="sidebarToggle"
            ></button>
          </div>
        </>
      )}
    </ul>
  );
}

export default SideNav;
