import { PipHeader } from "../PipHeader";
import Spinner from "../../Spinner";
import { PipComponentsListing } from "../PipComponentsListing";
import { PipPackingInformation } from "../PipPackingInformation";
import { PipProductInformation } from "../PipProductInformation";
import PipLocalDescription from "../PipLocalDescription";
import PipBarcode from "../PipBarcode";
import { PipRelatedItems } from "../PipRelatedItems";
import { ExpandableSection } from "../../../routes/skus/components/expandable-section";
import {
  GlobalProductTemplate,
  TCCTemplate,
} from "../../../routes/skus/components/attributes-modal";

export const PipListLayout = ({
  prodInfoData,
  compListData,
  packingInfoData,
  relatedItemsData,
  isPipLoading,
  areOtherTabsLoading,
  isGITabsLoading,
  productNumber,
  globalInfo,
  submitChanges,
  infoSavePending,
  setInfoSavePending,
  barcodes,
  barcodeTypes,
  barcodeSavePending,
  setBarcodeSavePending,
}: {
  prodInfoData: any;
  compListData: any;
  packingInfoData: any;
  relatedItemsData: any;
  isPipLoading: boolean;
  areOtherTabsLoading: boolean;
  isGITabsLoading: boolean;
  productNumber: string;
  globalInfo: any;
  submitChanges: any;
  infoSavePending: any;
  setInfoSavePending: any;
  barcodes: any;
  barcodeTypes: any;
  barcodeSavePending: any;
  setBarcodeSavePending: any;
}) => {
  return (
    <div className="product-information-page-container mt-1">
      <PipHeader data={prodInfoData} />

      <div className="d-flex flex-column gap-2">
        <ExpandableSection
          title={"Components Listing"}
          sectionId={"components"}
          expandedByDefault={true}
        >
          {isPipLoading ? (
            <Spinner />
          ) : (
            <PipComponentsListing prodId={productNumber} data={compListData} />
          )}
        </ExpandableSection>

        <ExpandableSection
          title={"Packing Information"}
          sectionId={"packing"}
          expandedByDefault={true}
        >
          {areOtherTabsLoading ? (
            <Spinner />
          ) : (
            <PipPackingInformation data={packingInfoData} />
          )}
        </ExpandableSection>

        <ExpandableSection
          title={"Product Information"}
          sectionId={"product-info"}
          expandedByDefault={true}
        >
          <ExpandableSection
            className="mt-2"
            title={"Global Product Attributes"}
            sectionId={"global-attributes"}
            expandedByDefault={true}
          >
            <GlobalProductTemplate
              model={
                prodInfoData?.tcc?.global_product ??
                prodInfoData?.global_product
              }
            />
          </ExpandableSection>
          <ExpandableSection
            className="mt-2 mb-2"
            title={"TCC Attributes"}
            sectionId={"tcc-attributes"}
            expandedByDefault={true}
          >
            <TCCTemplate model={prodInfoData?.global_product?.tcc} />
          </ExpandableSection>
          <ExpandableSection
            className="mt-2"
            title={"SKU Attributes"}
            sectionId={"sku-attributes"}
            expandedByDefault={true}
          >
            <PipProductInformation data={prodInfoData} />
          </ExpandableSection>
        </ExpandableSection>

        <ExpandableSection
          title={"Local Description"}
          sectionId={"local-description"}
          expandedByDefault={true}
        >
          {isGITabsLoading ? (
            <Spinner />
          ) : (
            <PipLocalDescription
              data={globalInfo}
              submitChanges={submitChanges}
              savePending={infoSavePending}
              setSavePending={setInfoSavePending}
            />
          )}
        </ExpandableSection>

        <ExpandableSection
          title={"Barcodes"}
          sectionId={"barcodes"}
          expandedByDefault={true}
        >
          <PipBarcode
            data={barcodes}
            barcodeTypes={barcodeTypes}
            productNumber={productNumber}
            submitChanges={submitChanges}
            savePending={barcodeSavePending}
            setSavePending={setBarcodeSavePending}
          />
        </ExpandableSection>

        <ExpandableSection
          title={"Related Items"}
          sectionId={"related-items"}
          expandedByDefault={true}
        >
          {(prodInfoData?.tcc?.name || prodInfoData?.global_product?.name) &&
            (areOtherTabsLoading ? (
              <Spinner />
            ) : (
              <PipRelatedItems
                data={relatedItemsData ?? []}
                globalProductName={prodInfoData?.global_product?.name}
                tccName={prodInfoData?.tcc?.name}
              />
            ))}
        </ExpandableSection>
      </div>
    </div>
  );
};
