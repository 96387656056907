import { IMarketingMaterial } from "../types/data.interface";

export const formatMarketingMaterialIntoOption = (
  item: IMarketingMaterial
) => ({
  value: item,
  label: `${item.name}`,
});

export const getMarketingMaterials = (
  marketingMaterials: IMarketingMaterial[]
) => marketingMaterials.map(formatMarketingMaterialIntoOption);
