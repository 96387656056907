import { FormEvent, useState } from "react";

export default function useFormInput(initialValue: string = "") {
  const [value, setValue] = useState(initialValue);

  function handleChange(e: FormEvent<HTMLInputElement>) {
    setValue(e.currentTarget.value);
  }

  function resetValue() {
    setValue("");
  }

  const inputProps = {
    value,
    onChange: handleChange,
    resetValue,
  };

  return inputProps;
}
