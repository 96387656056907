import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncSelect from "react-select/async";
import OptionFormatter from "../../../../components/OptionFormatter";
import { useContext, useEffect, useState } from "react";
import ComponentAdditionForm from "./ComponentAdditionForm";
import useAxios from "../../../../utils/useAxios";
import SkuCompositionTree from "../sku-composition-tree/SkuCompositionTree";
import {
  ComponentType,
  ICompositionComponent,
  ISkuComposition,
  Sku,
} from "../../../../types/data.interface";
import {
  fillCompositionNodeIds,
  findNodeIndexByNodeId,
  getNodeByNodeId,
} from "../../features/sku-create/helpers";
import useSku, { fecthSku } from "../../../../hooks/useSku";
import { getSku } from "../../../../helpers/sku.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import Spinner from "../../../../components/Spinner";
import { toast } from "react-toastify";
import { fetchSkuComposition } from "../../../../hooks/useSkuComposition";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";

export type CompositionType = "sku" | "component";

export const COMPOSITION_OPTIONS: { [k: string]: CompositionType } = {
  SKU: "sku",
  COMPONENT: "component",
};

export interface EditCompositionParams {
  compositionEditType: CompositionType;
  compositionEditObject: any;
}

const MAX_DEPTH_ALLOWED = 3;

const AddSkuCompositionModal = ({
  handleCloseModal,
  handleAddSku,
  handleAddComponents,
  editModalParams,
}: {
  handleCloseModal: any;
  handleAddSku: (sku: ISkuComposition | null, nodeId: number) => void;
  handleAddComponents: (
    moldedComponents: ICompositionComponent[],
    nonMoldedComponents: ICompositionComponent[],
    nodeId: number,
  ) => void;
  editModalParams?: EditCompositionParams | null;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const [optionSelected, setOptionSelected] = useState<CompositionType>();
  const { data: skus, isLoading: isSkusLoading } = useSku({});

  const [skuSelected, setSkuSelected] = useState<{
    label: string;
    value: Sku;
  } | null>(null);
  const [isLoadingComposition, setIsLoadingComposition] =
    useState<boolean>(false);

  const [component, setComponent] = useState<ICompositionComponent | null>(
    null,
  );
  const [skuComposition, setSkuComposition] = useState<ISkuComposition | null>(
    null,
  );
  const [shouldIncludeSku, setShouldIncludeSku] = useState<boolean>(true);
  const [compositionToSave, setCompositionToSave] =
    useState<ISkuComposition | null>(null);

  const title = editModalParams
    ? `Edit ${
        editModalParams.compositionEditType === "sku"
          ? `SKU 11 ${editModalParams.compositionEditObject.id} - ${editModalParams.compositionEditObject.name}`
          : `Component ${editModalParams.compositionEditObject.mold_description}`
      }`
    : "";
  const cancelButtonLabel = editModalParams ? "Cancel" : "Close";
  const saveButtonLabel = editModalParams ? "Save" : "Add to SKU";

  const addComponentButtonDisabled =
    !component?.mold_id ||
    !component?.material_id ||
    !component?.color_id ||
    (component?.nr_pieces ?? 0) <= 0
      ? true
      : false;

  const addSkuButtonDisabled = editModalParams
    ? shouldIncludeSku
    : !compositionToSave;

  const loadSkuOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fecthSku({
      search,
      axios,
    });
    callback(getSku(response));
  };

  const addFakeLayerAndSetComposition = (composition: ISkuComposition) => {
    setSkuComposition({
      id: "",
      name: "",
      skus: [composition],
      molded_components: [],
      non_molded_components: [],
    });
  };

  const handleSkuSelected = async (e: any) => {
    if (e) {
      setIsLoadingComposition(true);
      setSkuSelected(e);
      try {
        const formattedSkuId: string = e.value.id.replace(/^0+/, "");
        const retrievedComposition = await fetchSkuComposition({
          id: formattedSkuId.length === 0 ? "0" : formattedSkuId,
          axios,
        });
        const compositionWithNodeIds: ISkuComposition =
          fillCompositionNodeIds(retrievedComposition);
        addFakeLayerAndSetComposition({ ...compositionWithNodeIds });
        setCompositionToSave({ ...compositionWithNodeIds });

        setIsLoadingComposition(false);
      } catch (err) {
        console.log(err);
        setIsLoadingComposition(false);
      }
    } else {
      setSkuSelected(null);
      setSkuComposition(null);
      setCompositionToSave(null);
    }
  };

  const getCompositionDepth = (
    composition: ISkuComposition,
    currentDepth: number,
  ) => {
    let depthArray: number[] = [];
    if (composition.skus.length === 0) {
      return currentDepth;
    } else {
      composition.skus.forEach((sku: ISkuComposition) => {
        depthArray.push(getCompositionDepth(sku, currentDepth + 1));
      });

      return Math.max(...depthArray);
    }
  };

  const handleAdditionToComposition = () => {
    const nodeId: number = editModalParams
      ? editModalParams.compositionEditObject.node_id
      : -1;

    if (optionSelected === COMPOSITION_OPTIONS.SKU && skuComposition) {
      if (compositionToSave) {
        if (shouldIncludeSku) {
          const compositionDepth: number = getCompositionDepth(
            compositionToSave,
            1,
          );

          if (compositionDepth + 1 > MAX_DEPTH_ALLOWED) {
            toast.error(
              "This SKU exceeds the maximum level of hierarchy allowed",
            );
          } else {
            handleAddSku(compositionToSave, nodeId);
          }
        } else {
          handleAddSku(null, nodeId);
          handleAddComponents(
            compositionToSave.molded_components,
            compositionToSave.non_molded_components,
            -1,
          );
        }
      }
    } else if (optionSelected === COMPOSITION_OPTIONS.COMPONENT && component) {
      const componentQuantity: number =
        component.quantity > 0 ? component.quantity : 1;
      if (component.type === "NonMolded") {
        handleAddComponents(
          [],
          [{ ...component, quantity: componentQuantity, type: "NonMolded" }],
          nodeId,
        );
      } else {
        handleAddComponents(
          [{ ...component, quantity: componentQuantity, type: "Molded" }],
          [],
          nodeId,
        );
      }
    }
  };

  const manageSkuInclusion = (isToInclude: boolean, nodeId: number) => {
    setShouldIncludeSku(isToInclude);
  };

  const manageComponentInclusion = (isToInclude: boolean, nodeId: number) => {
    const compositionWithouFakeLayer: ISkuComposition | null =
      skuComposition?.skus.at(0) ?? null;
    if (compositionWithouFakeLayer && compositionToSave) {
      const componentToManage: ICompositionComponent | undefined =
        getNodeByNodeId(
          nodeId,
          compositionWithouFakeLayer,
        ) as ICompositionComponent;
      const componentType: ComponentType = componentToManage.type;
      const componentsList: ICompositionComponent[] =
        componentType === "Molded"
          ? [...compositionToSave.molded_components]
          : [...compositionToSave.non_molded_components];

      if (isToInclude) {
        componentsList.push(componentToManage);
      } else {
        componentsList.splice(findNodeIndexByNodeId(nodeId, componentsList), 1);
      }

      if (compositionToSave && componentType === "Molded") {
        setCompositionToSave({
          ...compositionToSave,
          molded_components: componentsList,
        });
      } else if (compositionToSave && componentType === "NonMolded") {
        setCompositionToSave({
          ...compositionToSave,
          non_molded_components: componentsList,
        });
      }
    }
  };

  const assertComponentIsIncluded = (
    componentType: ComponentType,
    nodeId: number,
  ) => {
    if (compositionToSave) {
      const componentsList: ICompositionComponent[] =
        componentType === "Molded"
          ? compositionToSave.molded_components
          : compositionToSave.non_molded_components;
      return findNodeIndexByNodeId(nodeId, componentsList) !== -1;
    } else {
      return shouldIncludeSku;
    }
  };

  useEffect(() => {
    setOptionSelected(
      editModalParams
        ? editModalParams.compositionEditType
        : COMPOSITION_OPTIONS.SKU,
    );

    if (
      editModalParams &&
      editModalParams.compositionEditType === COMPOSITION_OPTIONS.SKU
    ) {
      addFakeLayerAndSetComposition({
        ...editModalParams.compositionEditObject,
      });
      setCompositionToSave({ ...editModalParams.compositionEditObject });
    } else if (
      editModalParams &&
      editModalParams.compositionEditType === COMPOSITION_OPTIONS.COMPONENT
    ) {
      setComponent(editModalParams.compositionEditObject);
    }
  }, []);

  useEffect(() => {
    const compositionWithouFakeLayer: ISkuComposition | null =
      skuComposition?.skus.at(0) ?? null;
    if (compositionWithouFakeLayer) {
      setCompositionToSave({ ...compositionWithouFakeLayer });
    } else {
      setCompositionToSave(null);
    }
  }, [shouldIncludeSku]);

  return (
    <div className="p-3 d-flex flex-column" style={{ minHeight: 450 }}>
      <div className="d-flex justify-content-between mb-2">
        <div></div>
        <h3 className="h5 mb-2">{title}</h3>
        <button onClick={handleCloseModal} className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>

      {!editModalParams && (
        <div className="row form-row mb-2">
          <form
            className="d-flex justify-content-between align-items-center col-md mr-3"
            style={{ maxWidth: 290 }}
          >
            <div className="radio">
              <label className="mr-2 mb-0">Search SKU</label>
              <input
                type="radio"
                onChange={() => setOptionSelected(COMPOSITION_OPTIONS.SKU)}
                value={COMPOSITION_OPTIONS.SKU}
                checked={optionSelected === COMPOSITION_OPTIONS.SKU}
              />
            </div>

            <div className="radio">
              <label className="mr-2 mb-0">Add Components</label>
              <input
                type="radio"
                onChange={() =>
                  setOptionSelected(COMPOSITION_OPTIONS.COMPONENT)
                }
                value={COMPOSITION_OPTIONS.COMPONENT}
                checked={optionSelected === COMPOSITION_OPTIONS.COMPONENT}
              />
            </div>
          </form>

          {optionSelected === COMPOSITION_OPTIONS.SKU && (
            <div className="d-flex col-md-8 align-items-center">
              <span className="mr-2 black-text">11 </span>
              <AsyncSelect
                className="composition-search-sku-field flex-fill"
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(loadSkuOptions, input, getSku(skus), callback);
                }}
                defaultOptions={getSku(skus)}
                onChange={handleSkuSelected}
                value={skuSelected}
                placeholder="(min 3 digits)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isSkusLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </div>
      )}

      {optionSelected === COMPOSITION_OPTIONS.COMPONENT && (
        <ComponentAdditionForm
          component={component}
          setComponent={setComponent}
        />
      )}

      {isLoadingComposition ? (
        <Spinner />
      ) : (
        optionSelected === COMPOSITION_OPTIONS.SKU &&
        skuComposition && (
          <>
            <div className="d-flex justify-content-between mb-2">
              <p className="font-italic">
                If the SKU is selected all of its composition is added. If it is
                not selected, the user can select the components to add
              </p>
            </div>

            <SkuCompositionTree
              skuComposition={skuComposition}
              isMainPage={false}
              shouldIncludeSku={shouldIncludeSku}
              manageSkuInclusion={manageSkuInclusion}
              manageComponentInclusion={manageComponentInclusion}
              assertComponentIsIncluded={assertComponentIsIncluded}
            />
          </>
        )
      )}

      <div className="d-flex justify-content-end gap-2 mt-auto">
        <input
          type="button"
          onClick={handleCloseModal}
          className="btn btn-secondary"
          value={cancelButtonLabel}
        />

        <input
          type="submit"
          onClick={handleAdditionToComposition}
          className="btn btn-primary"
          value={saveButtonLabel}
          disabled={
            optionSelected === COMPOSITION_OPTIONS.SKU
              ? addSkuButtonDisabled
              : addComponentButtonDisabled
          }
        />
      </div>
    </div>
  );
};

export default AddSkuCompositionModal;
