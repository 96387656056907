import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "../TablePagination";
import { Predicates } from "../../libraries/predicates/predicates";
import PictureCellRenderer from "../cell-renderers/PictureCellRenderer";
import MoldCompositionCellRenderer from "../cell-renderers/MoldCompositionCellRenderer";
import useFormInput from "../../hooks/useFormInput";
import { FilterChangedEvent, GridApi } from "ag-grid-community";

export const PipRelatedItems = ({
  globalProductName,
  tccName,
  data,
}: {
  globalProductName: string;
  tccName?: string;
  data: any[];
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const {
    value: search,
    onChange: onSearchChange,
    resetValue: resetSearch,
  } = useFormInput();

  const onPageSizeChanged = useCallback(
    (e: any) => {
      gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
      setPageSize(Number(e.target.value));
    },
    [gridRef],
  );

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current?.api?.getDisplayedRowCount());
    }
  }, [gridRef]);

  const columnDefs = useMemo(
    () => [
      {
        colId: "id",
        field: "id",
        headerName: "SKU Nr.",
        maxWidth: 120,
        minWidth: 100,
        cellRenderer: (params: any) => {
          const pathToNavigate = `/product-information/?productnumber=${
            params.data.id.split(" ")[1]
          }`;
          return (
            <Link
              className="text-decoration-underline fw-bold"
              to={pathToNavigate}
            >
              {params.data.id}
            </Link>
          );
        },
      },
      {
        colId: "description",
        headerName: "SKU Description",
        field: "description",
      },
      {
        colId: "picture",
        headerName: "Picture?",
        maxWidth: 85,
        valueGetter: (params: any) => (params.data.image_file ? true : false),
        cellRenderer: (params: any) => {
          if (params.data.image_url || params.data.image_file) {
            return (
              <PictureCellRenderer
                id={params.data.id}
                url={params.data.image_url || params.data.image_file}
                sourceType={params.data.image_url ? "Bynder" : "Moldapp"}
              />
            );
          } else {
            return <span>n/a</span>;
          }
        },
        quickFilter: false,
      },
      {
        colId: "stock",
        headerName: "Any Stock Reported?",
        maxWidth: 150,
        valueGetter: (params: any) => (params.data.inventory ? true : false),
        cellRenderer: (params: any) => {
          if (params.data.inventory) {
            return (
              <Link
                className="table-link"
                to={`/product-information/inventory?p_prodid=${parseInt(
                  params.data.id,
                )}&p_is_iframe=Y`}
              >
                INVENTORY
              </Link>
            );
          } else {
            return <span>No Reported Stock</span>;
          }
        },
        quickFilter: false,
      },
      {
        colId: "stdpk",
        headerName: "Standard Pack",
        field: "stdpk",
        maxWidth: 100,
      },
      {
        colId: "mold_qty",
        field: "mold_qty",
        headerName: "Component qty.",
        maxWidth: 140,
        minWidth: 120,
        cellRenderer: (params: any) => (
          <MoldCompositionCellRenderer
            id={params.data.id}
            description={params.data.description}
            moldQty={params.data.mold_qty}
            nonMoldQty={params.data.non_mold_qty}
          />
        ),
      },
    ],
    [],
  );

  const defaultColDef: any = useMemo(
    () => ({
      minWidth: 100,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );

  const clearAllFilters = () => {
    resetSearch();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      gridRef.current?.api?.setQuickFilter(search ?? "");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  }, []);

  const overlayNoRowsTemplate = useMemo(
    () =>
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(search)
        ? "<span>No results found with these criteria<span>"
        : "<span><b>There are no other SKUs for this Global Product Name<b><span>",
    [search],
  );

  return (
    <div className="py-2 px-2 bg-white">
      <div className="d-flex flex-column">
        {data.length == 1 ? (
          <h6 className="black-text mb-2">
            This table displays the <strong>only other SKU</strong> included in
            the
            {Predicates.isNotNullAndNotUndefined(tccName)
              ? " TCC Name "
              : " Global Product Name "}
            :<strong> {tccName ?? globalProductName}</strong>
          </h6>
        ) : data.length > 0 ? (
          <h6 className="black-text mb-2">
            This table displays all <strong>{`${data.length} SKUs `}</strong>
            included in the
            {Predicates.isNotNullAndNotUndefined(tccName)
              ? " TCC Name "
              : " Global Product Name "}
            :<strong> {tccName ?? globalProductName}</strong>
          </h6>
        ) : (
          <h6 className="black-text mb-2">
            There are <strong>no other SKUs</strong> included in the
            {Predicates.isNotNullAndNotUndefined(tccName)
              ? " TCC Name "
              : " Global Product Name "}
            :<strong> {tccName ?? globalProductName}</strong>
          </h6>
        )}

        <div className="d-flex justify-content-between mb-1">
          <div className="d-flex align-items-center">
            <label className="d-inline-block">
              Show
              <select
                onChange={onPageSizeChanged}
                className="table-top-input"
                id="page-size"
                value={pageSize}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">All</option>
              </select>
              entries
            </label>
          </div>
          <div className="d-flex align-items-center">
            <label className="d-inline-block">
              Search:
              <input
                type="search"
                className="table-top-input"
                value={search}
                onChange={onSearchChange}
              />
            </label>
            {/* <button
              className="icon-button ml-3 mr-3"
              onClick={clearAllFilters}
              disabled={Predicates.isNullOrUndefinedOrEmpty(search)}
            >
              <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
            </button> */}
          </div>
        </div>

        <div className="ag-theme-alpine shadow-sm bg-white">
          <AgGridReact
            domLayout="autoHeight"
            ref={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={pageSize}
            onPaginationChanged={onPaginationChanged}
            unSortIcon
            suppressPaginationPanel
            overlayNoRowsTemplate={overlayNoRowsTemplate}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            onFilterChanged={onFilterChanged}
          ></AgGridReact>
        </div>
        <TablePagination
          gridRef={gridRef}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPages={totalPages}
          totalEntries={totalEntries}
        />
      </div>
    </div>
  );
};
