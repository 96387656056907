import { useState, ReactNode, useEffect, useMemo } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import TopNav from "./TopNav";
import { useLocation, matchPath } from "react-router-dom";
import SideNav from "./SideNav";
import { ProductProvider } from "../../context/ProductContext";

interface Props {
  children: ReactNode;
}

const Layout = (props: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { children } = props;
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState("Product Mass Update");

  const titleMap = useMemo(
    () => [
      { path: "/product-master", title: "Product Master Search" },
      {
        path: "/product-information",
        title: "Product Master - Product Information",
      },
      {
        path: "/product-information/user-guide",
        title: "Product Information - User Guide",
      },
      {
        path: "/product-master/user-guide",
        title: "Product Master Search - User Guide",
      },
      { path: "/skus", title: "Search PIS" },
      { path: "/skus/create", title: "New SKU Creation" },
      { path: "/skus/requests", title: "My approval requests" },
      { path: "/tccs/*", title: "Tupperware Consumer Code" },
      { path: "/no-access", title: "Session Expired / Not authorized" },
    ],
    [],
  );

  const route = useMemo(
    () => titleMap.find((item) => matchPath(item.path, location.pathname)),
    [location, titleMap],
  );

  useEffect(() => {
    if (route && route.title) {
      setPageTitle(route.title);
      document.title = route.title;
    }
  }, [route]);

  return (
    <div>
      {/* Page Wrapper */}
      <div id="wrapper">
        <ProductProvider>
          <SideNav toggle={toggleMenu} showMenu={showMenu} />

          {/* Content Wrapper */}
          <div
            id="content-wrapper"
            className={`d-flex flex-column ${
              showMenu ? "padding-open" : "padding-collapsed"
            }`}
          >
            {/* Main Content */}
            <div id="content">
              <TopNav toggle={toggleMenu} title={pageTitle} />
              <div id="modal-container" className="pt-1">
                {/* Begin Page Content */}
                <div id="main-content" className="container-fluid">
                  <ToastContainer
                    hideProgressBar
                    newestOnTop
                    draggable={false}
                    theme="colored"
                    transition={Zoom}
                  />
                  {children}
                </div>
              </div>
              {/* /.container-fluid */}
            </div>
            {/* End of Main Content */}

            {/* Footer */}
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Tupperware 2024</span>
                </div>
              </div>
            </footer>
            {/* End of Footer */}
          </div>
          {/* End of Content Wrapper */}
        </ProductProvider>
      </div>
      {/* End of Page Wrapper */}
    </div>
  );
};

export default Layout;
