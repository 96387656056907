import { useCallback, useEffect, useState } from "react";
import useAxios from "../../utils/useAxios";
import { IComponent } from "../../types/data.interface";
import Spinner from "../Spinner";

const MoldCompositionModal = ({
  model,
}: {
  model: { id: string; description: string };
}) => {
  const axios = useAxios();

  const [molds, setMolds] = useState<any[]>([]);
  const [nonMolds, setNonMolds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchComponentsAsync = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/product-details/${model.id}/molds/?limit=500`,
      );
      const sortedComponents = response.data.results.sort(function (
        a: any,
        b: any,
      ) {
        return a.id - b.id;
      });
      setMolds(
        sortedComponents.filter(
          (component: any) => component.compo_type === "B",
        ),
      );
      setNonMolds(
        sortedComponents.filter(
          (component: any) => component.compo_type === "P",
        ),
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [axios, model]);

  useEffect(() => {
    fetchComponentsAsync();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      {molds.length > 0 && (
        <>
          <h5 className="h6 mb-1">Molded Components</h5>
          <div className="molds-table-container">
            <table className="table table-striped table-bordered mb-1">
              <thead>
                <tr>
                  <th scope="col" style={{ minWidth: 90 }}>
                    <span className="d-flex justify-between">
                      Mold n°<i className="fa fa-sort-down ml-auto"></i>
                    </span>
                  </th>
                  <th style={{ minWidth: 150 }} scope="col">
                    Description
                  </th>
                  <th style={{ maxWidth: 80 }} scope="col">
                    N° of pieces
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Material
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Color
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Decoration Technique
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Artwork
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {molds &&
                  molds.map((mold: IComponent, index) => (
                    <tr key={mold.id + index}>
                      <td>{mold.id}</td>
                      <td>{mold.name}</td>
                      <td>{mold.nr_pieces}</td>
                      <td>{mold.material}</td>
                      <td>{mold.color}</td>
                      <td>{mold.decoration_technique}</td>
                      <td>{mold.artwork}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <small className="mb-3">Total entries: {molds.length}</small>
        </>
      )}

      {molds.length > 0 && nonMolds.length > 0 && <div className="mb-3"></div>}

      {nonMolds.length > 0 && (
        <>
          <h5 className="h6 mb-1">Non-Molded Components</h5>
          <div className="molds-table-container">
            <table className="table table-striped table-bordered mb-1">
              <thead>
                <tr>
                  <th scope="col" style={{ minWidth: 120 }}>
                    <span className="d-flex justify-between">
                      Sequence n°<i className="fa fa-sort-down ml-auto"></i>
                    </span>
                  </th>
                  <th style={{ minWidth: 150 }} scope="col">
                    Description
                  </th>
                  <th style={{ maxWidth: 80 }} scope="col">
                    N° of pieces
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Material
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Color
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Decoration Technique
                  </th>
                  <th style={{ minWidth: 100 }} scope="col">
                    Artwork
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {nonMolds &&
                  nonMolds.map((nonMold: IComponent, index) => (
                    <tr key={nonMold.id + index}>
                      <td>{nonMold.id}</td>
                      <td>{nonMold.name}</td>
                      <td>{nonMold.nr_pieces}</td>
                      <td>{nonMold.material}</td>
                      <td>{nonMold.color}</td>
                      <td>{nonMold.decoration_technique}</td>
                      <td>{nonMold.artwork}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <small>Total entries: {nonMolds.length}</small>
        </>
      )}
    </div>
  );
};

export default MoldCompositionModal;
