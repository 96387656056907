import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const ConfirmDeleteModal = ({
  message,
  cancelDelete,
  confirmDelete,
}: {
  message: string;
  cancelDelete: any;
  confirmDelete: any;
}) => {
  return (
    <div className="text-center p-5 m-3">
      <FontAwesomeIcon icon={faWarning} size="2x" className="mb-4" />
      <p
        className="text-center mb-5"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      <div className="d-flex justify-content-center align-center">
        <button
          onClick={confirmDelete}
          type="button"
          className="btn btn-primary"
        >
          Yes and remove{" "}
        </button>
        <button
          onClick={cancelDelete}
          type="button"
          className="btn btn-secondary ml-3"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
