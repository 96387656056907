import { IColor } from "../types/data.interface";

export const formatColorIntoOption = (item: IColor) => ({
    value: item,
    label: `${item.id} - ${item.name}`,
});

export const getColors = (
    colors: IColor[],
) =>
    colors.map(formatColorIntoOption);