import {
  ICompositionComponent,
  ISkuComposition,
} from "../../../../types/data.interface";
import { ExpandableSection } from "../expandable-section";
import { CompositionCheckbox } from "../sku-composition-modal";
import { CompositionType } from "../sku-composition-modal/AddSkuCompositionModal";
import ComponentsGroupTreeNode from "./ComponentsGroupTreeNode";
import TreeActionsGroup from "./TreeActionsGroup";

const SkuTreeNode = ({
  id,
  sku,
  isRoot,
  isSummaryPage,
  isMainPage,
  isPipPage,
  addActionHandler,
  handleRemoveNode,
  openEditModal,

  shouldIncludeSku,
  manageSkuInclusion,
  manageComponentInclusion,
  assertComponentIsIncluded,
}: {
  id: string;
  sku: ISkuComposition;
  isRoot: boolean;
  isSummaryPage: boolean;
  isMainPage: boolean;
  isPipPage: boolean;
  addActionHandler?: (node_id: number) => void;
  handleRemoveNode?: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
  openEditModal?: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;

  shouldIncludeSku: boolean;
  manageSkuInclusion: any;
  manageComponentInclusion: any;
  assertComponentIsIncluded: any;
}) => {
  return (
    <li
      className={`d-flex justify-content-between gap-4 ${
        isRoot && !isMainPage && !isSummaryPage ? "remove-tree-link" : ""
      }`}
    >
      {isRoot && !isMainPage && !isSummaryPage && (
        <CompositionCheckbox
          checkedChangeHandler={manageSkuInclusion}
          nodeId={sku.node_id ?? -1}
          isChecked={shouldIncludeSku}
          isDisabled={false}
        />
      )}

      <div className="w-100">
        <ExpandableSection
          sectionId={id}
          title={`SKU 11 ${sku.id.padStart(6, "0")} - ${sku.name}`}
          expandedByDefault={true}
        >
          <ul>
            {sku.skus.map((childSku: ISkuComposition, index: number) => (
              <SkuTreeNode
                key={childSku.node_id}
                id={childSku.id + "-" + index}
                sku={childSku}
                isRoot={false}
                isSummaryPage={isSummaryPage}
                isMainPage={isMainPage}
                isPipPage={isPipPage}
                shouldIncludeSku={shouldIncludeSku}
                manageSkuInclusion={manageSkuInclusion}
                manageComponentInclusion={manageComponentInclusion}
                assertComponentIsIncluded={assertComponentIsIncluded}
              />
            ))}

            {sku.molded_components.length > 0 && (
              <ComponentsGroupTreeNode
                id={id}
                type={"Molded"}
                isRoot={false}
                isSummaryPage={isSummaryPage}
                isMainPage={isMainPage}
                isPipPage={isPipPage}
                components={sku.molded_components}
                shouldIncludeSku={shouldIncludeSku}
                manageComponentInclusion={manageComponentInclusion}
                assertComponentIsIncluded={assertComponentIsIncluded}
              />
            )}

            {sku.non_molded_components.length > 0 && (
              <ComponentsGroupTreeNode
                id={id}
                type={"NonMolded"}
                isRoot={false}
                isSummaryPage={isSummaryPage}
                isMainPage={isMainPage}
                isPipPage={isPipPage}
                components={sku.non_molded_components}
                shouldIncludeSku={shouldIncludeSku}
                manageComponentInclusion={manageComponentInclusion}
                assertComponentIsIncluded={assertComponentIsIncluded}
              />
            )}
          </ul>
        </ExpandableSection>
      </div>

      {!isSummaryPage &&
        isRoot &&
        openEditModal &&
        handleRemoveNode &&
        addActionHandler && (
          <TreeActionsGroup
            node={sku}
            isMainPage={isMainPage}
            compositionType="sku"
            addActionHandler={addActionHandler}
            editActionHandler={openEditModal}
            removeActionHandler={handleRemoveNode}
          />
        )}
    </li>
  );
};

export default SkuTreeNode;
