import { AxiosError, AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { URL } from "../libraries/http/url";
import { Predicates } from "../libraries/predicates/predicates";
import { Pageable, PageableDTO, PageableQuery } from "../types/pageable";
import useAxios from "../utils/useAxios";
import AbortControllers from "../types/request";

type ProductMasterSearch = {
  id?: string;
  tcc_id?: number;
  global_product_id?: number;
  queryString?: string;
  search?: string;
  searchProduct?: string;
} & Partial<PageableQuery>;

export type ProductMaster = {
  id: string;
  description: string;
  image_file: string;
  inventory: boolean;
  mold_qty: number;
  stdpk: string;
};

export type ProductMasterResponse =
  | PageableDTO<ProductMaster[]>
  | ProductMaster[];

const PMAbortControllers = {
  productDetails: null,
} as AbortControllers;

class ProductMasterMapper {
  static pageableToModel(
    dto: ProductMasterResponse,
    offset?: number,
  ): Pageable<ProductMaster> {
    return {
      total: Predicates.isPageable(dto) ? dto.count : 0,
      offset: offset ?? 0,
      data: Predicates.isPageable(dto) ? dto.results : dto,
    };
  }
}

export const fetchProductMaster = async ({
  global_product_id,
  tcc_id,
  axios,
  limit,
  offset,
  queryString,
  search,
  searchProduct,
}: ProductMasterSearch & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams(
      {
        limit,
        offset,
        global_product_id,
        tcc_id,
        search,
        searchProduct,
      },
      queryString,
    );

    if (
      Predicates.isNotNullAndNotUndefined(PMAbortControllers.productDetails)
    ) {
      PMAbortControllers.productDetails.abort();
    }

    PMAbortControllers.productDetails = new AbortController();

    const response = await axios.get<ProductMasterResponse>(
      `/product-master/product-details/?${searchParams.toString()}`,
      { signal: PMAbortControllers.productDetails.signal },
    );

    return ProductMasterMapper.pageableToModel(response.data, offset);
  } catch (err) {
    console.error((err as Error).message);
    return Promise.reject(err as Error);
  }
};

export const fetchProductMasterById = async ({
  id,
  axios,
}: Pick<Required<ProductMasterSearch>, "id"> & { axios: AxiosInstance }) => {
  try {
    const response = await axios.get<ProductMaster>(
      `/product-master/product-details/${parseInt(id)}`,
    );

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const useProductMasterById = ({
  id,
}: Pick<ProductMasterSearch, "id">) => {
  const [data, setData] = useState<{
    isLoading: boolean;
    data?: ProductMaster;
  }>({ isLoading: false });
  const axios = useAxios();

  useEffect(() => {
    const getData = async () => {
      if (Predicates.isNotNullAndNotUndefined(id)) {
        setData({ isLoading: true });
        const response = await fetchProductMasterById({ id, axios });
        setData({ isLoading: false, data: response });
      }
    };
    getData();
  }, [id]);

  return data;
};

export default function useProductMaster({
  global_product_id,
  tcc_id,
  limit,
  offset,
  queryString,
  search,
  searchProduct,
}: ProductMasterSearch) {
  const [data, setData] = useState<{
    isLoading: boolean;
    data?: Pageable<ProductMaster>;
  }>({ isLoading: false });
  const axios = useAxios();

  useEffect(() => {
    const getData = async () => {
      try {
        setData({ isLoading: true });
        const response = await fetchProductMaster({
          global_product_id,
          tcc_id,
          axios,
          limit,
          offset,
          queryString,
          search,
          searchProduct,
        });

        setData({ isLoading: false, data: response });
      } catch (err) {
        if (
          !(err instanceof AxiosError && err.code === AxiosError.ERR_CANCELED)
        )
          setData({ isLoading: false });
      }
    };
    if (
      Predicates.isNotNullAndNotUndefined(global_product_id) ||
      Predicates.isNotNullAndNotUndefined(tcc_id) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(queryString) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(search) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(searchProduct)
    )
      getData();
  }, [
    tcc_id,
    global_product_id,
    limit,
    offset,
    queryString,
    search,
    searchProduct,
  ]);

  return data;
}
