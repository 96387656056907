import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import UserGuidePMS from "../components/UserGuidePMS";

const ProductMasterUserGuide = () => {
  const scrollToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="userguide-page">
        <UserGuidePMS />
      </div>
      <button className="scroll-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon
          icon={faChevronUp}
          color="#fff"
          className="text-align-center"
        />
      </button>
    </>
  );
};

export default ProductMasterUserGuide;
