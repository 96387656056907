import Select from "react-select";
import OptionFormatter from "../../../../components/OptionFormatter";
import { HierarchyInfoSection } from "../../components/hierarchy-info";
import {
  IGlobalProduct,
  IRequestorMarket,
  ITcc,
} from "../../../../types/data.interface";
import useRequestorMarket from "../../../../hooks/useRequestorMarket";
import {
  formatRequestorMarketIntoOption,
  getRequestorMarkets,
} from "../../../../helpers/requestor-market.helper";

export type RequestorProps = {
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  requestorMarket: IRequestorMarket;
  setRequestorMarket: (market: IRequestorMarket) => void;
  setUnsavedChangesToTrue: () => void;
};

const RequestorInfo = ({
  selectedGP,
  selectedTcc,
  requestorMarket,
  setRequestorMarket,
  setUnsavedChangesToTrue,
}: RequestorProps) => {
  const { requestorMarkets, isLoading: isRequestorMarketsLoading } =
    useRequestorMarket();

  const handleRequestorMarketChange = (e: any) => {
    if (e) {
      setRequestorMarket(e.value);
    } else {
      setRequestorMarket({ id: "", short_name: "", full_name: "" });
    }
  };

  return (
    <>
      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div>
        <div className="card-header my-2 rounded d-flex ">
          <h6 className="m-0 text-primary fw-bold">Requestor Information</h6>
        </div>

        <div className="col-lg-4 form-group">
          <label className="form-label">
            Requestor Location <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={getRequestorMarkets(requestorMarkets)}
            onChange={handleRequestorMarketChange}
            formatOptionLabel={OptionFormatter}
            value={
              requestorMarket.id
                ? formatRequestorMarketIntoOption(requestorMarket)
                : null
            }
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            isLoading={isRequestorMarketsLoading}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RequestorInfo;
