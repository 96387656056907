import { IDecorationTech } from "../types/data.interface";

export const formatDecorTechIntoOption = (item: IDecorationTech) => ({
    value: item,
    label: `${item.id} - ${item.name}`,
});

export const getDecorTechs = (
    decorTechs: IDecorationTech[],
) =>
    decorTechs.map(formatDecorTechIntoOption);