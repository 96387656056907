import { Predicates } from "../libraries/predicates/predicates";
import { IGlobalProduct } from "../types/data.interface";

type GlobalProductFilterType = {
  subCategory?: number;
  productLine?: number;
  id?: number;
};

export const formatGlobalProductIntoOption = (item: IGlobalProduct) => ({
  value: item,
  label: `${getGlobalProductCode(item)} - ${item.name}`,
});

export const getGlobalProductCode = (globalProduct: IGlobalProduct) => {
  return `${globalProduct?.sub_category?.parent?.code}${globalProduct?.sub_category.code}${globalProduct?.product_line.code}${globalProduct?.suffix}`;
};


export const getGlobalProduct = (
  globalProducts: IGlobalProduct[],
  filter: GlobalProductFilterType
) => {
  if (Predicates.isNotNullAndNotUndefined(filter.id)) {
    const globalProduct = globalProducts.find((item) => item.id === filter.id);
    return globalProduct ? [formatGlobalProductIntoOption(globalProduct)] : [];
  }

  if (
    Predicates.isNotNullAndNotUndefined(filter.subCategory) &&
    Predicates.isNotNullAndNotUndefined(filter.productLine)
  )
    return globalProducts
      .filter(
        (item) =>
          item.sub_category.id === filter.subCategory &&
          item.product_line.id === filter.productLine
      )
      .map(formatGlobalProductIntoOption);

  if (Predicates.isNotNullAndNotUndefined(filter.subCategory))
    return globalProducts
      .filter((item) => item.sub_category.id === filter.subCategory)
      .map(formatGlobalProductIntoOption);

  if (Predicates.isNotNullAndNotUndefined(filter.productLine))
    return globalProducts
      .filter((item) => item.product_line.id === filter.productLine)
      .map(formatGlobalProductIntoOption);

  return globalProducts.map(formatGlobalProductIntoOption);
};
