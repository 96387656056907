import { AxiosError, AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { DateTimeFormat } from "../libraries/date-time-format/date-time-format";
import { URL } from "../libraries/http/url";
import { Predicates } from "../libraries/predicates/predicates";
import {
  IPisRequest,
  PisRequestOrderByType,
  PisRequestStatus,
  PisRequestTable,
  PisRequestTablePageable,
} from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import AbortControllers from "../types/request";
import useAxios from "../utils/useAxios";

type SearchParams = {
  id: number | undefined;
  pis_request_id: string | null;
  revision?: string;
};

type CreateParams = {
  requestData: IPisRequest;
};

type UpdateParams = {
  id: number;
  requestData: IPisRequest;
};

type ApprovalFinalStepsParams = {
  requestId: string;
  revision: number;
  skuId: string;
  userId: string;
};

type PisRequestResponse = IPisRequest;

export type ResPmMarket = {
  id: string;
  short_name: string;
  full_name: string;
  currency: string;
  costcenter: string;
  requestcountrycode: string;
  pis_requestor: string;
};

export type PisRequestDTO = {
  id: number;
  pis_request_id: number;
  finish_goods_no_assigned: string;
  item_description: string;
  request_date: string;
  requested_by: string;
  requestor_location: string;
  date_assigned: string;
  release_date: string;
  uidchng: string;
  revision: number;
  status: string;
  comments: string;
  revise_flag: string;
};

type PisRequestResponseDTO = PageableDTO<PisRequestDTO[]> | PisRequestDTO[];

type PisRequestTableParams = {
  limit?: number;
  offset?: number;
  lastDays?: number;
  search?: string;
  skuSearch?: string;
  requestorSearch?: string;
  status?: Array<PisRequestStatus>;
  orderBy?: PisRequestOrderByType;
};

const PisAbortControllers = {
  requestTable: null,
} as AbortControllers;

class PisRequestMapper {
  static toModel(dto: PisRequestDTO): PisRequestTable {
    return {
      id: dto.id,
      pis_request_id: dto.pis_request_id,
      sku_id: dto.finish_goods_no_assigned,
      sku_description: dto.item_description,
      request_date: new Date(dto.request_date),
      requestor: dto.requested_by,
      requestor_location: dto.requestor_location,
      approved_date: Predicates.isNotNullAndNotUndefined(dto.date_assigned)
        ? new Date(dto.date_assigned)
        : undefined,
      approver: dto.uidchng,
      release_date: dto.release_date ? new Date(dto.release_date) : undefined,
      revision: dto.revision,
      status: dto.status as PisRequestStatus,
      comments: dto.comments,
      revise_flag: dto.revise_flag,
    };
  }

  static pageableToModel(
    dto: PisRequestResponseDTO,
    offset?: number,
  ): PisRequestTablePageable {
    return {
      total: Predicates.isPageable(dto) ? dto.count : 0,
      offset: offset ?? 0,
      data: Predicates.isPageable(dto)
        ? dto.results.map(PisRequestMapper.toModel)
        : dto.map(PisRequestMapper.toModel),
    };
  }
}

export const fetchPisRequest = async ({
  id,
  pis_request_id,
  revision,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      pis_request_id,
      revision,
    });
    const response = await axios.get<PisRequestResponse>(
      `/pis-request/${id}?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchPisHistory = async ({
  id,
  revision,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      revision: revision ?? "",
    });
    const response = await axios.get<PisRequestResponse>(
      `/pis-history/${id ?? ""}/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const createPisRequest = async ({
  requestData,
  axios,
}: CreateParams & { axios: AxiosInstance }) => {
  try {
    const response = await axios({
      method: "post",
      url: `/pis-request/`,
      data: requestData,
    });

    return Predicates.parsePageableReponseToType<PisRequestResponse>(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const updatePisRequest = async ({
  id,
  requestData,
  axios,
}: UpdateParams & { axios: AxiosInstance }) => {
  try {
    const response = await axios({
      method: "patch",
      url: `/pis-request/${id}/`,
      data: requestData,
    });

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const finalizePisRequestApproval = async ({
  requestId,
  revision,
  skuId,
  userId,
  axios,
}: ApprovalFinalStepsParams & { axios: AxiosInstance }) => {
  try {
    const response = await axios({
      method: "post",
      url: `/finalize-pis-request/`,
      data: {
        request_id: requestId,
        revision: revision,
        sku_id: skuId,
        user_id: userId,
      },
    });

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export default function usePisRequest({
  id,
  pis_request_id,
  revision,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IPisRequest | null>(null);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchPisRequest({
        id,
        pis_request_id,
        revision,
        axios,
      });

      setData(response);
    };

    getData();
  }, [id, revision]);

  return data;
}

export const fetchPisRequestTable = async ({
  axios,
  limit,
  offset,
  lastDays,
  search,
  skuSearch,
  requestorSearch,
  status,
  orderBy,
}: PisRequestTableParams & { axios: AxiosInstance }) => {
  try {
    let request_date__gte = undefined;

    if (Predicates.isNotNullAndNotUndefined(lastDays) && lastDays >= 30) {
      const now = new Date();
      now.setDate(now.getDate() - lastDays);
      request_date__gte = DateTimeFormat.formatDate(now);
    }

    const searchParams = URL.createSearchParams({
      limit,
      offset,
      search,
      sku_search: skuSearch,
      requestor_search: requestorSearch,
      request_date__gte,
      status,
      ordering: orderBy,
    });

    if (Predicates.isNotNullAndNotUndefined(PisAbortControllers.requestTable)) {
      PisAbortControllers.requestTable.abort();
    }

    PisAbortControllers.requestTable = new AbortController();

    const response = await axios.get<PisRequestResponseDTO>(
      `/pis-request-queue/?${searchParams.toString()}`,
      { signal: PisAbortControllers.requestTable.signal },
    );

    return PisRequestMapper.pageableToModel(response.data, offset);
  } catch (err) {
    return Promise.reject(err);
  }
};

export function usePisRequestTable({
  limit,
  offset,
  lastDays,
  search,
  skuSearch,
  requestorSearch,
  status,
  orderBy,
}: PisRequestTableParams) {
  const axios = useAxios();
  const [data, setData] = useState<{
    isLoading: boolean;
    data?: PisRequestTablePageable;
  }>({ isLoading: false });

  useEffect(() => {
    const getData = async () => {
      try {
        setData({ isLoading: true });
        const response = await fetchPisRequestTable({
          limit,
          axios,
          offset,
          lastDays,
          search,
          skuSearch,
          requestorSearch,
          status,
          orderBy,
        });

        setData({ isLoading: false, data: response });
      } catch (err) {
        if (
          !(err instanceof AxiosError && err.code === AxiosError.ERR_CANCELED)
        )
          setData({ isLoading: false });
      }
    };

    getData();
  }, [
    limit,
    offset,
    lastDays,
    search,
    skuSearch,
    requestorSearch,
    status,
    orderBy,
  ]);

  return data;
}
