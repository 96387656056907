import ReactModal from "react-modal";
import { AddSkuCompositionModal } from "../../components/sku-composition-modal";
import { useEffect, useState } from "react";
import { SkuCompositionTree } from "../../components/sku-composition-tree";
import {
  ICompositionComponent,
  IGlobalProduct,
  ISkuComposition,
  ITcc,
  Sku,
} from "../../../../types/data.interface";
import {
  COMPOSITION_OPTIONS,
  CompositionType,
  EditCompositionParams,
} from "../../components/sku-composition-modal/AddSkuCompositionModal";
import {
  checkIfAtLeastOneComponentIsTheSameInList,
  checkIfSkuIdIsIncludedInNode,
  findNodeIndexByNodeId,
  generateNodeId,
  getNodeByNodeId,
} from "./helpers";
import { toast } from "react-toastify";
import { HierarchyInfoSection } from "../../components/hierarchy-info";

export type CompositionProps = {
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  savedSkuComposition: ISkuComposition;
  saveSkuComposition: (composition: ISkuComposition) => void;
  setUnsavedChangesToTrue: () => void;

  skuIdInRevision?: string;
};

const Composition = ({
  selectedGP,
  selectedTcc,
  savedSkuComposition,
  saveSkuComposition,
  setUnsavedChangesToTrue,

  skuIdInRevision,
}: CompositionProps) => {
  const [showSkuCompositionModal, setShowSkuCompositionModal] = useState(false);

  const [skuComposition, setSkuComposition] =
    useState<ISkuComposition>(savedSkuComposition);
  const [editModalParams, setEditModalParams] =
    useState<EditCompositionParams | null>();

  const openEditModal = (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => {
    setEditModalParams({
      compositionEditType: compositionEditType,
      compositionEditObject: compositionEditObject,
    });

    setShowSkuCompositionModal(true);
  };

  const addSkuToComposition = (sku: ISkuComposition | null, nodeId: number) => {
    let updatedSkuList: ISkuComposition[] = skuComposition.skus;

    if (
      sku &&
      skuIdInRevision &&
      checkIfSkuIdIsIncludedInNode(sku, skuIdInRevision)
    ) {
      toast.error(
        "You can't include the same SKU you are revising to the composition",
      );
      return;
    }

    if (nodeId == -1 && sku) {
      updatedSkuList = [
        ...updatedSkuList,
        { ...sku, node_id: generateNodeId() },
      ];
      toast.success(
        `Successfully added SKU 11 ${sku.id.padStart(6, "0")} to composition`,
      );
    } else if (nodeId !== -1) {
      const oldNodeIndex: number = findNodeIndexByNodeId(
        nodeId,
        updatedSkuList,
      );

      if (sku) {
        updatedSkuList.splice(oldNodeIndex, 1, sku);
      } else {
        updatedSkuList.splice(oldNodeIndex, 1);
      }
    }

    setSkuComposition({ ...skuComposition, skus: updatedSkuList });
  };

  const duplicateSku = (nodeId: number) => {
    const duplicatedSku: ISkuComposition | undefined = skuComposition.skus.at(
      findNodeIndexByNodeId(nodeId, skuComposition.skus),
    );
    if (duplicatedSku) addSkuToComposition(duplicatedSku, -1);
  };

  const handleAdditionOfComponents = (
    moldedComponents: ICompositionComponent[],
    nonMoldedComponents: ICompositionComponent[],
    nodeId: number,
  ) => {
    if (
      checkIfAtLeastOneComponentIsTheSameInList(
        moldedComponents,
        skuComposition.molded_components,
      )
    ) {
      toast.error(
        `You're trying to add at least one Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
      );
      return;
    }

    if (
      checkIfAtLeastOneComponentIsTheSameInList(
        nonMoldedComponents,
        skuComposition.non_molded_components,
      )
    ) {
      toast.error(
        `You're trying to add at least one Non-Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
      );
      return;
    }

    setSkuComposition({
      ...skuComposition,
      molded_components: getUpdatedComponentsList(
        "Molded",
        moldedComponents,
        nodeId,
      ),
      non_molded_components: getUpdatedComponentsList(
        "NonMolded",
        nonMoldedComponents,
        nodeId,
      ),
    });

    if (editModalParams) {
      closeModal();
      toast.success("Successfully edited selected component");
    } else {
      toast.success(`Successfully added components to composition`);
    }
  };

  const getUpdatedComponentsList = (
    componentType: string,
    components: ICompositionComponent[],
    nodeId: number,
  ) => {
    let componentsListToUpdate: ICompositionComponent[] =
      componentType === "NonMolded"
        ? skuComposition.non_molded_components
        : skuComposition.molded_components;

    if (nodeId == -1) {
      // When it is an addition
      const updatedComponentsList = components.map(
        (component: ICompositionComponent) => {
          return { ...component, node_id: generateNodeId() };
        },
      );
      componentsListToUpdate = [
        ...componentsListToUpdate,
        ...updatedComponentsList,
      ];
    } else {
      // When it is an edition
      const component: ICompositionComponent = components[0];
      if (component) {
        const oldNode: ICompositionComponent = getNodeByNodeId(
          nodeId,
          skuComposition,
        ) as ICompositionComponent;
        const isSwitchBetweenComponentTypes: boolean =
          oldNode.type !== componentType;
        const oldNodeIndex: number = findNodeIndexByNodeId(
          nodeId,
          componentsListToUpdate,
        );

        if (isSwitchBetweenComponentTypes) {
          const componentsListToDelete: ICompositionComponent[] =
            oldNode.type === "NonMolded"
              ? skuComposition.non_molded_components
              : skuComposition.molded_components;
          componentsListToDelete.splice(oldNodeIndex, 1);
          componentsListToUpdate = [
            ...componentsListToUpdate,
            { ...component, node_id: nodeId },
          ];
        } else {
          componentsListToUpdate.splice(oldNodeIndex, 1, component);
        }
      }
    }

    return componentsListToUpdate;
  };

  const changeComponentQuantity = (nodeId: number, newQuantity: number) => {
    const componentToChange: ICompositionComponent | undefined =
      getNodeByNodeId(nodeId, skuComposition) as ICompositionComponent;
    if (componentToChange) {
      const updatedList = getUpdatedComponentsList(
        componentToChange.type,
        [{ ...componentToChange, quantity: newQuantity }],
        nodeId,
      );
      if (componentToChange.type === "Molded") {
        setSkuComposition({
          ...skuComposition,
          molded_components: updatedList,
        });
      } else {
        setSkuComposition({
          ...skuComposition,
          non_molded_components: updatedList,
        });
      }
    }
  };

  const deleteCompositionNode = (
    node_id: number,
    compositionType: CompositionType,
  ) => {
    const filteredSkus: ISkuComposition[] = skuComposition.skus.filter(
      (node: ISkuComposition) => node.node_id != node_id,
    );
    const filteredMoldedComponents: ICompositionComponent[] =
      skuComposition.molded_components.filter(
        (node: ICompositionComponent) => node.node_id != node_id,
      );
    const filteredNonMoldedComponents: ICompositionComponent[] =
      skuComposition.non_molded_components.filter(
        (node: ICompositionComponent) => node.node_id != node_id,
      );

    setSkuComposition({
      ...skuComposition,
      skus: filteredSkus,
      molded_components: filteredMoldedComponents,
      non_molded_components: filteredNonMoldedComponents,
    });

    toast.success(
      `Successfully removed ${
        compositionType === COMPOSITION_OPTIONS.SKU ? "SKU" : "Component"
      } from composition`,
    );
  };

  const isCompositionEmpty = () => {
    return (
      !skuComposition ||
      (skuComposition.skus.length === 0 &&
        skuComposition.molded_components.length === 0 &&
        skuComposition.non_molded_components.length === 0)
    );
  };

  const closeModal = () => {
    setShowSkuCompositionModal(false);
    setEditModalParams(null);
  };

  useEffect(() => {
    saveSkuComposition(skuComposition);
  }, [skuComposition]);

  return (
    <>
      <ReactModal
        isOpen={showSkuCompositionModal}
        className="custom-modal mold-comps"
        overlayClassName="custom-overlay"
      >
        <AddSkuCompositionModal
          handleCloseModal={closeModal}
          handleAddSku={addSkuToComposition}
          handleAddComponents={handleAdditionOfComponents}
          editModalParams={editModalParams}
        />
      </ReactModal>

      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div className="card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Composition</h6>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <p className="font-italic">{`${
          isCompositionEmpty()
            ? "Your SKU has no components associated to it."
            : ""
        } Please click on "Add SKU / Components" to manage the SKU composition`}</p>
        <input
          type="submit"
          className="btn btn-primary"
          value="Add SKU / Components"
          onClick={() => setShowSkuCompositionModal(true)}
        />
      </div>

      <SkuCompositionTree
        skuComposition={skuComposition}
        isMainPage={true}
        duplicateActionHandler={duplicateSku}
        changeQuantityActionHandler={changeComponentQuantity}
        handleRemoveNode={deleteCompositionNode}
        openEditModal={openEditModal}
        shouldIncludeSku={false}
        manageSkuInclusion={undefined}
        manageComponentInclusion={undefined}
        assertComponentIsIncluded={undefined}
      />
    </>
  );
};

export default Composition;
