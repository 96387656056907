import React from "react";
import ReactMarkdown from "react-markdown";
import { HeadingProps } from "react-markdown/lib/ast-to-react";

const toc: {
  level: number;
  id: string;
  title: string;
}[] = [];
const markdown = `## Authentication

Authentication and access to the **Product Master Search screen** can be done by clicking on the **Product Master** hyperlink displayed in the Main Menu or clicking on the sub-menu in the left lateral navigation bar.

![Product Master hyperlink in MoldApp > Item Master](/userguide/item_master_menu.png)
  >Product Master hyperlink in MoldApp > Item Master

![Product Master hyperlink in MoldApp > Item Master](/userguide/PIP_pms_link_sidebar.png) 
  >Product Master Search sub-menu link in lateral navigation bar

## Product Information 
 
The Product Information screen displays main information about the Product and SKU in the header and 5 tabs: Product Information, Components Listing, Packing Information, Local Description and Barcode.
    
### Product Information Header 
    
For Category, Sub-Category and Product Line fields have old values, respectively Category, Class, Brand Group and Brand Name, a tooltip appears on the right of the field name. When hovered, it displays the old value. 
  
![Tooltips Old Sub-Category](/userguide/PIP_tooltips.png) 
  >Tooltip showing the old sub-category of the SKU
  
The picture image can be expanded when clicking on it, opening a pop-up. 

![Image Popup](/userguide/PIP_picture_popup.png) 
  >SKU image popup
  
### Product Information tab 
  
Main SKU information is displayed in this tab.
  
None fields are editable.
  
Both Suggested Retail Price / Cartons and Suggested Retail Price / Sets display actual SRP values. When clicking on the link on the right of the field name, it opens a new browser tab displaying all the values history. 

![Product Info Tab](/userguide/PIP_product_info_tab.png) 
  >Product Information Tab

![Product Info Tab SRPs](/userguide/PIP_product_info_tab_srps.png) 
  >Suggested Retail Prices (Cartons and Sets)

### Components Listing tab 
  
Molded Components and non-Molded Components are displayed in 2 different tables, with their respective information. 

![Components Listing Tab](/userguide/PIP_components_listing.png) 
  >Components Listing Tab

### Packing Information tab 
  
Primary Packing Materials and Carton Information are displayed in 2 different tables, with their respective information.

![Packing Information Tab](/userguide/PIP_packing_info.png) 
  >Packing Information Tab

## Local Description Tab

All existing Local Descriptions related to the SKU Number are displayed in the table. Local Descriptions are sorted by default by creation date, from the most recent to the least one, where the first row is always the one related to the **en-US ISO Code**, which is automatically set as the **primary Local Description**. 

The Search bar above the table enables the user to search for some specific results in the table.

It is possible to sort multiple columns by holding down the "Shift" key while clicking the column header.

![Local Description tab](/userguide/PIP_local_desc_tab.png)
  >Local Description tab

Local Descriptions related to a specific Product, identified by its Product Number and Parent Code, present the following information:

-   **Local Description**: limited to 50 characters.
    
-   **ISO Code**: defined as a “language-country” abbreviated composed code, eg. “en-US“. 
    

## Barcodes Tab

The **Barcode** table displays all registered Barcodes related to the SKU Number. Barcodes are sorted by default in an alphabetical order by Location, then Type and then Sub-Type.

The Search bar above the table enables the user to search for some specific results in the table. 

It is possible to sort multiple columns by holding down the "Shift" key while clicking the column header.

Barcodes related to a specific Product, identified by its Product Number and Parent Code, present the following information:

-   **Location:** defined as a “language-country” abbreviated composed code, eg. “en-US“. 
    
-   Barcode **Type** + **Sub-Type**
    
-   **Code**: alphanumerical code, limited to 25 characters.
    
-   **Barcode image**: the file size is limited to 1MB and to jpg files only. 

![Barcode tab](/userguide/PIP_barcodes_tab.png)
>Barcode tab

### View Barcode image

To view an existing barcode image, click on the “**view**” icon. A pop-up will appear with the barcode image.

![View barcode image pop-up](/userguide/PIP_barcodes_tab_image_base.png)
>View barcode image pop-up
`;

const addIdToHeading = ({
  children,
  ...props
}: React.PropsWithChildren<HeadingProps>) => {
  const level = Number(props.node.tagName.match(/h(\d)/)?.slice(1));
  if (level && children && typeof children[0] === "string") {
    const id = children[0].toLowerCase().replace(/[^a-z0-9]+/g, "-");
    toc.push({
      level,
      id,
      title: children[0],
    });
    return React.createElement(props.node.tagName, { id }, children);
  } else {
    return React.createElement(props.node.tagName, props, children);
  }
};

const UserGuidePIPBase = () => {
  return (
    <div className="guide">
      <h1>User Guide</h1>
      <div className="table-of-content">
        <ul className="toc-indentation">
          <li>
            <a href="#authentication">Authentication</a>
          </li>
          <li>
            <a href="#product-information">Product Information</a>
            <ul>
              <li>
                <a href="#product-information-header">
                  Product Information Header
                </a>
              </li>
              <li>
                <a href="#product-information-tab">Product Information tab</a>
              </li>
              <li>
                <a href="#components-listing-tab">Components Listing tab</a>
              </li>
              <li>
                <a href="#packing-information-tab">Packing Information tab</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#local-description-tab">Local Description Tab</a>
          </li>
          <li>
            <a href="#barcodes-tab">Barcodes Tab</a>
            <ul>
              <li>
                <a href="#view-barcode-image">View Barcode image</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <ReactMarkdown
        children={markdown}
        components={{
          h2: addIdToHeading,
          h3: addIdToHeading,
          h4: addIdToHeading,
          h5: addIdToHeading,
          h6: addIdToHeading,
        }}
      />
    </div>
  );
};

export default UserGuidePIPBase;
