import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { IProductHier } from "../types/data.interface";

export default function useDatacatalog() {
  const axios = useAxios();
  const [datacatalog, setDatacatalog] = useState<IProductHier[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getDatacatalog = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get<IProductHier[]>(
          `/data-catalog/?ordering=code`,
        );
        setDatacatalog(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
      return [];
    };

    getDatacatalog();
  }, []);

  return { datacatalog, isLoading };
}
