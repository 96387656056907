import { PageableTableProvider } from "../../../../context/PageableTableContext";
import ApprovalQueueSection from "./ApprovalQueueSection";

export default function ApprovalQueue() {
  return (
    <>
      <h6 className="text-primary fw-bold card-header border-0 mb-2">
        Approval Queues
      </h6>
      <div className="mb-3">
        <PageableTableProvider>
          <ApprovalQueueSection />
        </PageableTableProvider>
      </div>
    </>
  );
}
