import { AgGridReact } from "ag-grid-react";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePageableTable } from "../context/PageableTableContext";
import useProductMaster, {
  ProductMaster,
  useProductMasterById,
} from "../hooks/useProductMaster";
import { Predicates } from "../libraries/predicates/predicates";
import { PageableTableActions } from "../states/pageable-table";
import MoldCompositionCellRenderer from "./cell-renderers/MoldCompositionCellRenderer";
import PictureCellRenderer from "./cell-renderers/PictureCellRenderer";
import { PageableTable } from "./table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import {
  DebounceContext,
  DebounceContextType,
} from "../context/DebounceContext";
import ProductContext from "../context/ProductContext";
import {
  retrieveObjectFromLocalStorage,
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../helpers/local-storage.helper";
import { TablePagePersistentTypes } from "../types/persistent-page-filters";

type ProductMasterTableProp = {
  localStoragePrefix: TablePagePersistentTypes["prefix"];
  checkUnsaved?: boolean;
  showCustomFields?: Array<string>;
  filters?: {
    productMasterId?: string;
    globalProductId?: number;
    tccId?: number;
    queryString?: string;
  };
  onSelectionChanged?: (value: ProductMaster) => void;
  enableAdvancedFilters?: boolean;
  showClearButton?: boolean;
};

const ProductMasterTable = memo(
  ({
    localStoragePrefix,
    checkUnsaved = false,
    filters,
    showCustomFields,
    onSelectionChanged,
    enableAdvancedFilters = true,
    showClearButton = true,
  }: ProductMasterTableProp) => {
    const [pageSize, setPageSize] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "pageSize",
        50,
      );
    });
    const gridRef = useRef<AgGridReact>(null);
    const [search, setSearch] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });
    const [searchToBeSent, setSearchToBeSent] = useState<string>(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });
    const { debounce } = useContext<DebounceContextType>(DebounceContext);
    const { setProductDetails } = useContext(ProductContext);
    const navigate = useNavigate();
    const {
      state: { offset, isFirstRender },
      dispatch,
    } = usePageableTable();

    const handleNavigationToProductInformation = useCallback(
      (event: any, pathToNavigate: string) => {
        event.preventDefault();
        let listOfFilters: any[] = [];
        columnDefs.forEach((colDef) => {
          const filterInstance = gridRef?.current?.api?.getFilterInstance(
            colDef.colId as string,
          );
          const filterModel = filterInstance?.getModel();
          if (colDef.colId) listOfFilters.push({ [colDef.colId]: filterModel });
        });

        if (checkUnsaved) {
          const confirmationToLeave = window.confirm(
            "You may have unsaved changes. Are you sure you want to leave?",
          );
          if (confirmationToLeave) {
            saveObjectsToLocalStorage<TablePagePersistentTypes>({
              prefix: localStoragePrefix,
              lastTableFilters: listOfFilters,
              lastTableTopFilters: { search: search, pageSize: pageSize },
            });
            navigate(pathToNavigate);
          }
        } else {
          saveObjectsToLocalStorage<TablePagePersistentTypes>({
            prefix: localStoragePrefix,
            lastTableFilters: listOfFilters,
            lastTableTopFilters: { search: search, pageSize: pageSize },
          });
          navigate(pathToNavigate);
        }
      },
      [search, pageSize],
    );

    const defaultColDef = useMemo(
      () => ({
        sortingOrder: ["asc" as const, "desc" as const],
        minWidth: 100,
        flex: 1,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
          sortAscending: "<i class='fa fa-sort-up'/>",
          sortDescending: "<i class='fa fa-sort-down'/>",
          sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
          filter: "<i class='fa fa-filter'></i>",
        },
      }),
      [],
    );

    const columnDefs = useMemo(
      () => [
        {
          colId: "id",
          field: "id",
          headerName: "SKU nº",
          maxWidth: 120,
          minWidth: 100,
          cellRenderer: (params: any) => {
            const pathToNavigate = `/product-information/?productnumber=${
              params.data.id
            }&sessid=${sessionStorage.getItem("sessid")}`;
            return (
              <>
                <Link
                  className="text-decoration-underline fw-bold"
                  to={pathToNavigate}
                  onClick={(event) =>
                    handleNavigationToProductInformation(event, pathToNavigate)
                  }
                >
                  {`11 ${params.data.id}`}
                </Link>
              </>
            );
          },
        },
        {
          colId: "description",
          headerName: "SKU Description",
          field: "description",
        },
        {
          colId: "picture",
          headerName: "Picture?",
          maxWidth: 85,
          valueGetter: (params: any) =>
            params.data.image_url || params.data.image_file ? true : false,
          filterValueGetter: (params: any) =>
            params.data.image_url || params.data.image_file ? "Picture" : "n/a",
          cellRenderer: (params: any) => {
            if (params.data.image_url || params.data.image_file) {
              return (
                <PictureCellRenderer
                  id={params.data.id}
                  url={params.data.image_url || params.data.image_file}
                  sourceType={params.data.image_url ? "Bynder" : "Moldapp"}
                />
              );
            } else {
              return <span>n/a</span>;
            }
          },
        },
        {
          colId: "stock",
          headerName: "Any Stock Reported?",
          maxWidth: 150,
          valueGetter: (params: any) => (params.data.inventory ? true : false),
          filterValueGetter: (params: any) =>
            params.data.inventory ? "Inventory" : "No Reported Stock",
          cellRenderer: (params: any) => {
            if (params.data.inventory) {
              const handleInventoryClick = (event: any) => {
                event.preventDefault();
                setProductDetails({
                  productId: params?.data.id.replace(/^0+/, ""),
                  description: params?.data.description,
                  stdpk: params?.data.stdpk,
                });
                navigate(
                  `/product-information/inventory?p_prodid=${parseInt(
                    params.data.id,
                  )}&p_is_iframe=Y`,
                );
              };
              return (
                <Link
                  className="table-link"
                  to={""}
                  onClick={handleInventoryClick}
                >
                  INVENTORY
                </Link>
              );
            } else {
              return <span>No Reported Stock</span>;
            }
          },
        },
        {
          colId: "stdpk",
          headerName: "Standard Pack",
          field: "stdpk",
          maxWidth: 100,
        },
        {
          colId: "gp_name",
          headerName: "Global Product",
          field: "gp_name",
          hide: !(
            Predicates.isNotNullAndNotUndefinedAndNotEmpty(showCustomFields) &&
            showCustomFields?.includes("gp_name")
          ),
        },
        {
          colId: "tcc_name",
          headerName: "TCC",
          field: "tcc_name",
          hide: !(
            (Predicates.isNotNullAndNotUndefinedAndNotEmpty(showCustomFields) &&
              showCustomFields.includes("tcc_name")) ||
            Predicates.isNotNullAndNotUndefined(filters?.tccId)
          ),
        },
        {
          colId: "mold_qty",
          field: "mold_qty",
          headerName: "Component qty.",
          filter: "agNumberColumnFilter",
          maxWidth: 140,
          minWidth: 120,
          cellRenderer: (params: any) => (
            <MoldCompositionCellRenderer
              id={params.data.id}
              description={params.data.description}
              moldQty={params.data.mold_qty}
              nonMoldQty={params.data.non_mold_qty}
            />
          ),
        },
        {
          field: "select",
          headerName: "Select",
          maxWidth: 100,
          checkboxSelection: true,
          floatingFilter: false,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          cellClass: "align-end",
          hide: !Predicates.isNotNullAndNotUndefined(onSelectionChanged),
        },
      ],
      [search, pageSize],
    );

    const productMasterId = useMemo(() => filters?.productMasterId, []);

    const selectedPM = useProductMasterById({
      id: productMasterId,
    });

    const productMaster = useProductMaster({
      global_product_id: filters?.globalProductId,
      tcc_id: filters?.tccId,
      limit: pageSize,
      offset,
      queryString: filters?.queryString,
      search: Predicates.isNotNullAndNotUndefinedAndNotEmpty(showCustomFields)
        ? searchToBeSent
        : undefined,
      searchProduct: Predicates.isNullOrUndefinedOrEmpty(showCustomFields)
        ? searchToBeSent
        : undefined,
    });

    const rows = useMemo(() => {
      const newRows = [];

      if (!selectedPM.isLoading && !productMaster.isLoading) {
        if (
          Predicates.isNotNullAndNotUndefined(selectedPM.data) &&
          offset === 0 &&
          Predicates.isNullOrUndefinedOrEmpty(search)
        )
          newRows.push(selectedPM.data);
        if (Predicates.isNotNullAndNotUndefined(productMaster.data)) {
          newRows.push(
            ...productMaster.data.data.filter(
              (item) =>
                item.id !==
                (selectedPM.data
                  ? selectedPM.data.id
                  : filters?.productMasterId),
            ),
          );
        }
      }

      return newRows;
    }, [selectedPM, productMaster, offset]);

    const isLoading = useMemo(
      () => selectedPM.isLoading || productMaster.isLoading,
      [selectedPM, productMaster],
    );

    const handleOnSelectionChanged = useCallback(() => {
      if (Predicates.isNotNullAndNotUndefined(onSelectionChanged))
        onSelectionChanged(gridRef?.current?.api.getSelectedRows()[0]);
    }, [onSelectionChanged]);

    const tableProp = useMemo(
      () => ({
        gridRef,
        rowData: rows,
        onSelectionChanged: handleOnSelectionChanged,
        columnDefs,
        defaultColDef,
      }),
      [gridRef, rows, handleOnSelectionChanged, columnDefs, defaultColDef],
    );

    const onPageSizeChanged = useCallback((e: any) => {
      setPageSize(Number(e.target.value));
    }, []);

    const handleTableSearch = (elem: any) => {
      setSearch(elem.target.value.toLowerCase().trimStart());
      debounce(setSearchToBeSent, 500)(elem?.target?.value ?? "");
    };

    const clearAllFilters = () => {
      setSearch("");
      setSearchToBeSent("");
      if (Predicates.isNotNullAndNotUndefined(gridRef.current)) {
        gridRef.current.api?.setFilterModel(null);
      }
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableFilters: [],
        lastTableTopFilters: {},
      });
    };

    useEffect(() => {
      if (gridRef.current?.api) {
        const storedTableFilters =
          retrieveObjectFromLocalStorage<TablePagePersistentTypes>(
            localStoragePrefix,
            "lastTableFilters",
          );
        if (storedTableFilters && storedTableFilters.length > 0) {
          storedTableFilters.forEach((tableFilter: any) => {
            const colId = Object.keys(tableFilter)[0];
            if (tableFilter[colId]) {
              const filterInstance =
                gridRef.current?.api?.getFilterInstance(colId);
              filterInstance?.setModel(tableFilter[colId]);
            }
          });
        }
      }
    }, [gridRef.current?.api]);

    useEffect(() => {
      dispatch(PageableTableActions.setOffset(0));
    }, [filters?.queryString, dispatch]);

    useEffect(() => {
      dispatch(PageableTableActions.setPageSize(pageSize));
    }, [pageSize, dispatch]);

    useEffect(() => {
      if (!isLoading && Predicates.isNotNullAndNotUndefined(productMaster.data))
        dispatch(
          PageableTableActions.setTotal(
            productMaster.data.data.length > 0 ? productMaster.data?.total : 0,
          ),
        );
    }, [isLoading, productMaster.data, dispatch]);

    useEffect(() => {
      if (
        Predicates.isNotNullAndNotUndefined(gridRef.current?.api) &&
        Predicates.isNotNullAndNotUndefined(filters?.productMasterId) &&
        offset === 0
      )
        gridRef.current?.api.forEachNode((node) => {
          if (filters?.productMasterId === node.data.id) node.setSelected(true);
        });
    }, [selectedPM, productMaster, offset, filters, dispatch]);

    useEffect(() => {
      dispatch(PageableTableActions.setLoading(isLoading));
    }, [isLoading, dispatch]);

    useEffect(() => {
      if (
        (Predicates.isNotNullAndNotUndefinedAndNotEmpty(filters?.queryString) ||
          Predicates.isNotNullAndNotUndefined(filters?.globalProductId) ||
          Predicates.isNotNullAndNotUndefined(filters?.productMasterId) ||
          Predicates.isNotNullAndNotUndefined(filters?.tccId)) &&
        isFirstRender
      ) {
        dispatch(PageableTableActions.setFirstRender(false));
      }
    }, [filters, isFirstRender, dispatch]);

    return (
      <>
        {enableAdvancedFilters ? (
          <div className="d-flex justify-content-between table-top-container">
            <div className="d-flex align-items-center">
              <label className="d-inline-block">
                Show
                <select
                  onChange={onPageSizeChanged}
                  className="table-top-input"
                  id="page-size"
                  value={pageSize}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">All</option>
                </select>
                entries
              </label>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div
                id="pp_search_filter"
                className="dataTables_filter table-top-search"
              >
                <label>
                  Search:
                  <input
                    onChange={handleTableSearch}
                    type="search"
                    className="table-top-input"
                    placeholder=""
                    aria-controls="pp_search"
                    value={search}
                  />
                </label>
              </div>

              {showClearButton ? (
                <button
                  className="icon-button ml-3 mr-3"
                  onClick={clearAllFilters}
                  disabled={Predicates.isNullOrUndefinedOrEmpty(search)}
                >
                  <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <PageableTable tableProp={tableProp} />
      </>
    );
  },
);

export default ProductMasterTable;
