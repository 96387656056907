export const PMS_PREFIX = "pms";
export const PIS_PREFIX = "pis";
export const NEW_PIS_HIERARCHY_PREFIX = "new-pis-hier";
export const PIP_LAYOUT_SWITCH_PREFIX = "pip-layout";

export type PMSPersistentData = {
  prefix: typeof PMS_PREFIX;
  lastFilters?: any;
  lastDropdownFilters?: any;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type PISPersistentData = {
  prefix: typeof PIS_PREFIX;
  lastFilters?: any;
};

export type NewPISHierarchyPersistentData = {
  prefix: typeof NEW_PIS_HIERARCHY_PREFIX;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type PIPLayoutSwitch = {
  prefix: typeof PIP_LAYOUT_SWITCH_PREFIX;
  isChecked?: any;
};

export type PagePersistentTypes =
  | PMSPersistentData
  | PISPersistentData
  | NewPISHierarchyPersistentData
  | PIPLayoutSwitch;

export type TablePagePersistentTypes =
  | PMSPersistentData
  | NewPISHierarchyPersistentData;
