import {
  ICompositionComponent,
  ISkuComposition,
  ISkuInformation,
} from "../../../../types/data.interface";

export const getTodayDate = () => {
  let date = new Date();
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

export const generateNodeId = () => {
  return Math.floor(Math.random() * 1000 + 1);
};

export const fillCompositionNodeIds = (
  composition: ISkuComposition,
): ISkuComposition => {
  let skus: ISkuComposition[] = composition.skus;
  let moldedComponents: ICompositionComponent[] = composition.molded_components;
  let nonMoldedComponents: ICompositionComponent[] =
    composition.non_molded_components;

  skus = skus.map((sku: ISkuComposition) => {
    return { ...sku, node_id: generateNodeId() };
  });
  moldedComponents = moldedComponents.map(
    (component: ICompositionComponent) => {
      return { ...component, node_id: generateNodeId() };
    },
  );
  nonMoldedComponents = nonMoldedComponents.map(
    (component: ICompositionComponent) => {
      return { ...component, node_id: generateNodeId() };
    },
  );

  return {
    ...composition,
    skus: skus,
    molded_components: moldedComponents,
    non_molded_components: nonMoldedComponents,
  };
};

export const formatIntoOption = (id: any, label: string) => {
  return label ? { value: id, label: label } : null;
};

export const findNodeIndexByNodeId = (
  nodeId: number,
  nodesList: ISkuComposition[] | ICompositionComponent[],
) => {
  return nodesList.findIndex(
    (node: ISkuComposition | ICompositionComponent) => node.node_id === nodeId,
  );
};

export const getNodeByNodeId = (
  nodeId: number,
  composition: ISkuComposition,
) => {
  let index: number = findNodeIndexByNodeId(nodeId, composition.skus);
  if (index != -1) return composition.skus.at(index);

  index = findNodeIndexByNodeId(nodeId, composition.molded_components);
  if (index != -1) return composition.molded_components.at(index);

  index = findNodeIndexByNodeId(nodeId, composition.non_molded_components);
  if (index != -1) return composition.non_molded_components.at(index);
};

export const checkIfSameComponentAlreadyExists = (
  componentToAdd: ICompositionComponent,
  componentsList: ICompositionComponent[],
): boolean => {
  let sameComponentAlreadyExists: boolean = false;
  componentsList.forEach((component: ICompositionComponent) => {
    const isSameComponent: boolean =
      component.node_id !== componentToAdd.node_id &&
      component.mold_id === componentToAdd.mold_id &&
      component.material_id === componentToAdd.material_id &&
      component.color_id === componentToAdd.color_id &&
      component.decoration_technique_id ===
        componentToAdd.decoration_technique_id &&
      component.artwork_id === componentToAdd.artwork_id &&
      component.is_tps === componentToAdd.is_tps;

    if (isSameComponent) sameComponentAlreadyExists = true;
  });
  return sameComponentAlreadyExists;
};

export const checkIfAtLeastOneComponentIsTheSameInList = (
  componentsToAdd: ICompositionComponent[],
  componentsList: ICompositionComponent[],
): boolean => {
  let hasAtLeastOneSameComponent: boolean = false;
  componentsToAdd.forEach((component: ICompositionComponent) => {
    if (checkIfSameComponentAlreadyExists(component, componentsList))
      hasAtLeastOneSameComponent = true;
  });
  return hasAtLeastOneSameComponent;
};

export const checkIfSkuIdIsIncludedInNode = (
  skuNode: ISkuComposition,
  skuId: string,
): boolean => {
  let checkArray: boolean[] = [];
  if (skuNode.skus.length === 0) {
    return skuNode.id.replace(/^0+/, "") === skuId.replace(/^0+/, "");
  } else {
    skuNode.skus.forEach((sku: ISkuComposition) => {
      checkArray.push(checkIfSkuIdIsIncludedInNode(sku, skuId));
    });

    return checkArray.some((value: boolean) => value === true);
  }
};

export const getEmptySkuDataObject = (): ISkuInformation => {
  return {
    id: "",
    name: "",
    stdpk: "",
    stdpk_weight: "",
    stdpk_length: "",
    stdpk_width: "",
    stdpk_height: "",
    reldt: "",
    giftbox: "N",
    width: "",
    length: "",
    height: "",
    comments: "",
    product_use: "",
    tps_product: "N",
    tps_loc_id: null,
    tps_type_id: null,
  };
};
