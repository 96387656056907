import { useLocation } from "react-router-dom";

const MoldAppIFrame = ({ path, title }: { path: string; title: string }) => {
  const location = useLocation();
  const sessid = sessionStorage.getItem("sessid");

  return (
    <div className="col">
      <iframe
        className="vh-80 w-100"
        src={`${process.env.REACT_APP_MOLD_APP_URL}/${path}${location.search}&sessid=${sessid}`}
        title={title}
      />
    </div>
  );
};

export default MoldAppIFrame;
