import { ReactNode, createContext } from "react";
import {
  IBuyer,
  IColor,
  IDecorationTech,
  IMaterial,
  IMold,
  IProductHier,
  ISupplierCountry,
} from "../types/data.interface";
import useDatacatalog from "../hooks/useDatacatalog";
import useMold from "../hooks/useMold";
import useMaterial from "../hooks/useMaterial";
import useColor from "../hooks/useColor";
import useDecorTech from "../hooks/useDecorTech";
import useNonMold from "../hooks/useNonMold";
import useBuyer from "../hooks/useBuyer";
import useSupplierCountry from "../hooks/useSupplierCountry";

type GlobalContextType = {
  datacatalog: { datacatalog: IProductHier[]; isLoading: boolean };
  molds: { data: IMold[]; isLoading: boolean };
  nonMolds: { data: IMold[]; isLoading: boolean };
  materials: { data: IMaterial[]; isLoading: boolean };
  colors: { data: IColor[]; isLoading: boolean };
  decorTechs: { data: IDecorationTech[]; isLoading: boolean };
  buyers: { data: IBuyer[]; isLoading: boolean };
  supplierCountries: { data: ISupplierCountry[]; isLoading: boolean };
};

const initialContext: GlobalContextType = {
  datacatalog: { datacatalog: [], isLoading: false },
  molds: { data: [], isLoading: false },
  nonMolds: { data: [], isLoading: false },
  materials: { data: [], isLoading: false },
  colors: { data: [], isLoading: false },
  decorTechs: { data: [], isLoading: false },
  buyers: { data: [], isLoading: false },
  supplierCountries: { data: [], isLoading: false },
};

const GlobalContext = createContext<GlobalContextType>(initialContext);

const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const datacatalog = useDatacatalog();
  const molds = useMold({});
  const nonMolds = useNonMold({});
  const materials = useMaterial({});
  const colors = useColor({});
  const decorTechs = useDecorTech({});
  const buyers = useBuyer({});
  const supplierCountries = useSupplierCountry({});
  return (
    <GlobalContext.Provider
      value={{
        datacatalog,
        molds,
        nonMolds,
        materials,
        colors,
        decorTechs,
        buyers,
        supplierCountries,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export {
  GlobalContextProvider as default,
  GlobalContext,
  type GlobalContextType,
};
