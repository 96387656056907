import { Link, useNavigate } from "react-router-dom";
import { PisRequestTable } from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import { toast } from "react-toastify";

export default function ApprovalQueueActionLinks({
  data,
}: {
  data: PisRequestTable;
}) {
  const axios = useAxios();
  const navigate = useNavigate();

  const handleReviseClick = async (event: any, data: PisRequestTable) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `next-revision-number?request_id=${data.pis_request_id}`,
      );
      const newRevisionNumber: number = response.data;
      const pathToNavigate: string = `/skus/create?id=${data.id}&pis_request_id=${
        data.pis_request_id
      }&revision=${newRevisionNumber}&should_create=true&last_approved_revision=${
        data.revision
      }`;

      navigate(pathToNavigate);
    } catch (err) {
      toast.error("Something unexpected happened while starting a revision.");
    }
  };

  switch (data.status) {
    case "CREATED":
      return (
        <Link
          className="link-primary"
          to={`/skus/create?id=${
            data.id
          }&pis_request_id=${data.pis_request_id}&revision=${data.revision}`}
        >
          Resume
        </Link>
      );
    case "REJECTED":
    case "SUBMITTED":
      return (
        <>
          <Link
            className="link-primary me-1"
            to={`/skus/create?id=${
              data.id
            }&pis_request_id=${data.pis_request_id}&revision=${data.revision}`}
          >
            View
          </Link>
        </>
      );
    case "APPROVED":
      return (
        <>
          <Link
            className="link-primary me-2"
            to={`/skus/create?id=${
              data.id
            }&pis_request_id=${data.pis_request_id}&revision=${
              data.revision
            }&is_approved=true`}
          >
            View
          </Link>
          {data.revise_flag === "N" && (
            <Link
              className="link-primary"
              to={``}
              onClick={(event) => handleReviseClick(event, data)}
            >
              Revise
            </Link>
          )}
        </>
      );
    default:
      return <></>;
  }
}
