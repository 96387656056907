import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Layout from "./components/navigation/Layout";

import AuthContext from "./context/AuthContext";
import GlobalContextProvider from "./context/GlobalContextProvider";
import DebounceContextProvider from "./context/DebounceContext";
import NoAccessPage from "./pages/NoAccessPage";

function App() {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;

  return (
    <div className="App">
      <GlobalContextProvider>
        <DebounceContextProvider>
          <Layout>
            {!user ? (
              <NoAccessPage />
            ) : (
              <Outlet
                context={{
                  user,
                }}
              />
            )}
          </Layout>
        </DebounceContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
