import {
  faMinus,
  faPlus,
  faPencil,
  faTrash,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import ConfirmDeleteModal from "../../../../components/ConfirmDeleteModal";
import { useState } from "react";
import {
  COMPOSITION_OPTIONS,
  CompositionType,
} from "../sku-composition-modal/AddSkuCompositionModal";
import {
  ICompositionComponent,
  ISkuComposition,
} from "../../../../types/data.interface";

const TreeActionsGroup = ({
  node,
  isMainPage,
  compositionType,
  addActionHandler,
  editActionHandler,
  removeActionHandler,
}: {
  node: ISkuComposition | ICompositionComponent;
  isMainPage: boolean;
  compositionType: CompositionType;
  addActionHandler: (...params: any) => void;
  editActionHandler: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;
  removeActionHandler: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
}) => {
  const [showRemovalModal, setShowRemovalModal] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(
    (node as ICompositionComponent).quantity ?? 1,
  );

  const deleteNodeName =
    compositionType === COMPOSITION_OPTIONS.SKU
      ? `SKU ${(node as ISkuComposition).id} - ${(node as ISkuComposition).name}`
      : `Component ${(node as ICompositionComponent).mold_description}`;

  const quantityChangeHandler = (e: any) => {
    const cleanedNum = e.target.value.replace(/[^0-9]/gi, "");
    if (!isNaN(Number(cleanedNum)) && Number(cleanedNum) > 0) {
      changeNodeQuantity(Number(cleanedNum));
    }
  };

  const changeNodeQuantity = (newQuantity: number) => {
    if (newQuantity > 0) {
      setQuantity(newQuantity);
      addActionHandler(node.node_id, newQuantity);
    }
  };

  const cancelDelete = () => {
    setShowRemovalModal(false);
  };

  return (
    <>
      <ReactModal
        isOpen={showRemovalModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ConfirmDeleteModal
          message={`You are going to remove the <strong>${deleteNodeName}</strong>, do you want to proceed?`}
          cancelDelete={cancelDelete}
          confirmDelete={() => {
            removeActionHandler(node.node_id ?? -1, compositionType);
            setShowRemovalModal(false);
          }}
        />
      </ReactModal>

      <div
        className={`tree-actions-container d-flex gap-3 align-items-center align-self-start ${compositionType === COMPOSITION_OPTIONS.SKU ? "mt-2" : ""}`}
      >
        {compositionType === COMPOSITION_OPTIONS.COMPONENT ? (
          <div className="col-md form-group mb-0 p-0 d-flex flex-column align-items-center">
            <label className="form-label mb-0" style={{ fontSize: "smaller" }}>
              Qty
            </label>
            <div className="d-flex align-items-center gap-2">
              <FontAwesomeIcon
                icon={faMinus}
                size={"sm"}
                color={"black"}
                cursor={"pointer"}
                onClick={() => changeNodeQuantity(quantity - 1)}
              />
              <input
                type="text"
                className="form-control p-0 text-center"
                value={quantity}
                onChange={quantityChangeHandler}
                style={{ maxWidth: 50, maxHeight: 20 }}
              />
              <FontAwesomeIcon
                icon={faPlus}
                size={"sm"}
                color={"black"}
                cursor={"pointer"}
                onClick={() => changeNodeQuantity(quantity + 1)}
              />
            </div>
          </div>
        ) : (
          <FontAwesomeIcon
            icon={faClone}
            size={"lg"}
            color={"black"}
            cursor={"pointer"}
            onClick={() => addActionHandler(node.node_id)}
          />
        )}

        <FontAwesomeIcon
          icon={faPencil}
          size={"lg"}
          color={"black"}
          cursor={"pointer"}
          onClick={() => editActionHandler(compositionType, node)}
        />

        <FontAwesomeIcon
          icon={faTrash}
          size={"lg"}
          color={"black"}
          cursor={"pointer"}
          onClick={() => setShowRemovalModal(true)}
        />
      </div>
    </>
  );
};

export default TreeActionsGroup;
