import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import AuthContext from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ReactModal from "react-modal";
import { AgGridReact } from "ag-grid-react";
import { IGlobalInfo } from "../../types/data.interface";
import isoCodes from "../../constants/iso-codes.json";
import { reorderGlobalInfo, mapIsoCodeOptions } from "../../helpers";
import OptionFormatter from ".././OptionFormatter";
import TablePagination from ".././TablePagination";
import CheckBox from ".././CheckBox";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
const PipLocalDescription = ({
  data,
  submitChanges,
  savePending,
  setSavePending,
}: {
  data: IGlobalInfo[];
  submitChanges: any;
  savePending: boolean;
  setSavePending: any;
}) => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>(data);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<any>(null);
  const [isoCode, setIsoCode] = useState<any | null>(null);
  const [localDes, setLocalDes] = useState<string>("");
  const columnDefs = useMemo(
    () => [
      { field: "local_description", headerName: "Local Description", flex: 3 },
      { field: "iso_code", headerName: "ISO Code" },
      {
        field: "is_primary",
        headerName: "Primary",
        cellRenderer: (params: any) => (
          <CheckBox checked={params.data.is_primary} readOnly></CheckBox>
        ),
        maxWidth: 110,
      },
    ],
    [],
  );
  const adminColumnDefs = useMemo(
    () => [
      ...columnDefs,
      {
        headerName: "Actions",
        sortable: false,
        cellRenderer: (params: any) =>
          params.data.iso_code !== "en-US" ? (
            <button
              onClick={() => openDeleteModal(params.data)}
              className="icon-button d-flex justify-content-center mx-auto"
            >
              <FontAwesomeIcon icon={faMinusCircle} size="lg" />
            </button>
          ) : null,
        maxWidth: 110,
      },
    ],
    [columnDefs],
  );
  const defaultColDef = useMemo(
    () => ({
      sortingOrder: ["asc" as const, "desc" as const],
      minWidth: 100,
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );
  const onPageSizeChanged = useCallback(
    (e: any) => {
      gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
      setPageSize(Number(e.target.value));
    },
    [gridRef],
  );
  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current?.api?.getDisplayedRowCount());
    }
  }, [gridRef]);
  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };
  const openDeleteModal = (item: any) => {
    setShowModal(true);
    setRowToDelete(item);
  };
  const cancelDelete = () => {
    setShowModal(false);
    setRowToDelete(null);
  };
  const handleIsoCode = (e: any) => {
    if (e) {
      setIsoCode(e);
    } else {
      setIsoCode(null);
    }
  };
  const handleLocalDes = (e: any) => {
    setLocalDes(e.target.value);
  };
  const handleCreate = (e: any) => {
    e.preventDefault();
    const newItem = {
      local_description: localDes,
      iso_code: isoCode.value,
      product: rowData[0]?.product,
      is_primary: localDes === "en-US" ? true : false,
    };
    const newData = reorderGlobalInfo([newItem, ...rowData]);
    setRowData(newData);
    setSavePending(true);
    // reset inputs
    setLocalDes("");
    setIsoCode(null);
  };
  const handleDelete = () => {
    setShowModal(false);
    const newRowData = rowData.filter(
      (item) => item.iso_code !== rowToDelete.iso_code,
    );
    setRowData(newRowData);
    setSavePending(true);
  };
  const saveChanges = async () => {
    submitChanges(rowData);
  };
  useEffect(() => {
    ReactModal.setAppElement("#root");
    setRowData(data);
  }, [data]);
  return (
    <div className="py-2 px-2 bg-white">
      <ReactModal
        isOpen={showModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ConfirmDeleteModal
          message={`You are going to remove Local Description "<strong>${rowToDelete?.local_description}</strong>" for the ISO Code 
            <strong>${rowToDelete?.iso_code}</strong>, do you want to proceed?`}
          cancelDelete={cancelDelete}
          confirmDelete={handleDelete}
        />
      </ReactModal>
      {hasPermission("api.add_productinformation") ? (
        <form onSubmit={handleCreate} className="mb-2">
          <div className="row form-row align-items-end">
            <div className="col-lg-4 form-group">
              <label className="form-label">Local Description</label>
              <input
                value={localDes}
                onChange={handleLocalDes}
                type="text"
                className="form-control d-inline"
              />
            </div>
            <div className="col-lg-2 form-group">
              <label className="form-label">ISO Code</label>
              <Select
                options={mapIsoCodeOptions(isoCodes, rowData)}
                onChange={handleIsoCode}
                value={isoCode}
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder="-----"
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
              />
            </div>
            <div className="col-lg-2 form-group ">
              <input
                type="submit"
                className="btn btn-primary px-4 fixed-width-button"
                value="Add"
                disabled={!(isoCode && localDes)}
              />
            </div>
          </div>
        </form>
      ) : null}
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            id="pp_search_filter"
            className="dataTables_filter table-top-search"
          >
            <label>
              Search:
              <input
                onChange={handleTableSearch}
                type="search"
                className="table-top-input"
                placeholder=""
                aria-controls="pp_search"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          rowData={rowData}
          columnDefs={
            hasPermission("api.add_productinformation") ||
            hasPermission("api.delete_productinformation")
              ? adminColumnDefs
              : columnDefs
          }
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          pagination={true}
          paginationPageSize={pageSize}
          onPaginationChanged={onPaginationChanged}
          unSortIcon
          suppressPaginationPanel
        ></AgGridReact>
      </div>
      <TablePagination
        gridRef={gridRef}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
      />
      {hasPermission("api.add_productinformation") ||
      hasPermission("api.delete_productinformation") ? (
        <button
          onClick={saveChanges}
          className="btn btn-primary px-4 fixed-width-button"
          disabled={!savePending}
        >
          Save
        </button>
      ) : null}
    </div>
  );
};
export default PipLocalDescription;
