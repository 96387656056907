import {
  ColDef,
  FirstDataRenderedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject, memo, useCallback, useEffect, useMemo } from "react";
import { usePageableTable } from "../../context/PageableTableContext";
import { Predicates } from "../../libraries/predicates/predicates";
import Spinner from "../Spinner";
import { PageableTableActions } from "../../states/pageable-table";

const defaultColDef = {
  headerClass: "text-wrap",
  sortable: true,
  resizable: true,
  flex: 1,
};

export type TableProp = {
  rowData?: Array<any>;
  columnDefs: ColDef[];
  defaultColDef?: ColDef;
  gridRef: RefObject<AgGridReact<any>>;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  onFilterChanged?: () => void;
};

const Table = memo((prop: TableProp) => {
  const loadingOverlayComponent = useMemo(() => Spinner, []);
  const {
    state: { isLoading, isFirstRender },
    dispatch,
  } = usePageableTable();

  const onFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent<any>) => {
      if (Predicates.isNotNullAndNotUndefined(prop.onFirstDataRendered))
        prop.onFirstDataRendered(event);
      if (isFirstRender) dispatch(PageableTableActions.setFirstRender(false));
    },
    [prop, dispatch, isFirstRender],
  );

  useEffect(() => {
    if (
      Predicates.isNotNullAndNotUndefined(prop.gridRef?.current) &&
      Predicates.isNotNullAndNotUndefined(prop.gridRef?.current?.api)
    ) {
      if (isLoading) {
        prop.gridRef.current?.api.showLoadingOverlay();
      } else {
        prop.gridRef.current?.api.hideOverlay();
        if (Predicates.isNullOrUndefinedOrEmpty(prop.rowData))
          prop.gridRef.current?.api.showNoRowsOverlay();
      }
    }
  }, [isLoading, prop.gridRef]);

  const overlayNoRowsTemplate = useMemo(
    () =>
      isFirstRender
        ? "<span><b>Please use the search field(s) above to display data</b></span>"
        : "<span>No results found with these criteria</span>",
    [isFirstRender],
  );

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        ref={prop.gridRef}
        domLayout="autoHeight"
        defaultColDef={prop.defaultColDef ?? defaultColDef}
        columnDefs={prop.columnDefs}
        rowData={prop.rowData}
        enableCellTextSelection={true}
        onSelectionChanged={prop.onSelectionChanged}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={prop.onFilterChanged}
        loadingOverlayComponent={loadingOverlayComponent}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
      />
    </div>
  );
});

export default Table;
