import { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";
import ProductContext, {
  ProductContextInterface,
} from "../../context/ProductContext";
import {
  retrieveObjectFromLocalStorage,
  saveObjectsToLocalStorage,
} from "../../helpers/local-storage.helper";
import { Predicates } from "../../libraries/predicates/predicates";
import {
  PIPLayoutSwitch,
  PIP_LAYOUT_SWITCH_PREFIX,
} from "../../types/persistent-page-filters";

export const PipLayoutSwitch = () => {
  const { setPipLayout } = useContext<ProductContextInterface>(ProductContext);
  const [isListLayoutActive, setIsListLayoutActive] = useState<boolean>(() => {
    const isChecked = retrieveObjectFromLocalStorage<PIPLayoutSwitch>(
      "pip-layout",
      "isChecked",
    );
    return Predicates.isNullOrUndefined(isChecked) ? false : isChecked;
  });

  const handleToggle = () => {
    setIsListLayoutActive(!isListLayoutActive);
    saveObjectsToLocalStorage<PIPLayoutSwitch>({
      prefix: PIP_LAYOUT_SWITCH_PREFIX,
      isChecked: !isListLayoutActive,
    });
    setPipLayout(!isListLayoutActive ? "list" : "tabs");
  };
  return (
    <>
      <Tooltip
        id="layout-switch"
        className="tooltip-text-box"
        place="bottom"
        noArrow={true}
      />
      <div
        className="d-flex align-items-center"
        data-tooltip-id="layout-switch"
        data-tooltip-html={`Switch between a tabs and a top-down list layout`}
      >
        <span className="mr-1">Tabs</span>
        <input
          className="react-switch-checkbox"
          id={`react-switch-new`}
          type="checkbox"
          checked={isListLayoutActive}
          onChange={handleToggle}
        />
        <label className="react-switch-label mb-0" htmlFor={`react-switch-new`}>
          <span className={`react-switch-button`} />
        </label>
        <span className="ml-1">List</span>
      </div>
    </>
  );
};
