import React from "react";
import ReactMarkdown from "react-markdown";
import { HeadingProps } from "react-markdown/lib/ast-to-react";

const toc: {
  level: number;
  id: string;
  title: string;
}[] = [];
const markdown = `## Access

Access to the **Product Master Search screen** can be done by clicking on the **Product Master** hyperlink displayed in the Main Menu or clicking on the sub-menu in the left lateral navigation bar.

![Product Master hyperlink in MoldApp > Item Master](/userguide/item_master_menu.png)
  >Product Master hyperlink in MoldApp > Item Master

![Product Master hyperlink in MoldApp > Item Master](/userguide/PIP_pms_link_sidebar.png) 
  >Product Master Search sub-menu link in lateral navigation bar

![PMS](/userguide/PMS.png)
  >Product Master Search Page

## Search Fields

Main Search Fields are displayed on the top of the screen.  

A **More Search Fields** expandable section is available to refine the search. The section expands when clicking on the arrow on the right side to display more fields. 

Products can be searched using multiple criteria by filling some or all of the following fields: 

-   text field (search triggered with at least 3 characters): **Global Code**, **Global Product Name**, **SKU Number**, **SKU Description**, **Design** 
    
-   auto-complete search field, with the possibility to search for one specific product or search for all products containing a specific string of characters: **Products**
    
-   multi-criteria auto-complete search field (“AND” query, up to 5 criteria, search triggered with at least 3 characters): **Molded Components** and **non-Molded Components**, **Material** and **Color**
    
-   simple auto-complete search field: **Category**, **Sub-Category** and **Product Line**

-   simple dropdown: **Decoration Technology**, **Buyers**, **Release / Supplier Country**
    
-   checkboxes: **Royalty Item**, **Discontinued**, **TPS Product**, **Stock Reported**. If the user leaves the checkbox unchecked, no filter is applied; if the user checks a checkbox, results are filtered as “YES”

-   date field with mathematical operator: **Release date**. The user can insert a date by using the operators “<,<=,=,>,>= ” followed by a date, eg. >= 01/01/2020
    
and clicking on the **Search** button on the right bottom corner. Products returned in the Results table are the ones matching with all criteria (AND search). 

By default, the **Search** button is disabled until at least one field is filled. 

Values inserted in the search fields can be easily removed by clicking on **Clear Filters** button. 

![Main search fields](/userguide/PMS_top_search_fields.png)
>Main search fields

![More search fields](/userguide/PMS_more_search_fields.png)
>More search fields

![Release date operators options](/userguide/PMS_release_date_operators.png)
>Release date operators options

## Search Results

A table is displayed below the search part, showing the results based on the search fields. By default, 50 results are shown by page. This can be changed by choosing another value in the “Show 50 entries” above the results table.

Before doing a search, the following message is displayed: “**Please use the search field(s) above to display data**.”

When doing a search, and if no results correspond to the search criteria, the following message is displayed: “**No results found with these criteria**”.

When doing a search, and if the number of results exceed 500, the following message is displayed: “**There are more than 500** **results, please refine your search**”.

If results are found, the following information is displayed:

-   **SKU Nr**.: display the SKU number digits with the “11” prefix. By clicking on the link, the user is redirected to the Product Information page. It is possible to go back to the Product Master Search by clicking on the “go back” button on the top of the screen. If any search field was filled, the search values are kept while going back. 
    
-   **SKU Description**: returns the SKU Description (in en-US)
    
-   **Picture?**: if any picture is associated to the SKU, a **PICTURE** link appears in the results and when clicking on it, it opens a pop-up.
    
-   **Any Stock Reported?**: if any stock is reported for the SKU, an **INVENTORY** link appears in the results and when clicking on it, it opens a new tab, redirecting the user to the MoldApp “Product Master : Product Inventory” screen.
    
-   **Global Product Name**: returns the Global Product Name of the SKU
    
-   **Mold qty.**: displays the number of molds included in the SKU, as well as a “+ Details” button to open the Mold Composition pop-up.

It is possible to filter and sort every column, by using the arrows on the right of the header name, and the filter fields below each header name. It is also possible to search any text in the whole table by using the Search field above the table. All filters can be cleared by using the clean filter icon on the top right of the table.
        
![Product Master Search Results table](/userguide/PMS_results_table.png)
>Product Master Search Results table

![Molds Composition Details pop-up](/userguide/PMS_mold_composition_modal.png)
>Molds Composition Details pop-up

![Picture and Inventory links](/userguide/PMS_results_table_pic_and_stock.png)
>Picture and Inventory links

![Product Picture pop-up](/userguide/PMS_picture_popup.png)
>Product Picture pop-up

![Inventory](/userguide/PMS_inventory_redirect.png)
>Inventory`;

const addIdToHeading = ({
  children,
  ...props
}: React.PropsWithChildren<HeadingProps>) => {
  const level = Number(props.node.tagName.match(/h(\d)/)?.slice(1));
  if (level && children && typeof children[0] === "string") {
    const id = children[0].toLowerCase().replace(/[^a-z0-9]+/g, "-");
    toc.push({
      level,
      id,
      title: children[0],
    });
    return React.createElement(props.node.tagName, { id }, children);
  } else {
    return React.createElement(props.node.tagName, props, children);
  }
};

const UserGuidePMS = () => {
  return (
    <div className="guide">
      <h1>User Guide</h1>
      <div className="table-of-content">
        <ul className="toc-indentation">
          <li>
            <a href="#access">Access</a>
          </li>
          <li>
            <a href="#search-fields">Search Fields</a>
          </li>
          <li>
            <a href="#search-results">Search Results</a>
          </li>
        </ul>
      </div>
      <ReactMarkdown
        children={markdown}
        components={{
          h2: addIdToHeading,
          h3: addIdToHeading,
          h4: addIdToHeading,
          h5: addIdToHeading,
          h6: addIdToHeading,
        }}
      />
    </div>
  );
};

export default UserGuidePMS;
