import axios, { InternalAxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Predicates } from "../libraries/predicates/predicates";
import AuthService from "../services/auth-service";

const baseURL = process.env.REACT_APP_API_BASE_URL;

let refreshTokenPromise: any = null; // this holds in-progress token refresh requests

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (
    Predicates.isNotNullAndNotUndefined(config) &&
    Predicates.isNotNullAndNotUndefined(config.headers)
  ) {
    config.headers.Accept = "application/json";
    config.headers.Authorization = `Bearer ${AuthService.getAccessToken()}`;
  }

  return config;
}

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext<any>(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
  });

  const getRefreshToken = () =>
    axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens?.refresh,
    });

  axiosInstance.interceptors.request.use(authRequestInterceptor);

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getRefreshToken().then((res) => {
            refreshTokenPromise = null;
            return res;
          });
        }

        return refreshTokenPromise.then((res: any) => {
          localStorage.setItem("authTokens", JSON.stringify(res.data));
          setAuthTokens(res.data);
          setUser(jwt_decode(res.data.access));
          error.config.headers.Authorization = `Bearer ${res.data.access}`;
          return axiosInstance.request(error.config);
        });
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default useAxios;
