import {
  ComponentType,
  ICompositionComponent,
  ISkuComposition,
} from "../../../../types/data.interface";
import { CompositionCheckbox } from "../sku-composition-modal";
import { CompositionType } from "../sku-composition-modal/AddSkuCompositionModal";
import TreeActionsGroup from "./TreeActionsGroup";

const ComponentsTable = ({
  skuId,
  isSummaryPage,
  isModal,
  isPipPage,
  components,
  componentsType,

  addActionHandler,
  handleRemoveNode,
  openEditModal,

  shouldIncludeSku,
  manageComponentInclusion,
  assertComponentIsIncluded,
}: {
  skuId: string;
  isSummaryPage: boolean;
  isModal: boolean;
  isPipPage: boolean;
  components: ICompositionComponent[];
  componentsType: ComponentType;

  addActionHandler?: (node_id: number, newQuantity: number) => void;
  handleRemoveNode?: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
  openEditModal?: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;

  shouldIncludeSku: boolean;
  manageComponentInclusion: any;
  assertComponentIsIncluded: any;
}) => {
  return (
    <table className="table table-striped mb-0">
      <thead>
        <tr>
          {isModal && !isSummaryPage && <th scope="col"></th>}
          <th scope="col" className="component-col">{`${
            componentsType === "NonMolded" ? "Sequence" : "Mold"
          }`}</th>
          <th scope="col" className="material-col">
            Material
          </th>
          <th scope="col" className="color-col">
            Color
          </th>
          <th scope="col" className="pcs-col">
            Pcs./ Set
          </th>
          <th scope="col" className="decoration-col">
            Decor. Technique
          </th>
          <th scope="col" className="artwork-col">
            Artwork
          </th>
          {componentsType === "Molded" && (
            <th scope="col" className="tps-col">
              TPS
            </th>
          )}
          {!isSummaryPage &&
            !isModal &&
            addActionHandler &&
            openEditModal &&
            handleRemoveNode && <th scope="col" style={{ width: 170 }}></th>}
        </tr>
      </thead>
      <tbody>
        <tr></tr>
        {components.map((component: ICompositionComponent) => (
          <tr key={component.node_id}>
            {isModal && !isSummaryPage && (
              <td>
                <CompositionCheckbox
                  nodeId={component.node_id ?? -1}
                  isDisabled={shouldIncludeSku}
                  isChecked={assertComponentIsIncluded(
                    component.type,
                    component.node_id,
                  )}
                  checkedChangeHandler={manageComponentInclusion}
                />
              </td>
            )}

            {isPipPage && componentsType !== "NonMolded" ? (
              <td>
                <a
                  className="fw-bold"
                  target="_blank"
                  href={`${
                    process.env.REACT_APP_MOLD_APP_URL
                  }/ords/moldapp/product_master.prod_mold_list?sessid=${sessionStorage.getItem(
                    "sessid",
                  )}&moldid=${component.mold_id}&p_prodid=${skuId}`}
                >
                  <span>{component.mold_description}</span>
                </a>
              </td>
            ) : (
              <td>{component.mold_description}</td>
            )}

            <td>{component.material_description}</td>
            <td>{component.color_description}</td>
            <td>{component.nr_pieces * component.quantity}</td>
            <td>
              {component.decoration_technique_description
                ? component.decoration_technique_description
                : "00"}
            </td>
            <td>
              {component.artwork_description
                ? component.artwork_description
                : "00000"}
            </td>
            {componentsType === "Molded" && (
              <td>{component.is_tps ? "Yes" : "No"}</td>
            )}
            {!isSummaryPage &&
              !isModal &&
              addActionHandler &&
              openEditModal &&
              handleRemoveNode && (
                <td>
                  <TreeActionsGroup
                    node={component}
                    isMainPage={!isModal}
                    compositionType="component"
                    addActionHandler={addActionHandler}
                    editActionHandler={openEditModal}
                    removeActionHandler={handleRemoveNode}
                  />
                </td>
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ComponentsTable;
